import Cookies from "js-cookie";

import avatarImage from "../../assets/png/testAvatar.png";
import React, { useEffect, useState } from "react";
import { Loading, SearchSvg } from "../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useMerchant, useMyStore, userDataStore, useTheme } from "../../store";
import ButtonComp from "../ButtonComp/ButtonComp";
import PagingWithNextAndPrevButtons from "../PagingWithNextAndPrevButtons";
import Avatar from "react-avatar";
import Pagination from "../shared/Pagination";
import { removeCookie, setCookie } from "../../utils";

const AnalyticsUsersList = ({ setIsUsersModalVisible, setSelectedUser }) => {
  const { usersList, userId, setUserId, usersListHandler, isUsersListRequestLoading } = useMyStore();

  const { loginAuth } = userDataStore();
  const { getMerchantData, setIdOfUser, setAvatar } = useMerchant();
  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();

  const { Colors } = useTheme();
  const [searchedUser, setSearchedUser] = useState();

  const loadHandler = async (params) => {
    usersListHandler({ ...{ searchKey: searchedUser }, ...params });
  };
  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div
      style={{
        fontFamily: "Poppins",
        backgroundColor: Colors.white,
        borderRadius: "8px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "10px",
        flexWrap: "wrap",
        flexFlow: "row wrap",
        boxSizing: "border-box",
        padding: "20px 20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            fontSize: mdfontsize,
            fontWeight: "500",
            color: Colors.fontDark,
          }}
        >
          Select User
        </div>
        <button
          onClick={() => {
            setIsUsersModalVisible(false);
          }}
          type="button"
          style={{
            color: Colors?.fontDark,
            fontSize: isdesktop ? "20px" : "36px",
          }}
          className="material-icons outline-none"
        >
          close
        </button>
      </div>
      <form style={{ width: "100%", display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "100%",
            borderWidth: "1px",
            borderRadius: "6px",
            borderColor: Colors.borderPrimary,
            padding: "10px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            backgroundColor: Colors.bgSettings,
            marginTop: "10px",
          }}
        >
          <SearchSvg />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => {
              setSearchedUser(e.target.value);
            }}
            value={searchedUser}
            style={{
              outline: "none",
              width: "100%",
              height: "100%",
              marginLeft: "10px",
              backgroundColor: "transparent",
              color: Colors.fontDark,
            }}
          />
        </div>
        <ButtonComp
          type="submit"
          title={"Search"}
          onClick={(e) => {
            e.preventDefault();
            loadHandler({ searchKey: searchedUser });
          }}
          primary
          extraStyles={{ marginTop: "10px", marginLeft: "14px" }}
        />
        <ButtonComp
          title={"Clear"}
          onClick={async () => {
            removeCookie("impersonatedMerchantAccountId");
            removeCookie("impersonatedMerchantUserId");
            loadHandler();
            setSearchedUser("");

            await loginAuth().then((res) => {
              if (res.data?.user?.merchantAccountId) {
                // getMerchantData(res.data.user.merchantAccountId);
              }
            });
            setUserId();
            setIdOfUser();
            setSelectedUser();
          }}
          extraStyles={{
            marginTop: "10px",
            marginLeft: "14px",
            color: Colors.fontGreyLight,
          }}
        />
      </form>
      <div
        style={{
          width: "100%",
          maxHeight: "40vh",
          backgroundColor: Colors.bgListHeader,
          marginTop: "30px",
          overflowY: "auto",
        }}
      >
        {isUsersListRequestLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              marginTop: "100px",
              marginBottom: "100px",
            }}
          >
            <Loading color={Colors.bluePrimary} className="h-14 w-14 animate-spin" />
          </div>
        )}
        {!isUsersListRequestLoading &&
          usersList?.data.length > 0 &&
          usersList?.data.map((user, index) => {
            return (
              <button
                key={user.id}
                disabled={user.locled ? true : false}
                type="button"
                onClick={() => {
                  getMerchantData(user.merchantAccountId);
                  setUserId(user.merchantAccountId);
                  setAvatar(user.image);
                  setIdOfUser(user.id);
                  setSelectedUser(user);
                  setCookie("sandbox", user?.sandboxKey);
                  setCookie("prod", user?.prodKey);
                }}
                style={{ padding: "10px 8px", width: "80%" }}
                className="  flex flex-row justify-between items-center  focus:outline-none"
              >
                <div style={{ width: "70%" }} className="flex items-center ">
                  <div
                    style={{
                      height: isdesktop ? "40px" : "50px",
                      width: isdesktop ? "40px" : "50px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0px 10px",
                    }}
                  >
                    {user?.image ? (
                      <img
                        style={{
                          height: "100%",
                        }}
                        src={user.image ?? avatarImage}
                        alt="user avatar"
                      />
                    ) : (
                      <Avatar
                        name={user?.first_name ? user?.first_name?.split("")?.join(" ") : user.last_name?.split("")?.join(" ")}
                        size={50}
                        maxInitials={2}
                        // className={"h-8 w-8"}
                      />
                    )}
                  </div>
                  <div
                    id={`usersId${user.name}`}
                    style={{
                      fontSize: smfontsize,
                      marginLeft: "10px",
                      minWidth: "27%",
                      display: "flex",
                      justifyContent: "start",
                      fontWeight: "500",
                      color: userId === user.merchantAccountId ? Colors.bluePrimary : Colors.fontDark,
                    }}
                  >
                    {(user?.first_name ?? " ") + " " + (user.last_name ?? "")}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: smfontsize,
                    display: "flex",
                    fontWeight: "500",
                    color: userId === user.merchantAccountId ? Colors.bluePrimary : Colors.fontDark,
                    width: "28%",
                    justifyContent: "start",
                    marginLeft: "10px",
                  }}
                >
                  {user.email}
                </div>
              </button>
            );
          })}
      </div>
      <Pagination
        data={usersList}
        onGetPaginationData={loadHandler}
        dependencies={[
          searchedUser,
          // searchByDropoffAddress,
          // searchByDropoffPhoneNum,
          // searchByDroppofName,
          // searchByPhoneNumber,
          // searchByName,
          // searchByAddress,
          // searchById,
          // startDate,
          // selectedProvider,
        ]}
      />
      {/* <PagingWithNextAndPrevButtons
        setPage={setPageCount}
        page={pageCount}
        result={usersList?.users?.length}
        plus
        itemsPerPage={50}
        usersList={usersList}
      /> */}
    </div>
  );
};

export default AnalyticsUsersList;
