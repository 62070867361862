import React from "react";
import { useEffect } from "react";
import { Loading } from "../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useLogsStore, useMerchant, useMyStore, userDataStore, useTheme } from "../../store";
import { removeCookie } from "../../utils";

const LogsPage = ({}) => {
  const { Colors } = useTheme();
  const { userLogsRequest, userLogsLoading, userLogs } = useLogsStore();
  const { isdesktop, mdfontsize } = useDesktopOrLaptop();
  const { getMerchantData, setIdOfUser, setSelectedUser } = useMerchant();

  const { userId, setUserId } = useMyStore();
  const { loginAuth } = userDataStore();

  useEffect(() => {
    if (userId) {
      userLogsRequest(userId);
    }
    userLogsRequest();
  }, []);

  return (
    <>
      <div
        style={{
          fontWeight: "500",
          fontSize: mdfontsize,
          color: Colors.fontDark,
          display: "flex",
        }}
      >
        Logs
        {userId && (
          <button
            type="button"
            onClick={async () => {
              removeCookie("impersonatedMerchantAccountId");
              removeCookie("impersonatedMerchantUserId");
              await loginAuth().then((res) => {
                if (res.data?.user?.merchantAccountId) {
                  // getMerchantData(res.data.user.merchantAccountId);
                }
              });
              setUserId();
              setIdOfUser();
              setSelectedUser();
            }}
            style={{
              // padding: "8px 8px",
              // borderRadius: "8px",
              fontFamily: "Poppins",
              fontSize: mdfontsize,
              color: Colors.fontGreyLight,
              marginRight: isdesktop ? "20px" : "20px",
              marginLeft: isdesktop ? "30px" : "40px",
              outline: "none",
              fontWeight: "500",
            }}
          >
            Clear User
          </button>
        )}
      </div>
      <div
        style={{
          marginTop: "24px",
          padding: "24px 16px",
          borderRadius: "10px",
          fontFamily: "Poppins",
          position: "relative",
        }}
        className="w-full shadow-md flex flex-col"
      >
        {userLogsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              marginTop: "100px",
              marginBottom: "100px",
            }}
          >
            <Loading color={Colors.bluePrimary} className="h-14 w-14 animate-spin" />
          </div>
        ) : (
          <div style={{ color: Colors.fontDark }}>
            {userLogs?.items?.map((item, index) => {
              return (
                <div style={{ marginBottom: "20px" }} key={index}>
                  {JSON.stringify(item ?? {})}
                </div>
              );
            })}
            {JSON.stringify(userLogs.items ?? {})}
          </div>
        )}
      </div>
    </>
  );
};

export default LogsPage;
