import moment from "moment";

export const constructName = (firstName, lastName) => {
  let nameArray = [firstName, lastName];
  nameArray = nameArray.filter(Boolean);
  return nameArray.join(" ");
};

export const errorMessagesSerive = {
  payment_method: "Please provide a default payment method before creating deliveries.",
  account_overdrafted: "Your account has been suspended due to past-due balance. Please add a payment method to resume service.",
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getDateObject = (date, format) => {
  return {
    day: parseInt(moment(date, format).format("DD")),
    month: parseInt(moment(date, format).format("MM")),
    year: parseInt(moment(date, format).format("YYYY")),
  };
};

export const getPreviousMonthDate = ({ prevMonth, addDay = false, isStart = false, format = "YYYY-MM-DD" }) => {
  let lastmonthlastdate = moment().subtract(prevMonth, "months");

  if (addDay) {
    lastmonthlastdate = lastmonthlastdate.add(addDay, "days");
  }
  if (isStart) {
    lastmonthlastdate = lastmonthlastdate.startOf("month");
  }
  lastmonthlastdate = lastmonthlastdate.format(format);

  const lastmonthfirstdate = moment().subtract(prevMonth, "months").endOf("month").format(format);

  const date = {
    monthStart: lastmonthlastdate,
    monthEnd: lastmonthfirstdate,
    monthStartObject: getDateObject(lastmonthlastdate, format),
    monthEndObject: getDateObject(lastmonthfirstdate, format),
  };

  return date;
};

export const canCreateDeliveriesService = (capabilities) => {  
  const createDeliveryCapability = capabilities?.find((capability) => capability.name === "create_delivery");
  const canCreateDelivery = !!createDeliveryCapability?.enabled;
  return {
    isEnabled: canCreateDelivery,
    pendingRequirments: createDeliveryCapability?.pending_requirements || [],
  };
};

export const canSeeStatementsTab = (capabilities) => {
  const statementsSeeCapability = capabilities?.find((capability) => capability.name === "view_billing_statements");
  const canSeeStatements = !!statementsSeeCapability?.enabled;
  return {
    isEnabled: canSeeStatements,
    pendingRequirments: statementsSeeCapability?.pending_requirements,
  };
};

export const mapEnvironment = (env) => {
  switch (env) {
    case "prod":
      return "production";
    case "preprod":
      return "preprod";
    default:
      return "local";
  }
};

export const findMinValueResource = (resourceList, key, type = "number") => {
  switch (type) {
    case "number": {
      const min = Math.min(...resourceList.map((item) => item[key]));
      return resourceList.filter((item) => item[key] === min);
    }
    case "date": {
      const min = Math.min(...resourceList.filter((item) => !!item[key]).map((item) => new Date(item[key]).getTime()));
      return resourceList.filter((item) => new Date(item[key]).getTime() === min);
    }
    default:
      return [];
  }
};

export const sortObjectArrayByOrder = (data, order) => {
  const arr = [...data];
  arr.sort((a, b) => {
    const orderA = order.indexOf(a.name);
    const orderB = order.indexOf(b.name);

    if (orderA !== -1 && orderB !== -1) {
      return orderA - orderB;
    }

    if (orderA !== -1) {
      return -1;
    }

    if (orderB !== -1) {
      return 1;
    }

    return 0;
  });

  return arr;
};

export const calculateCostLineItemsTotalAmount = (data) => {
  if (!data?.length) return 0;
  return data.reduce((acc, lineItem) => {
    return acc + lineItem.amount;
  }, 0);
};

export const calculateCostLineDeliveryFee = (data) => {
  if (!data?.length) return 0;
  const deliveryFeeItem = data.find((item) => item.name === "Delivery Fee");
  return deliveryFeeItem ? deliveryFeeItem.amount : 0;
};
export const calculateCostLineDeliveryAndTransactionFee = (data) => {
  if (!data?.length) return 0;
  const deliveryFeeItem = data.find((item) => item.name === "Delivery Fee");
  const transactionFeeItem = data.find((item) => item.name === "Transaction Fee");
  return deliveryFeeItem ? deliveryFeeItem.amount + transactionFeeItem.amount : 0;
};

export const separateName = (fullName) => {
  const nameParts = fullName.split(" ");
  const firstName = nameParts.shift();
  const lastName = nameParts.join(" ");
  return {
    firstName,
    lastName,
  };
};

export const phoneNumberHandler = (phoneNumber) => {
  const input = phoneNumber.replace(/\D/g, "");
  const hasCountryCode = input.length > 10;
  const number = hasCountryCode ? input.substring(1, 11) : input.substring(0, 10);
  const formattedInput = number.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/, "+1 ($1) $2 $3");
  return formattedInput;
};

export const badgesForDeliveryStatuses = {
  delivery_created: { type: "warning", label: "Delivery Created" },
  driver_assigned: { type: "warning", label: "Driver Assigned" },
  driver_not_assigned: { type: "warning", label: "Assigning Driver" },
  pending: { type: "warning", label: "Pending" },

  enroute_pickup: { type: "pickup", label: "Enroute to Pick-up" },
  arrived_at_pickup: { type: "pickup", label: "Arrived at Pick-up" },
  pickup_complete: { type: "pickup", label: "Pick-up Complete" },
  pickup: { type: "pickup", label: "Pickup" },

  enroute_dropoff: { type: "dropoff", label: "Enroute to Drop-off" },
  arrived_at_dropoff: { type: "dropoff", label: "Arrived at Drop-off" },
  dropoff_complete: { type: "dropoff", label: "Drop-off Completed" },
  dropoff: { type: "dropoff", label: "Dropoff" },

  delivered: { type: "success", label: "Delivered" },
  completed: { type: "success", label: "Completed" },

  provider_canceled: { type: "error", label: "Provider Canceled" },
  customer_canceled: { type: "error", label: "Merchant Canceled" },
  burq_canceled: { type: "error", label: "Burq Canceled" },
  customer_cancelled: { type: "error", label: "Merchant Canceled" },
  expired: { type: "error", label: "Expired" },
  failed: { type: "error", label: "Failed" },
  disputed: { type: "error", label: "Disputed" },
  canceled: { type: "error", label: "Canceled" },

  contacting_provider: { type: "primary", label: "Contacting Provider" },
  scheduled: { type: "primary", label: "Scheduled" },
  returned: { type: "primary", label: "Returned" },
  enroute_to_return: { type: "primary", label: "Enroute to Return" },

  request: { type: "neutral", label: "Not Initiated" },
  
  //computed statuses for reroute
  canceled_not_reroutable: { type: "error", label: "Canceled - Not Reroutable" },
  attempting_reroute: { type: "attempting_reroute", label: "Attempting reroute" },
  rerouted: { type: "reroute", label: "Rerouted" },
};


export const getStatusForBadge = ({deliveryStatus, rerouteStatus}) => {
  if (deliveryStatus === "provider_canceled" && rerouteStatus === "not_reroutable") {
    return "canceled_not_reroutable"
  } else if (rerouteStatus === "provider_search") {
    return "attempting_reroute"
  } else if (rerouteStatus === "rerouted") {
    return "rerouted"
  } else
    return deliveryStatus;
}

export const mapNotRerouteReason = {
  "no_alternative_provider": "No alternate provider", 
  "alternative_too_expensive": "Alternative too expensive",
  "items_not_available": "Items not available",
  "customer_cancelled": "Customer cancelled",
  "already_picked_up": "Already picked up",
  "restaurant_closed": "Restaurant closed",
  "merchant_requested": "Merchant Requested",
  "merchant_disabled_auto_reroute": "Merchant disabled auto reroute",
  "already_rerouted_too_many_times": "Already rerouted too many times",
  "failed_to_create_quotes": "Failed to create quotes",
  "merchant_disabled_manual_reroute": "Merchant disabled manual reroute",
};

export const mapRerouteReason = {
  "provider_canceled": "Provider canceled",
  "no_driver_assignment": "Driver not found",
  "customer_request": "Customer requested",
  "merchant_rerouted": "Merchant rerouted",
}
