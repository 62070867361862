import React, { useEffect, useState } from "react";
import { useMerchant, useMyStore, useTheme, userDataStore } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { Loading, SearchSvg } from "../../../assets/svgs/Svgs";
import ButtonComp from "../../ButtonComp/ButtonComp";

import PagingWithNextAndPrevButtons from "../../PagingWithNextAndPrevButtons";
import Users from "./UsersListItem";
import CustomModal from "../../CustomModal/CustomModal";
import PricingRules from "./PricingRules";
import styles from "./styles/styles.module.css";
import Pagination from "../../shared/Pagination";
import { removeCookie } from "../../../utils";

const UsersForAdmin = () => {
  const { Colors } = useTheme();

  const { usersList, userId, setUserId, deliveries, usersListHandler, isUsersListRequestLoading } = useMyStore();

  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();

  const { setisSmsTrue, loginAuth } = userDataStore();

  const { setIdOfUser, setSelectedUser } = useMerchant();

  // const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");

  const [selectedUserRow, setSelectedUserRow] = useState(null);
  const gerUsersList = async (params) => {
    usersListHandler({ ...{ searchKey: search }, ...params });
  };

  useEffect(() => {
    gerUsersList();
  }, []);

  return (
    <>
      <div className={styles.headerContainer}>
        <div
          style={{
            fontWeight: "500",
            fontSize: mdfontsize,
            color: Colors.fontDark,
            display: "flex",
            alignItems: "center",
          }}
        >
          Users
          <div style={{ opacity: 0.7, marginLeft: "5px" }}>{`(${usersList?.data.length})`}</div>
          {userId && (
            <button
              type="button"
              onClick={async () => {
                removeCookie("impersonatedMerchantAccountId");
                removeCookie("impersonatedMerchantUserId");
                await loginAuth().then((res) => {});
                setSelectedUser();
                setUserId();
                setIdOfUser();
              }}
              style={{
                // padding: "8px 8px",
                // borderRadius: "8px",
                fontFamily: "Poppins",
                fontSize: mdfontsize,
                color: Colors.fontGreyLight,
                marginRight: isdesktop ? "20px" : "20px",
                marginLeft: isdesktop ? "30px" : "40px",
                outline: "none",
                fontWeight: "500",
              }}
            >
              Clear User
            </button>
          )}
        </div>
        {isdesktop && (
          <form
            style={{ width: "26vw", marginLeft: "80px" }}
            className="flex xl:justify-between justify-start"
            onSubmit={(e) => {
              gerUsersList({ searchKey: search });
              e.preventDefault();
            }}
          >
            <div
              style={{
                padding: "4px 10px",
                borderColor: Colors.borderPrimary,
                borderWidth: "1px",
                borderRadius: "10px",
              }}
              className="w-full flex items-center justify-evenly lg:px-0"
            >
              <SearchSvg />
              <input
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (e.target.value === "") {
                    gerUsersList({ searchKey: "" });
                  }
                }}
                style={{
                  fontFamily: "Poppins",
                  fontSize: smfontsize,
                  fontWeight: "normal",
                  backgroundColor: Colors.white,
                  color: Colors.fontDark,
                }}
                type="search"
                className="focus:outline-none mr-1 bg w-full  pl-2  py-1 "
                placeholder="Search"
                value={search}
              />
            </div>
            <ButtonComp
              title={"Search"}
              type="submit"
              extraStyles={{
                // height: "42px",
                color: Colors.fontGreyLight,
              }}
            />
          </form>
        )}
      </div>
      <div
        style={{
          marginTop: "24px",
          padding: "24px 16px",
          borderRadius: "10px",
          fontFamily: "Poppins",
          position: "relative",
        }}
        className="w-full shadow-md flex flex-col"
      >
        {isUsersListRequestLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              marginTop: "100px",
              marginBottom: "100px",
            }}
          >
            <Loading color={Colors.bluePrimary} className="h-14 w-14 animate-spin" />
          </div>
        )}

        {!isUsersListRequestLoading &&
          usersList?.data?.map((item, index) => {
            return (
              <Users
                item={item}
                key={item.id}
                deliveries={deliveries}
                setisSmsTrue={setisSmsTrue}
                setSelectedUserRow={setSelectedUserRow}
              />
            );
          })}
        <Pagination
          data={usersList}
          onGetPaginationData={gerUsersList}
          dependencies={[
            search,
            // searchByDropoffAddress,
            // searchByDropoffPhoneNum,
            // searchByDroppofName,
            // searchByPhoneNumber,
            // searchByName,
            // searchByAddress,
            // searchById,
            // startDate,
            // selectedProvider,
          ]}
        />
        {/* <PagingWithNextAndPrevButtons
          setPage={setPageCount}
          page={pageCount}
          result={usersList?.users?.length}
          plus
          itemsPerPage={50}
          usersList={usersList}
        /> */}
        <CustomModal
          extraPanelStyles={{ maxWidth: isdesktop ? "70%" : "80%" }}
          isOpen={!!selectedUserRow}
          onClose={() => {
            setSelectedUserRow(null);
          }}
        >
          <PricingRules selectedUser={selectedUserRow} setSelectedUser={setSelectedUserRow} />
        </CustomModal>
      </div>
    </>
  );
};

export default UsersForAdmin;
