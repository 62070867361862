import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import "./assets/output.css";
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Provider } from "aniuta";
import { Elements } from "@stripe/react-stripe-js";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";

import { toast, ToastContainer } from "react-toastify";

import {
  useClickStore,
  useConnectedAccounts,
  useDelInfoOnSidePopup,
  useMerchant,
  useMyStore,
  useOrderManagement,
  usePusherUpdates,
  userDataStore,
  useSingleItemStore,
  useStoreAndChild,
} from "./store";

import CreateStoreModal from "./modals/CreateStoreModal";
import CustomModal from "./components/CustomModal/CustomModal";

import InviteMember from "./modals/InviteMember";
// import AddUserToAccount from "./modals/AddUserToAccount";

import RemoveOrderModal from "./screens/OrderManagement/OrdersSide/RemoveOrderModal";

import { FlagProvider, useFlag, useFlagsStatus } from "@unleash/proxy-client-react";
import { useDesktopOrLaptop } from "./services/useDesktopOrLaptop";
import OrderTracking from "./components/OrderTracking";
import { baseUrl, stripePromise } from "./runTimeConst";
import ConnectedAccountOnboarding from "./components/ConnectedAccountOnboarding";
import { ConfigProvider } from "react-avatar";
import Hotjar from "@hotjar/browser";

import { constructName } from "./utils/helpers";
import CreateDelivery from "./screens/CreateDelivery/CreateDelivery";
import SettingsPage from "./components/SettingsPage";

import AddressBook from "./components/AddressBook";
import AccountSettings from "./components/AccountSettings/AccountSettings";
import ProviderIntegrationSettings from "./components/ProviderIntegrationSettings";
import V1Layout from "./components/V1Layout";
import PaymentsHistory from "./components/InvoicesHistory/PaymentsHistory";
import PaymentsHistoryList from "./components/InvoicesHistory/PaymentsHistoryList";
import PaymentSettings from "./components/PaymentSettings";
import StoreSettings from "./components/StoreSettings/StoreSettings";
import StoreDetailedPage from "./components/InsideOfStores/StoreDetailedPage";

import AdminPage from "./components/Admin";
import Workflows from "./components/workflow";
import OrderManagementScreen from "./screens/OrderManagement/OrderManagementScreen";
import TripManagementScreen from "./screens/TripManagement/TripManagementScreen";
import ConnectedAccounts from "./components/ConnectedAccounts";
import Analytics from "./components/Analytics";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./components/HomePage";
import StatementsAndTransactions from "./components/Billing/StatementsAndTransactions";
import StatementDetail from "./components/Billing/StatementsAndTransactions/PreviousStatements/StatementDetail";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import ProfileInformation from "./components/ProfileInformation";
import Onboarding from "./screens/Onboarding/Onboarding";
import RecentTrx from "./components/Billing/StatementsAndTransactions/RecentTrx";
import PreviousStatements from "./components/Billing/StatementsAndTransactions/PreviousStatements";

import ReferralSignup from "./components/ReferralSignup";
import TrackingPage from "./components/TrackingPage";
import InvoiceList from "./components/Billing/Invoices/InvoiceList";
import InvoiceDetails from "./components/Billing/Invoices/InvoiceDetails";

import DeliveryIncident from "./components/DeliveryIncident";

import CustomDetailsRightModal from "./components/CustomModal/CustomDetailsRightModal";
import DeliverySideDrawer from "./components/DeliveryDetailsOnSidePopup/DeliverySideDrawer";
// import DeliveryDetailsOnSidePopup from "./components/DeliveryDetailsOnSidePopup";
import useSocket from "./customHooks/useSocket";

import axios from "axios";
import ErrorPage from "./screens/ErrorPage";
import ReferralsPage from "./components/ReferralsPage";
import Home from "./components/Home";
import SmsWorkflow from "./components/workflow/SmsWorkflow";
import QuoteWorkflow from "./components/workflow/QuoteWorkflow";
import BrandingWorkflow from "./components/workflow/BrandingWorkflow";
import Cookies from "js-cookie";
import { setCookie, userRoleHandler } from "./utils";

require("dotenv").config();

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
const siteId = process.env.REACT_APP_HOTJAR_SITE_ID;

if (siteId) {
  Hotjar.init(siteId, 6);
}

// process.env.REACT_APP_ENVIRONMENT === "prod"
//   ? Hotjar.init(3524920, 6) // Devops Account
//   : Hotjar.init(3525711, 6); // Preprod Account

const config = {
  url: "https://unleash.burqup.com/api/frontend",
  clientKey: process.env.REACT_APP_UNLEASH_KEY,
  refreshInterval: 15,
  appName: "dashboard",
  environment: process.env.REACT_APP_STRIPE_RETURN_URL === "https://dashboard.burqup.com" ? "production" : "development",
};

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
};

const MainApp = () => {
  const location = useLocation();
  const { isCreateStoreModalOpen, setIsCreateStoreModalOpen, isInviteMemberVisible, setIsInviteMemberVisible, setStoreForEdit } =
    useStoreAndChild();
  const { getMerchantData } = useMerchant();

  const { setIsDeleteOrderModalVisible, isDeleteOrderModalVisible } = useOrderManagement();

  const { setDeliveriesUpdates, setNotificationsUpdates } = usePusherUpdates();

  const { setIsCalendarVisible, setAllTimeVisible } = useClickStore();
  const { isdesktop, smfontsize } = useDesktopOrLaptop();
  const { setSelectedConnectedAccount, setIsNewAccountCreated } = useConnectedAccounts();
  const { authInfo, getZendeskMessagingAuthToken } = userDataStore();
  const [isZendeskChatWidgetLoaded, setIsZendeskChatWidgetLoaded] = useState(false);

  let history = useNavigate();

  const firstLoader = async () => {
    posthog.identify(authInfo.id, {
      email: authInfo.email,
      merchant_accout_id: authInfo.merchantAccountId,
      user_id: authInfo.id,
      user_name: authInfo.name,
    });
    const impersonatedMerchantAccountId = Cookies.get("impersonatedMerchantAccountId");

    await getMerchantData(impersonatedMerchantAccountId || authInfo.merchantAccountId).then((res) => {
      // if (res?.id) {
      //   updateContext({ userId: res.id });
      // }
      if (!impersonatedMerchantAccountId && res) {
        Sentry.setUser({
          email: authInfo.email,
          id: authInfo?.merchantAccountId,
          username: constructName(authInfo.first_name, authInfo.last_name),
        });
        if (process.env.REACT_APP_ENVIRONMENT === "prod") {
          window.heap.identify(authInfo.id);
          window.heap.addUserProperties({
            accountId: authInfo.merchantAccountId,
            role: userRoleHandler(authInfo?.user_roles),
            email: authInfo.email,
            name: constructName(authInfo.first_name, authInfo.last_name),
            merchantAccountName: res?.name,
          });
        }
        if (process.env.REACT_APP_ENVIRONMENT === "prod") {
          window.pendo.initialize({
            visitor: {
              id: authInfo.id, // Required if user is logged in, default creates anonymous ID
              email: authInfo.email, // Recommended if using Pendo Feedback, or NPS Email
              full_name: constructName(authInfo.first_name, authInfo.last_name), // Recommended if using Pendo Feedback
              merchant_account_id: authInfo?.merchantAccountId,
              role: authInfo.role,
              status: authInfo?.status, // Optional
              phone_number: res?.phone_number ?? "",
              industry: res?.standardized_industry,
            },
          });
        }
        if (!res?.standardized_industry) {
          if (res.account_type !== "connected") history("/v1/home");
        }
      }
    });
  };

  const { deliveryIdFromQueryParam, setDeliveryIdFromQueryParam } = useDelInfoOnSidePopup();

  const [searchParams, setSearchParams] = useSearchParams();

  const sidePopupHandlerOnLoad = () => {
    const clientSecret = new URLSearchParams(window.location.search).get("deliveryId");
    setDeliveryIdFromQueryParam(clientSecret);
    if (clientSecret) {
      setAllTimeVisible(false);
      setIsCalendarVisible(false);
    }
  };

  const isNewIncidentPageEnabled = useFlag("isNewIncidentPageEnabled");

  const isNewHomePageEnabled = useFlag("isNewHomePageEnabled");
  const analyticsAsHomeEnabled = useFlag("analyticsAsHomeEnabled");
  const zendeskMessagingAuthTokenHandler = async () => {
    const data = await getZendeskMessagingAuthToken();
    if (data?.token) {
      ZendeskAPI("messenger", "loginUser", function (callback) {
        callback(`${data.token}`);
      });
    }
  };
  const { message, notificationsData } = useSocket(
    authInfo?.merchantAccountId
      ? {
          channelName: `merchant@${authInfo?.merchantAccountId}`,
          notificationsEvent: `user_notification_${authInfo?.id}`,
        }
      : { channelName: undefined, notificationsEvent: null }
  );

  useEffect(() => {
    if (message) {
      setDeliveriesUpdates(message);
    }
  }, [message]);
  useEffect(() => {
    if (notificationsData) {
      setNotificationsUpdates(notificationsData);
    }
  }, [notificationsData]);

  useEffect(() => {
    if (authInfo && isZendeskChatWidgetLoaded) {
      zendeskMessagingAuthTokenHandler();
    }
    if (authInfo) {
      firstLoader();
    }
  }, [authInfo, isZendeskChatWidgetLoaded]);

  const zendeskLoaded = () => {
    setIsZendeskChatWidgetLoaded(true);
    ZendeskAPI("messenger", "close");
  };
  useEffect(() => {
    sidePopupHandlerOnLoad();
  }, [window.location.href]);

  useEffect(() => {
    if (authInfo && Hotjar.isReady()) {
      Hotjar.identify(authInfo.id, {
        email: authInfo.email,
        username: constructName(authInfo.first_name, authInfo.last_name),
      });
    }
  }, [authInfo]);
  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) return <></>;
  const excludedPaths = []; // Add your patterns here
  // const excludedPaths = ["/v1/orderManagement", "/v1/workflows/order-workflow"]; // Add your patterns here

  return (
    <FlagProvider config={{ ...config, context: { userId: authInfo?.merchantAccountId ?? undefined } }}>
      <>
        <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
          {/* <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={zendeskLoaded} /> */}

          {(process.env.REACT_APP_ENVIRONMENT === "prod" || !process.env) && (
            <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={zendeskLoaded} />
          )}
          <ToastContainer
            style={{
              fontSize: smfontsize,
              width: isdesktop ? 400 : 600,
              textAlign: "center",
            }}
            position="top-center"
            autoClose={7000}
            // hideProgressBar
            newestOnTop
            closeOnClick
            draggable={false}
            closeButton={false}
          />
          <CustomModal
            extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
            isOpen={isCreateStoreModalOpen}
            onClose={() => {
              setIsCreateStoreModalOpen(false);
              setSelectedConnectedAccount();
              setIsCalendarVisible(true);
              setIsNewAccountCreated(false);
              setStoreForEdit();
            }}
            notCloseOnOutside={true}
          >
            <CreateStoreModal />
          </CustomModal>
          {authInfo && (
            <CustomDetailsRightModal
              extraStyles={{
                width: isdesktop && `100%`,
              }}
              isOpen={!!deliveryIdFromQueryParam}
              onClose={() => {
                setSearchParams({ deliveryId: "" });
                setDeliveryIdFromQueryParam();
                setAllTimeVisible(true);
                setIsCalendarVisible(true);
              }}
            >
              <DeliverySideDrawer setSearchParams={setSearchParams} authInfo={authInfo} />
            </CustomDetailsRightModal>
          )}
          <CustomModal
            extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
            isOpen={isDeleteOrderModalVisible}
            onClose={() => {
              setIsDeleteOrderModalVisible(false);
            }}
          >
            <RemoveOrderModal />
          </CustomModal>

          <CustomModal
            extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
            isOpen={isInviteMemberVisible}
            onClose={() => setIsInviteMemberVisible(false)}
          >
            <InviteMember />
          </CustomModal>
          {/* <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={isInviteUserModalVisible || !!editUserData}
        onClose={() => {
          setIsInviteUserModalVisible(false);
          setEditUserData(null);
        }}
      >
        <AddUserToAccount />
      </CustomModal> */}
          {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
          <Routes>
            <Route path="/v1" element={<V1Layout />}>
              <Route path=":id" />
              <Route path="admin" element={<AdminPage />} />
              {isNewHomePageEnabled && <Route path="testPage" element={<Home />} />}
              {analyticsAsHomeEnabled ? (
                <Route path="home" element={<Analytics />} />
              ) : (
                <Route path="home" element={<HomePage />} />
              )}
              {!analyticsAsHomeEnabled && <Route path="analytics" element={<Analytics />} />}
              <Route path="deliveries" element={<HomePage />} />
              {/* <Route path="deliveryIncident" element={<DeliveryIncident />} /> */}
              {!isNewIncidentPageEnabled && <Route path="deliveryIncident" element={<DeliveryIncident />} />}
              {/* <Route path="deliveryIncident" element={<DeliveryIncident />} /> */}
              {/* <Route path="developers" element={<Webhooks />} /> */}
              <Route path="referrals" element={<ReferralsPage />} />

              <Route path="workflows" element={<Workflows />}>
                <Route index element={<SmsWorkflow />} />
                <Route path="quote" element={<QuoteWorkflow />} />
                <Route path="branding" element={<BrandingWorkflow />} />
              </Route>
              <Route path="accounts" element={<ConnectedAccounts />} />
              {/* <Route path="trips" element={<TripManagementScreen />} /> */}

              <Route path="deliveryProviders" element={<ProviderIntegrationSettings />} />

              <Route path="stores">
                <Route index element={<StoreSettings />} />
                <Route path=":id" element={<StoreDetailedPage />} />
              </Route>
              <Route path="settings" element={<SettingsPage />}>
                <Route path="payments" element={<PaymentsHistory />}>
                  <Route index element={<PaymentSettings />} />
                  <Route path="history" element={<PaymentsHistoryList />} />
                </Route>
                <Route path="details" element={<ProfileInformation />} />
                <Route path="addressBook" element={<AddressBook />} />
                <Route path="members" element={<AccountSettings />} />

                <Route path="notifications" element={<></>} />
                {/* <Route path="notifications" element={<NotificationsSettings />} /> */}

                <Route path="billing" element={<StatementsAndTransactions />}>
                  <Route index element={<RecentTrx />} />
                  <Route path="invoice">
                    <Route index element={<InvoiceList />} />
                    <Route path=":id" element={<InvoiceDetails />} />
                  </Route>
                  <Route path="statement">
                    <Route index element={<PreviousStatements />} />
                    <Route path=":id" element={<StatementDetail />} />
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route exact path="/referral_signup" element={<ReferralSignup />} />
            <Route exact path="/delivery" element={<Create />} />
            {/* <Route exact path="/order" element={<CreateOrderRoute />} /> */}
            {/* <Route exact path="/trip" element={<CreateTripRoute />} /> */}
            <Route exect path="/onboarding/platform_accounts/:id" element={<ConnectedAccountOnboarding />} />
            <Route exect path="/onboarding/:id" element={<Onboarding />} />

            <Route path="/reroute/:id" element={<Reroute />} />
            <Route path="/rerouteEdit/:id" element={<RerouteEdit />} />

            {/* <Route path="/tracking/:id" element={<MapForDeliveries />} /> */}
            <Route path="/track/:id" element={<TrackingPage />} />
            <Route path="/orders/track/:id" element={<TrackingPage />} />
            {/* <Route path="/tracking/:id" element={<TrackingPage />} /> */}
            <Route path="/trackOrder/:id" element={<OrderTracking />} />
            <Route path="/status" element={<ErrorPage />} />

            {!excludedPaths.some((path) => location.pathname.startsWith(path)) && (
              <Route path="*" element={<Navigate to="/v1/home" replace />} />
            )}
          </Routes>
          {/* </BrowserRouter> */}
        </PostHogProvider>
      </>
    </FlagProvider>
  );
};

// const CreateOrderRoute = () => {
//   return (
//     <Elements stripe={stripePromise}>
//       <CreateOrder />
//     </Elements>
//   );
// };
// const CreateTripRoute = () => {
//   return (
//     <Elements stripe={stripePromise}>
//       <CreateTrip />
//     </Elements>
//   );
// };
const Create = () => {
  return (
    <Elements stripe={stripePromise}>
      <CreateDelivery create={true} />
    </Elements>
  );
};

const Reroute = () => {
  const { rerouteItem, isMerchantReroute, setRerouteItem } = useSingleItemStore();
  const { setIsSandbox, isSandbox } = useMyStore();
  const { id } = useParams();

  const getDeliveryInfo = async (id) => {
    try {
      const { data } = await axios.get(`${baseUrl}delivery/${id}`, {
        withCredentials: false,
      });
      if (data) {
        if (data.testMode !== isSandbox) {
          setIsSandbox(data.testMode);
          setCookie("testModeCookie", data?.testMode ? "true" : "false");
        }
      }

      setRerouteItem(data);
    } catch (err) {
      toast.error(err.response?.data?.message === "Not Found" ? `Requested Id ${err.response.data.message}` : "Not Found");
    }
  };
  useEffect(() => {
    if (!rerouteItem && id) {
      getDeliveryInfo(id);
    }
  }, [id]);
  if (rerouteItem) {
    return (
      <Elements stripe={stripePromise}>
        <CreateDelivery isReroute={true} isMerchantReroute={isMerchantReroute} rerouteItem={rerouteItem} />
      </Elements>
    );
  } else return <></>;
};

const RerouteEdit = () => {
  const { rerouteItem, setRerouteItem } = useSingleItemStore();
  const { setIsSandbox, isSandbox } = useMyStore();
  const { id } = useParams();
  const getDeliveryInfo = async (id) => {
    try {
      const { data } = await axios.get(`${baseUrl}delivery/${id}`, {
        withCredentials: false,
      });
      if (data) {
        if (data.testMode !== isSandbox) {
          setIsSandbox(data.testMode);
          setCookie("testModeCookie", data?.testMode ? "true" : "false");
        }
      }

      setRerouteItem(data);
    } catch (err) {
      toast.error(err.response?.data?.message === "Not Found" ? `Requested Id ${err.response.data.message}` : "Not Found");
    }
  };
  useEffect(() => {
    if (!rerouteItem && id) {
      getDeliveryInfo(id);
    }
  }, [id]);
  if (rerouteItem) {
    return (
      <Elements stripe={stripePromise}>
        <CreateDelivery edit={true} rerouteItem={rerouteItem} />
        {/* <RerouteDelivery /> */}
      </Elements>
    );
  } else return <></>;
};

const MainAppUnleashWrapper = () => {
  const { authInfo, loginAuth } = userDataStore();
  const { setIdOfUser } = useMerchant();
  const { setUserId } = useMyStore();
  // const updateContext = useUnleashContext();
  const mainLoader = async () => {
    await loginAuth(true).then((res) => {
      // if (res?.id) {
      // updateContext({ userId: res.id });
      // }
    });
    const impersonatedMerchantAccountId = Cookies.get("impersonatedMerchantAccountId");
    const impersonatedMerchantUserId = Cookies.get("impersonatedMerchantUserId");
    if (impersonatedMerchantUserId) {
      setIdOfUser(impersonatedMerchantUserId);
    }
    if (impersonatedMerchantAccountId) {
      setUserId(impersonatedMerchantAccountId);
    }
  };

  useEffect(() => {
    mainLoader();
  }, []);

  // if (!authInfo) return <></>;

  return (
    <FlagProvider config={{ ...config, context: { userId: authInfo?.merchantAccountId ?? undefined } }}>
      <MainApp />
    </FlagProvider>
  );
};

function App() {
  return (
    <Sentry.ErrorBoundary>
      <Provider>
        <ConfigProvider colors={["#82C43C", "#FF676E", "#50B5FF", "#FF974A", "#A461D8"]}>
          {/* <FlagProvider config={config}> */}
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <MainAppUnleashWrapper />
          </BrowserRouter>
          {/* </FlagProvider> */}
        </ConfigProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
