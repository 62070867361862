import * as React from "react";

const BurqLogo = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 400 182" fill="none">
    <path
      fill="#0062FF"
      fillRule="evenodd"
      d="M58.64 11.617C58.64 5.201 63.84 0 70.257 0s11.617 5.201 11.617 11.617v39.938a44.9 44.9 0 0 1 21.852-5.64c24.901 0 45.086 20.185 45.086 45.085s-20.185 45.085-45.086 45.085h-61.13c-5.804 0-10.51-4.706-10.51-10.511s4.706-10.51 10.51-10.51H57.81a6.638 6.638 0 1 0 0-13.277H20.47c-5.805 0-10.511-4.706-10.511-10.51 0-5.805 4.706-10.511 10.51-10.511h37.065a6.915 6.915 0 1 0 0-13.83H42.598c-5.805 0-10.511-4.706-10.511-10.51 0-5.805 4.706-10.511 10.51-10.511H58.64zM81.874 91c0 12.068 9.784 21.851 21.852 21.851 12.069 0 21.852-9.783 21.852-21.851s-9.783-21.851-21.852-21.851c-12.068 0-21.851 9.783-21.851 21.851"
      clipRule="evenodd"
    ></path>
    <path
      fill="#0062FF"
      d="M205.793 136.362c-25.053 0-45.363-20.309-45.363-45.362V57.809c0-6.416 5.201-11.617 11.617-11.617s11.617 5.2 11.617 11.617V91c0 12.221 9.908 22.128 22.129 22.128s22.128-9.907 22.128-22.128V57.809c0-6.416 5.201-11.617 11.617-11.617s11.618 5.2 11.618 11.617V91c0 25.053-20.31 45.362-45.363 45.362M21.022 56.426c0 5.804-4.706 10.51-10.511 10.51S0 62.23 0 56.426c0-5.805 4.706-10.511 10.51-10.511 5.806 0 10.512 4.706 10.512 10.51M296.242 43.702c-18.484 0-33.469 14.984-33.469 33.468v45.085c0 6.416 5.201 11.617 11.617 11.617s11.618-5.201 11.618-11.617V77.17c0-5.652 4.582-10.234 10.234-10.234 6.416 0 11.617-5.2 11.617-11.617 0-6.416-5.201-11.617-11.617-11.617"
    ></path>
    <path
      fill="#0062FF"
      fillRule="evenodd"
      d="M399.373 91c0-24.9-20.186-45.085-45.087-45.085-24.9 0-45.086 20.185-45.086 45.085s20.186 45.085 45.086 45.085a44.9 44.9 0 0 0 21.852-5.64v39.938c0 6.416 5.201 11.617 11.617 11.617s11.618-5.201 11.618-11.617V91.277l-.001-.073.001-.118zm-66.938 0c0-12.068 9.783-21.851 21.851-21.851 12.069 0 21.852 9.783 21.852 21.851s-9.783 21.851-21.852 21.851c-12.068 0-21.851-9.783-21.851-21.851"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default BurqLogo;
