import React, { useState } from "react";
import { RiMapPin2Line } from "react-icons/ri";
import { TbPhone } from "react-icons/tb";

import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import {
  useMerchant,
  userDataStore,
  useStoreAndChild,
  useTheme,
} from "../../../store";

import { AiFillBank } from "react-icons/ai";

import { cardLogoHandler } from "../../InsideOfStores/UtilsComponents";
import { VscTrash } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import AreYouSurePopup from "../../shared/common/AreYouSurePopup";
import Badge from "../../shared/atoms/Badge";
import { ROLES } from "../../AccountSettings/RolesSidebar/helpers";

const StoreItem = ({ store, connectedAccount, paymentMethod }) => {
  const { Colors, themeChanger } = useTheme();
  const { deleteStore, getAllStoresByUser, setSelectedStore } =
    useStoreAndChild();

  let history = useNavigate();

  const { authInfo, userRole, isAdmin } = userDataStore();

  const { isdesktop, smfontsize, xsfontsize } = useDesktopOrLaptop();

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const storeRemoveHandler = async () => {
    await deleteStore(store.id);
    setIsDeleteModalOpened(false);
    if (connectedAccount) {
      await getAllStoresByUser(connectedAccount.id, true);
    } else {
      await getAllStoresByUser(authInfo.merchantAccountId);
    }
  };
  return (
    <div
      onClick={() => {
        if (!connectedAccount) {
          setSelectedStore(store);
        } else if (connectedAccount) {
          setSelectedStore(store);
        }
        history(`/v1/stores/${store.id}`);
      }}
      style={{
        borderWidth: "1px",
        borderRadius: "10px",
        borderColor: "#D2E0F0",
        padding: "18px 14px",
        fontSize: smfontsize,
        fontWeight: "400",
      }}
      className={`flex flex-col mt-5 cursor-pointer ${
        themeChanger ? "hover:bg-gray-200" : "hover:bg-gray-600"
      } `}
    >
      <AreYouSurePopup
        isOpen={isDeleteModalOpened}
        onClose={() => {
          setIsDeleteModalOpened(false);
        }}
        onYes={storeRemoveHandler}
      />

      <div className="flex w-full items-center justify-between">
        <div className="flex w-full items-center">
          <div
            style={{
              color: Colors.fontGreyLight,
              width: isdesktop ? "408px" : "300px",
              fontWeight: "500",
              fontSize: smfontsize,
            }}
          >
            {store?.id}
          </div>

          <div
            style={{
              color: Colors.fontDark,
              marginLeft: "10px",
            }}
          >
            {store?.name}
          </div>

          {store?.source_demand_side_platform && (
            <div
              className="capitalize"
              style={{
                color: Colors.fontDark,
                marginLeft: "10px",
              }}
            >
              <Badge
                label={store?.source_demand_side_platform}
                type="error"
              />
            </div>
          )}
        </div>
        {(userRole === ROLES.ADMIN || isAdmin) && (
          <button
            onClick={async (e) => {
              setIsDeleteModalOpened(true);
              e.stopPropagation();
            }}
            type="button"
            className="outline-none"
          >
            <VscTrash className="text-red-600" size={22} />
          </button>
        )}
      </div>
      <div
        style={{ fontSize: xsfontsize, color: Colors.fontGreyLight }}
        className="flex w-full items-center mt-5 mb-3"
      >
        <div
          style={{ maxWidth: isdesktop ? "80%" : "50%" }}
          className="flex items-center "
        >
          <span style={{ marginBottom: "2px" }}>
            <RiMapPin2Line size={16} />
          </span>
          <span
            className="whitespace-nowrap truncate w-full pr-2"
            style={{ paddingLeft: "4px" }}
          >
            {store?.address}
          </span>
        </div>

        <div
          style={{ maxWidth: !isdesktop && "20%" }}
          className="flex items-center ml-5"
        >
          <span>
            <TbPhone />
          </span>
          <span>
            {store?.phone_number[0] === "+"
              ? store?.phone_number
              : "+" + store?.phone_number}
          </span>
        </div>
        <div
          style={{ maxWidth: !isdesktop && "25%" }}
          className="flex items-center ml-10"
        >
          <span className="whitespace-nowrap truncate w-full pr-2">
            {store?.merchant_store_id && store.merchant_store_id}
          </span>
        </div>
      </div>

      {paymentMethod && (
        <div
          style={{
            padding: "2px 0px",
            borderRadius: "8px",
            marginBottom: "4px",
            textAlign: "center",
          }}
        >
          <div className="flex items-center">
            {paymentMethod?.type === "us_bank_account" ? (
              <div
                style={{
                  borderWidth: "1px",
                  borderColor: Colors.borderPrimary,
                }}
                className="p-1 rounded"
              >
                <AiFillBank size={20} />
              </div>
            ) : (
              cardLogoHandler(paymentMethod?.card_issuer, xsfontsize, Colors)
            )}
            <div className="text-gray-400 ml-3 font-medium">
              <span
                style={{
                  fontSize: smfontsize,
                  color: Colors.fontGreyLight,
                }}
                className="text-gray-800"
              >
                ****
              </span>{" "}
              <span
                style={{
                  fontSize: smfontsize,
                  color: Colors.fontGreyLight,
                }}
                className="text-gray-800"
              >
                {" " + paymentMethod?.public_identifier}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreItem;
