import React from "react";
import Cookies from "js-cookie";
import NumberFormat from "react-number-format";

import { Form, Formik } from "formik";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useMerchant, useMyStore, useTheme, userDataStore } from "../../store";
import { profileInformationValidationSchema } from "../../utils/validationSchemas";
import { baseUrl } from "../../runTimeConst";
import ProfileImageContainer from "./ProfileImageContainer";
import FormikTextInput from "../FormikTextInput";
import { BsExclamationCircle } from "react-icons/bs";
import { FaRegClipboard } from "react-icons/fa";
import { Loading } from "../../assets/svgs/Svgs";
import { toast } from "react-toastify";
import moduleStyles from "./styles.module.css";
import moment from "moment";
import { ROLES } from "../AccountSettings/RolesSidebar/helpers";
import CopyToClipboard from "../shared/atoms/CopyToClipboard";
import { removeCookie } from "../../utils";

const industries = [
  { front: "Food", back: "food" },
  { front: "Catering", back: "catering" },
  { front: "Grocery", back: "grocery" },
  { front: "Flowers", back: "flowers" },
  { front: "Laundry / Dry Cleaning", back: "laundry_dry_cleaning" },
  { front: "Pharmacy", back: "pharmacy" },
  { front: "Ecommerce / Parcel", back: "ecommerce_parcel" },
  { front: "Auto Parts", back: "auto_parts" },
  { front: "Vape / Tobacco", back: "vape_tobacco" },
  { front: "Alcohol", back: "alcohol" },
  { front: "Cannabis / CBD", back: "cannabis_cbd" },
];

function ProfileInformation() {
  const { isdesktop, mdfontsize, lgfontsize, smfontsize } = useDesktopOrLaptop();

  const { Colors } = useTheme();

  const { merchantData, updateMerchantData } = useMerchant();
  const { userRole, isAdmin } = userDataStore();

  const { authInfo, setAvatar } = userDataStore();

  const { userId } = useMyStore();
  const saveDataHandler = async (values, image_url) => {
    if (image_url) {
      values.image_url = image_url;
    }
    const prevEmail = merchantData.email;
    const response = await updateMerchantData(userId || authInfo.merchantAccountId, values);

    if (response.data.status == 400) {
      return;
    }
    if (response.data?.email !== prevEmail) {
      Object.keys(Cookies.get()).forEach((cookieName) => {
        removeCookie(cookieName);
      });
      setTimeout(() => {
        window.location.href = `${baseUrl}auth/login`;
      }, 500);
    }
  };

  const referralLink = `${process.env.REACT_APP_STRIPE_RETURN_URL}/referral_signup?referrer=${authInfo.merchantAccountId}`;

  const styles = {
    inputs: {
      width: "100%",
      padding: "8px 12px",
      fontFamily: "Poppins",
      borderWidth: "1px",
      borderColor: Colors.borderPrimary,
      borderRadius: "10px",
      fontSize: mdfontsize,
      fontWeight: "400",
      color: Colors.fontDark,
      marginBottom: "14px",
      outline: "none",
      backgroundColor: Colors.white,
    },
    lables: {
      fontFamily: "Poppins",
      fontSize: mdfontsize,
      marginBottom: "10px",
      marginTop: "16px",
      fontWeight: "400",
      color: Colors.fontDark,
    },
  };

  if (authInfo?.merchantAccountId) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100% - 18px)",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "24px",
          }}
        >
          Account Details
        </div>
        {merchantData && (
          <Formik
            onSubmit={(values) => {
              saveDataHandler(values);
            }}
            validationSchema={profileInformationValidationSchema}
            enableReinitialize={true}
            initialValues={{ ...merchantData }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
              return (
                <Form>
                  <section
                    style={{
                      marginTop: "24px",
                      borderRadius: "8px",
                      padding: "16px",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontSize: lgfontsize,
                        fontWeight: "500",
                        color: Colors.fontDark,
                        marginBottom: "20px",
                      }}
                    >
                      <div className="w-full flex items-center justify-between">
                        <div>{merchantData?.account_type !== "connected" ? "Business Information" : "Information"}</div>
                        {merchantData?.created_at && (
                          <div className={moduleStyles.created}>
                            Account created at:{" "}
                            <span className={moduleStyles?.createdDate}>
                              {moment(merchantData?.created_at).format("MMM D, YYYY, h:mma")}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        borderBottomWidth: "2px",
                        borderColor: Colors.borderPrimary,
                        marginBottom: "20px",
                      }}
                    ></div>
                    {/* <ProfileImageContainer
                      values={values}
                      saveDataHandler={saveDataHandler}
                    /> */}

                    <div
                      style={{
                        width: isdesktop ? "60%" : "90%",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: mdfontsize,
                          marginBottom: "10px",
                          fontWeight: "400",
                          color: Colors.fontDark,
                        }}
                      >
                        Business Name
                      </div>
                      <FormikTextInput disabled name="name" lable="name" placeholder="Business name" marginbottom="20px" />
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: mdfontsize,
                          marginBottom: "10px",
                          fontWeight: "400",
                          color: Colors.fontDark,
                        }}
                      >
                        Business Email
                      </div>
                      <FormikTextInput name="email" lable="email" placeholder="Business email" marginbottom="20px" />
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: mdfontsize,
                          marginBottom: "10px",
                          fontWeight: "400",
                          color: Colors.fontDark,
                        }}
                      >
                        Developer Email
                      </div>
                      <FormikTextInput
                        name="developer_email"
                        lable="developer_email"
                        placeholder="Developer Email"
                        marginbottom="20px"
                      />
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: mdfontsize,
                          marginBottom: "10px",
                          fontWeight: "400",
                          color: Colors.fontDark,
                        }}
                      >
                        Billing Email
                      </div>
                      <FormikTextInput
                        name="billing_email"
                        lable="billing_email"
                        placeholder="Billing email"
                        marginbottom="20px"
                      />
                      {merchantData.account_type !== "connected" && (
                        <>
                          <div
                            style={{
                              fontFamily: "Poppins",
                              fontSize: mdfontsize,
                              marginBottom: "10px",
                              fontWeight: "400",
                              color: Colors.fontDark,
                            }}
                          >
                            Business Address
                          </div>
                          <FormikTextInput
                            disabled={authInfo.role === "admin"}
                            // disabled
                            address
                            setFieldValue={setFieldValue}
                            // isClicked={isClickedOnContinue}
                            name="address"
                            lable="address"
                            placeholder="Address"
                            marginbottom="20px"
                          />
                        </>
                      )}
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          marginBottom: "10px",
                          marginTop: "26px",
                          fontWeight: "400",
                          color: Colors.fontDark,
                        }}
                      >
                        Phone Number
                      </div>
                      <NumberFormat
                        // disabled={authInfo.role === "admin"}
                        name="phone_number"
                        style={styles.inputs}
                        placeholder="+# (###) ###-####"
                        type="tel"
                        format="+# (###) ###-####"
                        onValueChange={(e) => {
                          setFieldValue("phone_number", e.formattedValue);
                        }}
                        value={values.phone_number}
                        className={`px-5 w-full py-3 focus:outline-none`}
                      />
                      {merchantData?.account_type !== "connected" && (
                        <>
                          <div
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              marginBottom: "10px",
                              fontWeight: "400",
                              color: Colors.fontDark,
                            }}
                          >
                            Business Website
                          </div>
                          <FormikTextInput
                            // disabled={authInfo.role === "admin"}
                            url="true"
                            name="website"
                            lable="website"
                            placeholder="https://example.com"
                            marginbottom="20px"
                          />
                          <div
                            style={{
                              fontFamily: "Poppins",
                              fontSize: mdfontsize,
                              marginBottom: "10px",
                              fontWeight: "400",
                              color: Colors.fontDark,
                            }}
                          >
                            Industry
                          </div>
                          <select
                            // disabled={authInfo.role === "admin"}
                            name="standardized_industry"
                            value={values.standardized_industry}
                            draggable={true}
                            onChange={(e) => {
                              if (e.target.value === "Other") {
                                setFieldValue("isOtherChosen", true);
                              } else {
                                setFieldValue("isOtherChosen", false);
                              }
                              handleChange(e);
                            }}
                            style={styles.inputs}
                          >
                            {industries.map((item) => {
                              return (
                                <option key={item.back} value={item.back}>
                                  {item.front}
                                </option>
                              );
                            })}
                          </select>
                          <div
                            className="flex items-start"
                            style={{
                              marginTop: "0px",
                              marginBottom: "10px",
                              color: Colors.fontGreyLight,
                              padding: "0px 4px",
                            }}
                          >
                            <div style={{ marginTop: "4px" }}>
                              <BsExclamationCircle />
                            </div>
                            <div
                              style={{
                                marginLeft: "8px",
                                fontSize: smfontsize,
                              }}
                            >
                              Please make a selection based on the type of transported items. Delivery providers available to you
                              will be based on this selection. For example, select "Food" if you operate a restaurant or food
                              delivery platform. If you plan to send deliveries across multiple industries or do not see an option
                              that matches your industry, please reach out to customer support. You can change this selection
                              later from account setting.
                            </div>
                          </div>
                          <div style={styles.lables}>Company Registration Number</div>

                          <FormikTextInput
                            disabled
                            name="tax_or_ein"
                            lable="tax_or_ein"
                            placeholder="Tax Id or EIN"
                            marginbottom="20px"
                          />
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: isdesktop ? "60%" : "90%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        disabled={userRole === ROLES.EMPLOEE && !isAdmin}
                        type="submit"
                        style={{
                          padding: "8px 46px",
                          backgroundColor: Colors.bluePrimary,
                          borderRadius: "8px",
                          textAlign: "center",
                          color: "#FFFFFF",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: mdfontsize,
                          opacity: userRole === ROLES.EMPLOEE && !isAdmin && 0.5,
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </section>
                  <section
                    style={{
                      marginTop: "24px",
                      borderRadius: "8px",
                      padding: "16px",
                      height: "100%",
                      width: "100%",
                      paddingBottom: "120px",
                    }}
                    className="shadow-md"
                  >
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontSize: lgfontsize,
                        fontWeight: "500",
                        color: Colors.fontDark,
                        marginBottom: "20px",
                      }}
                    >
                      Referral
                    </div>
                    <div
                      style={{
                        width: "100%",
                        borderBottomWidth: "2px",
                        borderColor: Colors.borderPrimary,
                        marginBottom: "20px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: isdesktop ? "60%" : "90%",
                      }}
                    >
                      <div style={styles.lables}>
                        Share the link below to invite other people to sign up with Burq. This is a unique referral link for your
                        account.
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <FormikTextInput
                          disabled
                          name="tax_or_ein"
                          lable="tax_or_ein"
                          placeholder="Tax Id or EIN"
                          value={referralLink}
                        />
                        <CopyToClipboard copyText={referralLink} />
                      </div>
                    </div>
                  </section>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "90vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading color="#0062FF" className="h-14 w-14 animate-spin" />
      </div>
    );
  }
}

export default ProfileInformation;
