import React, { useRef, useState } from "react";
import Avatar from "react-avatar";
import { useBranding, useMerchant, useTheme, userDataStore } from "../../../../../store";
import { VscTrash } from "react-icons/vsc";

import { Loading, UploadImg } from "../../../../../assets/svgs/Svgs";
import { toast } from "react-toastify";
import classNames from "classnames";

import styles from "./styles.module.css";

function ImageContainer({ type, setImageUrl, imageUrl, backendImage }) {
  const { Colors } = useTheme();
  const { getMerchantData } = useMerchant();
  const { authInfo } = userDataStore();

  const { isUploadLoading, deleteBrandingImage, putBrandingSettingsHandler } = useBranding();

  // const [currentType, setCurrentType] = useState("");

  const photoInputRef = useRef();

  // const photoUploadHandler = async (event) => {
  //   setCurrentType(type);
  //   const params = {
  //     type: type,
  //   };
  //   let formData = new FormData();
  //   formData.append("file", event?.target?.files[0]);

  //   const uploadedData = await postBrandingImageUpload(
  //     authInfo?.merchantAccountId,
  //     params,
  //     formData
  //   );
  //   setCurrentType("");
  // };

  const photoHandler = (event) => {
    const file = event?.target?.files[0];

    let formData = new FormData();

    if (file) {
      if (file.size > 204800) {
        toast.error("Too big file");
        return;
      }
      formData.append("file", event?.target?.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl({ image: reader.result, formData: formData });
      };

      reader.readAsDataURL(file);
    }
  };

  const deleteImage = async () => {
    const data = await deleteBrandingImage(authInfo?.merchantAccountId, {
      file_url: backendImage,
    });
    if (data) {
      const payload = {
        icon_image_url: type === "icon" ? "" : undefined,
        favIcon_image_url: type === "favIcon" ? "" : undefined,
        branding_image_url: type === "branding" ? "" : undefined,
        platform_logo_url: type === "platformLogo" ? "" : undefined,
      };
      const brandingData = await putBrandingSettingsHandler(authInfo?.merchantAccountId, payload);
      if (brandingData) {
        // IMPERSONATION IMPLEMENTATION IS NOT HERE SINCE I AM NOT SURE IF THIS API IS SUPPORTED FOR IMPERSONATION
        getMerchantData(authInfo?.merchantAccountId);
      }
    }
  };

  return (
    <form>
      <div className="flex flex-row items-center ">
        <input
          ref={photoInputRef}
          type="file"
          // onChange={photoUploadHandler}
          onChange={photoHandler}
          style={{ display: "none" }}
        />
        <button
          type="button"
          onClick={async () => {
            if (imageUrl?.image) {
              setImageUrl();
            } else if (backendImage) {
              deleteImage();
            } else {
              photoInputRef.current.click();
            }
          }}
        >
          {isUploadLoading ? (
            <div className="w-full flex justify-center">
              <Loading color="#ECF0F1" className="h-6 w-6 animate-spin" />{" "}
            </div>
          ) : imageUrl?.image || backendImage ? (
            <div className="h-10 w-10 relative ">
              <div className={classNames("absolute -top-2 -right-2 flex items-center justify-center z-50", styles.removeImg)}>
                <button
                  onClick={() => {}}
                  type="button"
                  style={{
                    color: Colors?.fontDark,
                    fontSize: "14px",
                  }}
                  className="material-icons outline-none"
                >
                  close
                </button>
              </div>
              <div className="rounded-xl overflow-hidden h-full w-full">
                <img
                  className="w-full h-full"
                  src={imageUrl?.image ? imageUrl.image : backendImage + `?${Date.now()}`}
                  alt="imag"
                />
              </div>
            </div>
          ) : (
            <UploadImg grey={imageUrl ? Colors.bluePrimary : Colors.fontGreyLight} />
          )}
        </button>
      </div>
    </form>
  );
}

export default ImageContainer;
