import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { removeCookie } from "../../utils";
import { ZendeskAPI } from "react-zendesk";
import { useMerchant, userDataStore, useTheme } from "../../store";
import { FiLogOut } from "react-icons/fi";
import { BurgerSvgRotated, DropDown, SettingsIcon } from "../../assets/svgs/Svgs";
import { baseUrl } from "../../runTimeConst";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { LogOut, CircleDollarSign, CreditCard, ContactRound, UsersRound, UserRoundCog, Bell } from "lucide-react";
import Avatar from "react-avatar";
import { useFlag } from "@unleash/proxy-client-react";
import { canSeeStatementsTab } from "../../utils/helpers";
import { ROLES } from "../AccountSettings/RolesSidebar/helpers";
import styles from "./settingsDropdownMenu.module.css";

const SettingsDropdownMenu = ({ setSidepanelactive, setLogoutVisible, clickRef }) => {
  let history = useNavigate();
  const { Colors } = useTheme();
  const { merchantData, avatar } = useMerchant();
  const { isdesktop } = useDesktopOrLaptop();
  const { userRole, isAdmin } = userDataStore();
  const isUserRolesEnabled = useFlag("userRoles");
  const canSeeStatements = canSeeStatementsTab(merchantData?.capabilities);

  return (
    <div
      ref={clickRef}
      className="myShadow rounded absolute overflow-hidden"
      style={{
        minWidth: "80px",
        zIndex: 110,
        top: isdesktop ? "64px" : "70px",
        right: "22px",
        backgroundColor: Colors.white,
      }}
    >
      <div className={styles.menuHeader}>
        <div className="flex items-center cursor-default" style={{ padding: "6px 8px" }}>
          <div className="rounded-full overflow-hidden" style={{ height: "32px", width: "32px" }}>
            {avatar ? (
              <img style={{ objectFit: "cover", height: "100%", width: "100%" }} src={avatar + "?" + Date.now() ?? ""} alt="" />
            ) : (
              <Avatar
                name={merchantData?.email ? merchantData.email.split("").join(" ") : merchantData?.name}
                size={32}
                maxInitials={2}
                className={styles.avatarFallback}
              />
            )}
          </div>
          <div className={styles.menuHeaderText}>{merchantData?.name ? merchantData.name : merchantData?.email}</div>
        </div>
      </div>
      <div className="font-poppins" style={{ padding: "5px" }}>
        {(userRole === ROLES.ADMIN || isAdmin || !isUserRolesEnabled) && (
          <div
            onClick={() => {
              setSidepanelactive("settings");
              history(`/v1/settings/payments`);
              setLogoutVisible(false);
            }}
            className={styles.menuItem}
          >
            <CircleDollarSign size={"16px"} className={styles.menuItemIcon} />
            <div className={styles.menuItemText}>Payment</div>
          </div>
        )}
        {canSeeStatements?.isEnabled && (userRole === ROLES.ADMIN || !isUserRolesEnabled || isAdmin) && (
          <div
            onClick={() => {
              setSidepanelactive("settings");
              history(`/v1/settings/billing`);
              setLogoutVisible(false);
            }}
            className={styles.menuItem}
          >
            <CreditCard size={"16px"} className={styles.menuItemIcon} />
            <div className={styles.menuItemText}>Billing</div>
          </div>
        )}
        <div
          onClick={() => {
            setSidepanelactive("settings");
            history(`/v1/settings/addressBook`);
            setLogoutVisible(false);
          }}
          className={styles.menuItem}
        >
          <ContactRound size={"16px"} className={styles.menuItemIcon} />
          <div className={styles.menuItemText}>Address Book</div>
        </div>
        {(userRole === ROLES.ADMIN || userRole === ROLES.STORE_MANAGER || isAdmin || !isUserRolesEnabled) && (
          <div
            onClick={() => {
              setSidepanelactive("settings");
              history(`/v1/settings/members`);
              setLogoutVisible(false);
            }}
            className={styles.menuItem}
          >
            <UsersRound size={"16px"} className={styles.menuItemIcon} />
            <div className={styles.menuItemText}>Account Members</div>
          </div>
        )}
        {(userRole === ROLES.ADMIN || isAdmin || !isUserRolesEnabled) && (
          <div
            onClick={() => {
              setSidepanelactive("settings");
              history(`/v1/settings/details`);
              setLogoutVisible(false);
            }}
            className={styles.menuItem}
          >
            <UserRoundCog size={"16px"} className={styles.menuItemIcon} />
            <div className={styles.menuItemText}>Account Details</div>
          </div>
        )}
        <div
          onClick={() => {
            setSidepanelactive("settings");
            window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/settings/notifications`;
            setLogoutVisible(false);
          }}
          className={styles.menuItem}
        >
          <Bell size={"16px"} className={styles.menuItemIcon} />
          <div className={styles.menuItemText}>Notifications</div>
        </div>
      </div>

      <div className={styles.menuFooter}>
        <div
          onClick={() => {
            // window.heap.resetIdentity();
            Object.keys(Cookies.get()).forEach((cookieName) => {
              removeCookie(cookieName);
            });
            ZendeskAPI("messenger", "logoutUser");
            window.location.href = `${baseUrl}auth/logout`;
          }}
          className={styles.menuItem}
        >
          <LogOut size={"16px"} className="mr-2 text-red-500" />
          <div className="px-0 text-red-500 font-medium leading-5 cursor-pointer" style={{ fontSize: "14px" }}>
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsDropdownMenu;
