import React, { useState, useMemo, useEffect, useRef, memo, useCallback } from "react";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { useFeatureFlags, useTheme } from "../../../../../store";
import { LineChart, Line, Tooltip, ResponsiveContainer, Legend, ComposedChart, Area } from "recharts";
import { debounce } from "lodash";
import { getValueComparesion } from "../../../utils";
import CardFooter from "./CardFooter";
import DataComparisonSection from "./DataComparisonSection";
import CardHeader from "./CardHeader";

import classStyles from "./index.module.css";

const initPayload = {
  data: [],
  percentageChangeDirection: "",
  percentageChange: "",
  leftLegend: "",
  rightLegend: "",
  primaryRangeTotal: "",
  secondaryRangeTotal: "",
};

const CustomizedSecondDot = (props) => {
  const { cx, cy } = props;
  const { Colors } = useTheme();
  if (cy) {
    return <circle cx={cx} cy={cy} r={3} stroke={Colors.chartSecondLine} strokeWidth={3} fill={Colors.white} />;
  }
  return <></>;
};

const CustomizedPrimaryDot = (props) => {
  const { cx, cy } = props;
  const { Colors } = useTheme();

  if (cy) {
    return <circle cx={cx} cy={cy} r={3} stroke={Colors.bluePrimary} strokeWidth={3} fill={Colors.white} />;
  }
  return <></>;
};

const CustomizedDot = ({ color, ...props }) => {
  const { cx, cy } = props;

  return <circle cx={cx} cy={cy} r={2} stroke={color} strokeWidth={3} fill={color} />;
};

const InsightChartCard = ({
  title,
  payload,
  primaryLineLable,
  secondaryLineLable,
  valueFormat,
  getChartData,
  isSandbox,
  datePayload,
  compareDatePayload,
  userId,
  merchantAccountId,
  isDateSelected,
  selectedProviderArray,
  isSnowflakeDisabled,
}) => {
  const { mdfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  const [lineChart, setLineChart] = useState(initPayload);
  const [hoveredState, setHoveredState] = useState({
    isHover: false,
    hoverPayload: {},
  });

  const hoverData = useRef({
    isHover: false,
    hoverPayload: {},
  });

  const styles = useMemo(() => {
    return {
      lineChartContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "10px",
        width: "100%",
        height: "100%",
        padding: "12px",
        boxSizing: "border-box",
      },
      customTooltip: {
        display: "flex",
        backgorund: Colors.bgGrey,
      },
      noRecord: { margin: "0px", color: Colors.fontDark, fontSize: mdfontsize },
    };
  }, [Colors, mdfontsize]);

  useEffect(() => {
    if (getChartData) {
      getChartData(isSandbox, datePayload, compareDatePayload, userId ?? merchantAccountId, isSnowflakeDisabled);
    }
  }, [isSandbox, datePayload, compareDatePayload, userId, merchantAccountId, selectedProviderArray]);

  useEffect(() => {
    setLineChart(payload);
  }, [payload]);

  const debounced = useCallback(
    debounce((payload) => {
      setHoveredState(payload);
    }, 200),
    []
  );

  const customTooltip = (props) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const hoveredData = {
        isHover: true,
        hoverPayload: {
          primaryDataKey: "",
          primaryDataValue: 0,
          secondaryDataKey: "",
          secondaryDataValue: 0,
        },
      };
      payload.forEach((coordinate, i) => {
        if (coordinate.name === "primaryDataValue" && coordinate?.payload?.primaryDataKey) {
          hoveredData.hoverPayload.primaryDataKey = coordinate.payload.primaryDataKey;
          hoveredData.hoverPayload.primaryDataValue = coordinate.value;
        }
        if (coordinate.name === "secondaryDataValue" && coordinate.payload.secondaryDataKey) {
          hoveredData.hoverPayload.secondaryDataKey = coordinate.payload.secondaryDataKey;
          hoveredData.hoverPayload.secondaryDataValue = coordinate.value;
        }
      });
      hoveredData.hoverPayload = {
        ...hoveredData.hoverPayload,
        ...getValueComparesion(hoveredData.hoverPayload.primaryDataValue, hoveredData.hoverPayload.secondaryDataValue),
      };
      hoverData.current = hoveredData;
      debounced(hoverData.current);
    } else {
      hoverData.current = { isHover: false, hoverPayload: {} };
      debounced(hoverData.current);
    }

    return null;
  };
  if (lineChart.providersList) {
    return (
      <div style={{ ...styles.lineChartContainer }}>
        <CardHeader title={title} />

        <ResponsiveContainer width={"100%"} height={250}>
          <ComposedChart width={730} height={250} data={lineChart.data}>
            <Tooltip
              labelStyle={{ border: "0px" }}
              contentStyle={{ border: "0px" }}
              wrapperStyle={{ border: "0px" }}
              content={<CustomProvidersTooltip valueFormat={valueFormat} />}
            />

            {lineChart?.providersList?.map((provider) => {
              if (provider.name === "others" || provider.name === "all_providers") {
                return (
                  <>
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={"#00A0DE"} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={"#00A0DE"} stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <Area dataKey={provider.name} fill="#00A0DE" stroke={"#00A0DE"} />;
                    {/* <Area dataKey={provider.name} fill="url(#colorUv)" stroke={"#00A0DE"} />; */}
                  </>
                );
              } else {
                return (
                  <Line
                    activeDot={<CustomizedDot color={provider.color} />}
                    dataKey={provider.name}
                    stroke={provider.color}
                    dot={<></>}
                    strokeWidth={2}
                  />
                );
              }
            })}
          </ComposedChart>
          {/* <LineChart
            margin={{
              top: 30,
              right: 10,
              left: 10,
              bottom: 10,
            }}
            data={lineChart.data}
          >
            <Tooltip
              labelStyle={{ border: "0px" }}
              contentStyle={{ border: "0px" }}
              wrapperStyle={{ border: "0px" }}
              content={<CustomProvidersTooltip valueFormat={valueFormat} />}
            />
            {lineChart?.providersList?.map((provider) => {
              return (
                <Line
                  activeDot={<CustomizedDot color={provider.color} />}
                  dataKey={provider.name}
                  stroke={provider.color}
                  dot={<></>}
                  strokeWidth={2}
                />
              );
            })}
          </LineChart> */}
        </ResponsiveContainer>
        <CardFooter isProvidersSelected data={lineChart} />
      </div>
    );
  } else {
    return (
      <div style={{ ...styles.lineChartContainer }}>
        <CardHeader title={title} lineChart={lineChart} hoveredState={hoveredState} />
        <DataComparisonSection lineChart={lineChart} hoveredState={hoveredState} valueFormat={valueFormat} />

        {lineChart?.data?.length ? (
          <ResponsiveContainer width={"100%"} height={200}>
            <LineChart
              data={lineChart.data}
              margin={{
                top: 30,
                right: 10,
                left: 10,
                bottom: 10,
              }}
            >
              <Tooltip active={false} cursor={false} content={customTooltip} />
              <Line
                dataKey={secondaryLineLable}
                stroke={Colors.chartSecondLine}
                activeDot={<CustomizedSecondDot />}
                strokeWidth={2}
                dot={lineChart?.showSecondaryLineDot}
              />
              <Line
                dataKey={primaryLineLable}
                stroke={Colors.bluePrimary}
                activeDot={<CustomizedPrimaryDot />}
                strokeWidth={2}
                dot={lineChart?.showPrimaryLineDot}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div
            style={{
              height: "240px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={styles.noRecord}>No record found</p>
          </div>
        )}
        <CardFooter data={lineChart} />
      </div>
    );
  }
};

export default memo(InsightChartCard);

const CustomProvidersTooltip = ({ active, payload, label, valueFormat }) => {
  const allData = payload?.find((provider) => provider.name === "all_providers" || provider.name === "others");

  if (active && payload && payload.length) {
    return (
      <div className={classStyles.providersTooltipContainer}>
        {allData && (
          <div className={classStyles.oneProviderOnTooltip}>
            <div className={classStyles.nameCont}>
              <div
                style={{
                  backgroundColor: allData.name === "others" || allData.name === "all_providers" ? "#00A0DE" : allData.color,
                }}
                className={classStyles.square}
              ></div>
              <div className={classStyles.nameOnTooltip}>
                {allData.name === "others" || allData.name === "all_providers" ? "All" : allData.name}
              </div>
            </div>
            <div className={classStyles.value}>
              {allData.value}
              {valueFormat === "min" ? "min" : ""}
            </div>
          </div>
        )}
        {payload.map((item, index) => {
          if (item?.name !== "others" && item?.name !== "all_providers")
            return (
              <div className={classStyles.oneProviderOnTooltip}>
                <div className={classStyles.nameCont}>
                  <div
                    style={{ backgroundColor: item.name === "others" || item.name === "all_providers" ? "#00A0DE" : item.color }}
                    className={classStyles.square}
                  ></div>
                  <div className={classStyles.nameOnTooltip}>
                    {item.name === "others" || item.name === "all_providers" ? "All" : item.name}
                  </div>
                </div>
                <div className={classStyles.value}>
                  {item.value}
                  {valueFormat === "min" ? "min" : ""}
                </div>
              </div>
            );
          return <></>;
        })}
      </div>
    );
  }

  return null;
};
