export const initialValues = {
  name: "",
  email: "",
  phone_number: "",
};

export const formatPhoneNumber = (phoneNumber) => {
  const phoneString = phoneNumber.toString();

  const countryCode = phoneString.slice(0, 1);
  const areaCode = phoneString.slice(1, 4);
  const centralOfficeCode = phoneString.slice(4, 7);
  const stationCode = phoneString.slice(7);

  return `+${countryCode} (${areaCode}) ${centralOfficeCode}-${stationCode}`;
};
