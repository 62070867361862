import { useEffect, useState, useRef } from "react";
import { pusher } from "../config/pusher";

function useSocket({ channelName = null, notificationsEvent = null }) {
  const [message, setMessage] = useState(null);
  const [notificationsData, setNotificationsData] = useState(null);

  const channelRef = useRef(null);

  useEffect(() => {
    if (channelName) {
      if (channelRef.current) {
        channelRef.current.unbind_all();
        pusher.unsubscribe(channelRef.current.name);
      }

      const channel = pusher.subscribe(`presence-${channelName}`);
      channelRef.current = channel;

      channel.bind("pusher:subscription_succeeded", () => {
        console.log(`successfully subscribed to the channel ${channelName}`);
      });

      channel.bind("pusher:subscription_error", (error) => {
        console.log(`Failed subscription to channel`, error);
      });

      channel.bind("delivery_update", (data) => {
        setMessage(data);
      });
      if (notificationsEvent) {
        channel.bind(notificationsEvent, (data) => {
          setNotificationsData(data);
        });
      }
      return () => {
        if (channelRef.current) {
          console.log(`successfully unsubscribed to the channel ${channelName}`);
          channelRef.current.unbind_all();
          pusher.unsubscribe(channelRef.current.name);
          channelRef.current = null;
        }
      };
    }
  }, [channelName, notificationsEvent]);

  return { message, notificationsData };
}

export default useSocket;
