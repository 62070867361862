import classNames from "classnames";
import React from "react";
import styles from "../styles.module.css";
import {
  LiaUserAltSolid,
  LiaUserCogSolid,
  LiaUserSolid,
  LiaUserTieSolid,
} from "react-icons/lia";
import { useMerchant, useTheme, userDataStore } from "../../../../store";
import { ROLES } from "../helpers";

const RolesCards = ({ role, desc, roleBE, selectedRole, setFieldValue }) => {
  const { Colors } = useTheme();
  const { authInfo, userRole, isAdmin } = userDataStore();


  const selected = selectedRole === roleBE;

  const isAdminDisabled =
    userRole !== ROLES.ADMIN && roleBE === ROLES.ADMIN && !isAdmin;
  const isStoresDisabled =
    userRole !== ROLES.ADMIN &&
    (roleBE === ROLES.STORE_MANAGER || roleBE === ROLES.EMPLOEE) &&
    !isAdmin;

  return (
    <button
      disabled={isAdminDisabled || isStoresDisabled}
      type="button"
      onClick={() => {
        setFieldValue("role", roleBE);
        if (roleBE === ROLES.ADMIN) {
          const adminRole = [];
          adminRole.push({
            role_name: "acct_admin",
            resource_id: authInfo.merchantAccountId,
          });
          setFieldValue("user_roles", adminRole);
        } else if (roleBE === ROLES.EMPLOEE) {
          const emploee = [];
          emploee.push({
            role_name: "delivery_operator",
            resource_id: authInfo.merchantAccountId,
          });
          setFieldValue("user_roles", emploee);
        } else if (roleBE === ROLES.STORE_MANAGER) {
          setFieldValue("user_roles", []);
        } else if (roleBE === ROLES.STORE_EMPLOEE) {
          setFieldValue("user_roles", []);
        }
      }}
      className={classNames(
        "w-full border flex flex-col p-3 rounded-xl cursor-pointer text-left",
        styles.card,
        {
          [styles.cardActive]: selected,
          [styles.disabled]: isAdminDisabled || isStoresDisabled,
        }
      )}
    >
      <div
        style={{ color: selected && "#0062FF" }}
        className={styles.cardTitle}
      >
        {roleBE === ROLES.ADMIN ? (
          <LiaUserCogSolid
            size={27}
            color={selected ? Colors.bluePrimary : Colors.fontDark}
          />
        ) : roleBE === ROLES.STORE_MANAGER ? (
          <LiaUserTieSolid
            size={27}
            color={selected ? Colors.bluePrimary : Colors.fontDark}
          />
        ) : roleBE === ROLES.STORE_EMPLOEE ? (
          <LiaUserAltSolid
            size={27}
            color={selected ? Colors.bluePrimary : Colors.fontDark}
          />
        ) : (
          <LiaUserSolid
            size={27}
            color={selected ? Colors.bluePrimary : Colors.fontDark}
          />
        )}

        <span className="ml-3">{role}</span>
      </div>
      <div className={styles.cardText}>{desc}</div>
    </button>
  );
};

export default RolesCards;
