import React, { useCallback, useMemo, useRef, useState } from "react";
import { RiArrowDropDownLine, RiQuestionLine } from "react-icons/ri";
import PlacesAutocomplete from "react-places-autocomplete"; // getLatLng, // geocodeByAddress,
import { DropoffSvg, PickupSvg } from "../../assets/svgs/Svgs";
import { useClickStore, useMerchant, useMyStore, useStoreAndChild, useTheme, userDataStore } from "../../store";
import pickupBig from "../../assets/png/pickupBig.png";
import dropoffBig from "../../assets/png/dropoffBig.png";
import FormikNewTextInput from "./FormikNewTextInput";
import CustomFormikPhoneNumber from "../CustomFormikPhoneNumber";
import { useFormikContext } from "formik";
import ManifestItemsFormik from "./ManifestItemsFormik";
import { VscTrash } from "react-icons/vsc";

import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import DeliveryPreferences from "../DeliveryPreferences/DeliveryPreferences";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import ButtonComp from "../ButtonComp/ButtonComp";
import classNames from "classnames";
import { ROLES } from "../AccountSettings/RolesSidebar/helpers";
import ToolTip from "../Tooltip/Tooltip";

const CreateFieldsFormik = ({
  arr,
  index,
  coordinatesHandler,
  setSaveAddress,
  saveAddress,
  isClickedOnContinue,
  isOrder,
  setIsStoresVisible,
  isStoresVisible,
  isReroute,
}) => {
  const { Colors, themeChanger } = useTheme();
  const { userRole, isAdmin } = userDataStore();

  const { selectedStore } = useStoreAndChild();

  const [isAddressError, setIsAddressError] = useState(false);

  const { isdesktop, smfontsize, mdfontsize, lgfontsize } = useDesktopOrLaptop();

  const { setFieldValue, values, errors } = useFormikContext();
  const { getAddressesData, deleteSavedAddress } = useMyStore();
  const { useOnClickOutside } = useClickStore();

  const [isFieldsVisible, setIsFieldsVisible] = useState(
    arr === "pickup" ? (values.pickup[index].pickup_address ? true : false) : values.dropoff[index].dropoff_address ? true : false
  );
  const [addressesVisible, setAddressesVisible] = useState(false);

  const clickRef = useRef();
  const handler = useCallback(() => {
    setAddressesVisible(false);
  }, []);

  useOnClickOutside(clickRef, handler);

  const styles = useMemo(() => {
    return {
      svgBg: {
        height: "45px",
        width: "45px",
        backgroundColor: Colors.bgListHeader,
        display: "flex",
        borderRadius: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
        marginLeft: "-3px",
      },
      inputs: {
        fontFamily: "Poppins",
        borderRadius: "8px",
        fontSize: smfontsize,
        fontWeight: "400",
        color: Colors.fontDark,
        backgroundColor: Colors.buttonBg,
        borderRight: "20px solid transparent",
      },
      helperText: {
        fontSize: smfontsize,
        color: Colors.fontGreyLight,
        display: "flex",
        alignItems: "center",
        marginLeft: "5px",
      },
    };
  }, [Colors, isdesktop]);

  const [pickupError, setPickupError] = useState();
  const [dropoffError, setDropoffError] = useState();
  return (
    <div
      style={{
        width: "100%",
        borderRadius: "8px",
        backgroundColor: Colors.bgDarkSecondary,
        padding: "20px 20px",
        marginBottom: "0px",
      }}
    >
      {!isStoresVisible && (
        <>
          <div ref={clickRef} className="flex w-full justify-between  relative items-center">
            <div
              style={{
                fontSize: mdfontsize,
                textTransform: "capitalize",
                color: Colors.fontDark,
              }}
              className="font-semibold  text-base flex flex-row items-center"
            >
              <div style={styles.svgBg}>{arr === "pickup" ? <PickupSvg /> : <DropoffSvg />}</div>
              {/* {arr + " " + (index + 1)} */}
              {arr === "pickup" ? arr : arr + " " + (index + 1)}
            </div>
            {isReroute || (arr === "pickup" && selectedStore) ? null : (
              <button
                style={{ fontSize: mdfontsize, color: Colors.fontDark }}
                onClick={() => {
                  setAddressesVisible(!addressesVisible);
                }}
                type="button"
                className="flex font-semibold text-base focus:outline-none  justify-center items-center"
              >
                Select Address
                <RiArrowDropDownLine size={isdesktop ? 25 : 30} />
              </button>
            )}
            {addressesVisible && (
              <div
                style={{ backgroundColor: Colors.bgDarkSecondary }}
                className=" border-blue-500 rounded-lg absolute right-0 h-40 -bottom-40 kata  overflow-y-scroll flex-col py-2.5 shadow-lg z-20"
              >
                {getAddressesData?.map((item) => {
                  return (
                    <div
                      style={{
                        backgroundColor: Colors.bgDarkSecondary,
                        borderBottomWidth: "1px",
                        borderColor: Colors.borderPrimary,
                      }}
                      key={item.id}
                      className="flex pl-4 pr-2  py-1 "
                    >
                      <button
                        onClick={() => {
                          if (arr === "pickup") {
                            setIsFieldsVisible(true);
                            let _pickup = { ...values.pickup[0] };
                            _pickup.pickup_address = item.address;
                            _pickup.pickup_businessName = item.businessName;
                            _pickup.pickup_phone_number = item.phoneNumber;
                            _pickup.pickup_notes = item.notes ? item.notes : " ";
                            _pickup.pickup_name = item.firstName + " " + item?.lastName || "";
                            setFieldValue(`${arr}[0]`, _pickup);
                            setAddressesVisible(false);
                          } else if (arr === "dropoff") {
                            setIsFieldsVisible(true);
                            let _dropoff = { ...values.dropoff[index] };
                            _dropoff.dropoff_address = item.address;
                            _dropoff.dropoff_businessName = item.businessName;
                            _dropoff.dropoff_phone_number = item.phoneNumber;
                            _dropoff.dropoff_notes = item.notes ? item.notes : " ";
                            _dropoff.dropoff_name = item.firstName || "";
                            _dropoff.dropoff_last_name = item.lastName || "";
                            setFieldValue(`${arr}[${index}]`, _dropoff);
                            setAddressesVisible(false);
                          }
                          coordinatesHandler(item.address);
                        }}
                        type="button"
                        style={{ width: "200px" }}
                        className=" flex px-2 py-1 focus:outline-none hover:shadow-lg"
                      >
                        <p
                          className="elipsis"
                          style={{
                            fontSize: smfontsize,
                            color: Colors.fontDark,
                          }}
                          // className="mr-2"
                        >
                          {item.firstName + " " + item.lastName}
                        </p>
                        {/* <div
                      style={{ fontSize: smfontsize, color: Colors.fontDark }}
                    >
                      {" "}
                      {item.lastName}
                    </div> */}
                      </button>
                      <button
                        onClick={() => {
                          deleteSavedAddress(item.id);
                          setAddressesVisible(false);
                        }}
                        type="button"
                        className="ml-4 focus:outline-none"
                      >
                        <VscTrash size={20} color={Colors.fontDark} />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div
            style={{
              marginTop: "16px",
              position: "relative",
            }}
            className="flex flex-col w-full justify-between"
          >
            <PlacesAutocomplete
              value={arr === "pickup" ? values.pickup[index].pickup_address : values.dropoff[index].dropoff_address}
              onChange={(e) => {
                setFieldValue(`${arr}[${index}].${arr}_address`, e);
                setIsFieldsVisible(true);
                if (isAddressError) {
                  setIsAddressError(false);
                }
              }}
              onSelect={(e) => {
                setFieldValue(`${arr}[${index}].${arr}_address`, e);
                coordinatesHandler(e);
              }}
              onError={(status, clearSuggestions) => {
                console.log("Google Maps API returned error with status: ", status);
                setIsAddressError(true);
                clearSuggestions();
              }}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                if (errors.pickup) {
                  setPickupError(errors?.pickup[index].pickup_address ?? undefined);
                } else {
                  setPickupError();
                }
                if (errors.dropoff) {
                  setDropoffError(errors?.dropoff[index].dropoff_address ?? undefined);
                } else {
                  setDropoffError();
                }

                return (
                  <div style={{ width: "100%", padding: "0px 0px" }}>
                    <FormikNewTextInput
                      name={`${arr}[${index}].${arr}_address`}
                      {...getInputProps({
                        placeholder: arr === "pickup" ? "Search for pickup address *" : "Search for drop-off address *",
                        disabled: isReroute ? true : false,
                      })}
                      // className={` py-3 px-5 w-full `}
                      type="text"
                    />
                    <div className="w-full">
                      {loading ? (
                        <div
                          style={{
                            color: Colors.fontDark,
                            position: "absolute",
                            top: "55px",
                          }}
                        >
                          ...loading
                        </div>
                      ) : null}
                      <div
                        style={{
                          height: "10px",
                          width: "100%",
                        }}
                      >
                        {isClickedOnContinue &&
                          (arr === "pickup"
                            ? pickupError && (
                                <p
                                  style={{
                                    color: Colors.redDark,
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    padding: "0px 10px 0px 12px",
                                  }}
                                >
                                  {pickupError}
                                </p>
                              )
                            : arr === "dropoff" &&
                              dropoffError && (
                                <p
                                  style={{
                                    color: Colors.redDark,
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    padding: "0px 10px 0px 12px",
                                  }}
                                >
                                  {dropoffError}
                                </p>
                              ))}
                      </div>

                      {suggestions.length > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: Colors.bgDarkSecondary,
                            width: "82%",
                            padding: "0px 10px 10px 10px",
                            zIndex: 999,
                          }}
                        >
                          {suggestions.map((sug, index) => {
                            const className = sug.active ? "bg-blue-400 text-blue-50" : "";
                            return (
                              <div
                                style={{
                                  fontSize: mdfontsize,
                                  color: Colors.fontDark,
                                  borderBottom: "1px solid lightgrey",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                id={index}
                                key={sug.index}
                                {...getSuggestionItemProps(sug, {
                                  className,
                                })}
                              >
                                {sug.description}
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {!suggestions && isAddressError && (
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: Colors.bgDarkSecondary,
                            width: "82%",
                            padding: "0px 10px 10px 10px",
                            zIndex: 999,
                          }}
                        >
                          <div
                            style={{
                              fontSize: mdfontsize,
                              color: Colors.fontDark,
                              borderBottom: "1px solid lightgrey",
                              display: "flex",
                              alignItems: "center",
                            }}
                            id={index}
                          >
                            No matching address found
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            </PlacesAutocomplete>
          </div>
        </>
      )}
      {isFieldsVisible ? (
        <>
          <div style={{ marginTop: "20px" }} className="flex flex-row w-full justify-between">
            <div style={{ width: "48%" }}>
              <FormikNewTextInput
                isClickedOnContinue={isClickedOnContinue}
                value={arr === "pickup" ? values.pickup[index].pickup_unit : values.dropoff[index].dropoff_unit}
                name={`${arr}[${index}].${arr}_unit`}
                placeholder="Suite/Apt. #"
                marginbottom="0px"
                disabled={isReroute}
              />
            </div>
            <div style={{ width: "48%" }}>
              <div style={{ height: isdesktop ? "60px" : "80px" }}>
                <CustomFormikPhoneNumber
                  disabled={isReroute}
                  isClickedOnContinue={isClickedOnContinue}
                  name={`${arr}[${index}].${arr}_phone_number`}
                  style={{
                    ...styles.inputs,
                    ...{
                      padding: "10px 40px",
                      width: "100%",
                      fontSize: smfontsize,
                      backgroundColor: Colors.borderPrimary,
                    },
                  }}
                  placeholder="+1 (###) ###-####"
                  type="tel"
                  format="+1 (###) ###-####"
                  onValueChange={(e) => {
                    setFieldValue(`${arr}[${index}].pickup_phone_number`, e.formattedValue);
                  }}
                  value={arr === "pickup" ? values.pickup[index].pickup_phone_number : values.dropoff[index].dropoff_phone_number}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="flex flex-row w-full justify-between">
            {isOrder ? (
              <>
                {" "}
                <div style={{ width: "48%" }}>
                  <FormikNewTextInput
                    isClickedOnContinue={isClickedOnContinue}
                    value={arr === "pickup" ? values.pickup[index].pickup_name : values.dropoff[index].dropoff_name}
                    name={`${arr}[${index}].${arr}_name`}
                    placeholder="Name *"
                    maxLength={36}
                    onBlur={() => {
                      setFieldValue(
                        `${arr}[${index}].${arr}_name`,
                        arr === "pickup" ? values.pickup[index].pickup_name.trim() : values.dropoff[index].dropoff_name.trim()
                      );
                    }}
                    marginbottom={"10px"}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <FormikNewTextInput
                    isClickedOnContinue={isClickedOnContinue}
                    value={arr === "pickup" ? values.pickup[index].pickup_last_name : values.dropoff[index].dropoff_last_name}
                    name={`${arr}[${index}].${arr}_last_name`}
                    placeholder="Last Name *"
                    marginbottom="0px"
                    maxLength={36}
                    onBlur={() => {
                      setFieldValue(
                        `${arr}[${index}].${arr}_last_name`,
                        arr === "pickup"
                          ? values.pickup[index].pickup_last_name.trim()
                          : values.dropoff[index].dropoff_last_name.trim()
                      );
                    }}
                  />
                </div>
              </>
            ) : (
              <div style={{ width: "100%" }}>
                <FormikNewTextInput
                  disabled={isReroute}
                  isClickedOnContinue={isClickedOnContinue}
                  value={arr === "pickup" ? values.pickup[index].pickup_name : values.dropoff[index].dropoff_name}
                  name={`${arr}[${index}].${arr}_name`}
                  placeholder="Name *"
                  maxLength={36}
                  onBlur={() => {
                    setFieldValue(
                      `${arr}[${index}].${arr}_name`,
                      arr === "pickup" ? values.pickup[index].pickup_name.trim() : values.dropoff[index].dropoff_name.trim()
                    );
                  }}
                  marginbottom={"10px"}
                />
              </div>
            )}
          </div>
          <div style={{ marginTop: "20px" }} className="flex flex-col w-full justify-between mt-2">
            <textarea
              disabled={isReroute}
              maxLength={"200"}
              className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}  border border-transparent`}
              value={arr === "pickup" ? values.pickup[index].pickup_notes : values.dropoff[index].dropoff_notes}
              style={{
                ...styles.inputs,
                ...{
                  padding: "10px 20px",
                  width: "100%",
                  outline: "none",
                  backgroundColor: Colors.borderPrimary,
                  resize: "none",
                  maxHeight: "300px",
                },
              }}
              onChange={(e) => {
                setFieldValue(`${arr}[${index}].${arr}_notes`, e.target.value);
              }}
              rows="3"
              placeholder={arr === "pickup" ? "Add pickup instructions" : "Add drop-off instructions"}
            ></textarea>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "450px",
            paddingTop: "24px",
            paddingBottom: "24px",
          }}
          className="w-full flex flex-col justify-center items-center"
        >
          {arr === "pickup" ? (
            <>
              {(userRole === ROLES.ADMIN || isAdmin) && (
                <div className="w-full flex flex-col mb-3 justify-center items-center ">
                  <div
                    style={{
                      color: Colors.fontDark,
                      fontSize: lgfontsize,
                      margin: "10px 0px 10px 0px",
                    }}
                  >
                    Or
                  </div>
                  <ButtonComp
                    title={isStoresVisible ? "Select address manually" : "Choose the store"}
                    primary
                    onClick={() => {
                      if (isStoresVisible) {
                        setIsStoresVisible(false);
                      } else {
                        setIsStoresVisible(true);
                      }
                    }}
                  />
                </div>
              )}
              <img style={{ height: "calc(100% - 30px)" }} src={pickupBig} alt="pickup png" />
            </>
          ) : (
            <img className="h-full " src={dropoffBig} alt="pickup png" />
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "20px 0px 0px 0px",
          justifyContent: "flex-end",
        }}
      >
        {arr === "pickup" && !isReroute && (
          <div>
            <input
              // id={pickup ? "pickSaveAddressId" : "dropoffSaveAddressId"}
              type="checkbox"
              onChange={(event) => {
                setSaveAddress(event.target.checked);
              }}
              checked={saveAddress}
            />

            <span
              style={{
                fontSize: mdfontsize,
                fontFamily: "Poppins",
                color: Colors.fontGreyLight,
              }}
              className="ml-2 capitalize "
            >
              Save Pickup Address
            </span>
          </div>
        )}
        {/* {arr === "pickup" && index === values.pickup.length - 1 && (
          <button
            onClick={() => {
              const _address = values.pickup;
              _address.push(pickupTemplate);
              setFieldValue(`pickup`, _address);
            }}
            type="button"
            style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "600",
              color: Colors.fontDark,
              outline: "none",
            }}
          >
            + Add Address
          </button>
        )} */}
        {/* {arr === "dropoff" && index === values.dropoff.length - 1 && (
          <button
            onClick={() => {
              const _address = values.dropoff;
              _address.push(dropoffTemplate);
              setFieldValue(`dropoff`, _address);
            }}
            type="button"
            style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "600",
              color: Colors.fontDark,
              outline: "none",
            }}
          >
            + Add Address
          </button>
        )} */}
      </div>
      {arr === "dropoff" && isFieldsVisible && (
        <ManifestItemsFormik
          isClickedOnContinue={isClickedOnContinue}
          index={index}
          styles={styles}
          isOrder={isOrder}
          isReroute={isReroute}
        />
      )}

      {arr === "dropoff" && isFieldsVisible && (
        <div
          style={{
            marginTop: "39px",
          }}
        >
          {!isOrder && (
            <div className="flex gap-9">
              <div className=" w-full">
                <div
                  style={{
                    marginRight: "10px",
                    fontWeight: "600",
                    marginBottom: "10px",
                    color: Colors.fontDark,
                    fontSize: mdfontsize,
                  }}
                >
                  Order Value:{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    disabled={isReroute}
                    placeholder="Order value"
                    type="number"
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^.\d]/g, "");
                      setFieldValue("order_value", value);
                    }}
                    min={0}
                    minLength={0}
                    value={values.order_value}
                    style={{
                      padding: "10px 0px 10px 20px",
                      borderRadius: "8px",
                      outline: "none",
                      fontSize: smfontsize,
                      backgroundColor: Colors.borderPrimary,
                      borderWidth: "1px",
                      borderColor: Colors.borderPrimary,
                      color: Colors.fontDark,
                      width: "100%",
                    }}
                  />
                </div>
                {errors.order_value && isClickedOnContinue && (
                  <div
                    style={{
                      color: Colors.redDark,
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      padding: "6px 10px 0px 12px",
                      marginLeft: "6px",
                    }}
                  >
                    {errors.order_value}
                  </div>
                )}
              </div>
              <div className=" w-full">
                <div className=" flex w-full mb-3 ">
                  <div
                    style={{
                      marginRight: "10px",
                      fontWeight: "600",
                      // marginBottom: "10px",
                      color: Colors.fontDark,
                      fontSize: mdfontsize,
                    }}
                  >
                    Order Number:
                  </div>
                  <div style={styles.helperText}>
                    <div data-tip data-for={`orderNumberInfo`} className="cursor-pointer relative normal-case">
                      <RiQuestionLine color={Colors.fontGreyLight} size={18} />

                      <ToolTip
                        anchorSelect="#clickable"
                        clickable
                        id={`orderNumberInfo`}
                        disable={false}
                        text="This is the order number that will be shown to your driver."
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    disabled={isReroute}
                    placeholder="Order Number"
                    type="text"
                    onChange={(e) => {
                      // const value = e.target.value.replace(/[^.\d]/g, "");
                      setFieldValue("external_order_ref", e.target.value);
                    }}
                    value={values.external_order_ref}
                    style={{
                      padding: "10px 0px 10px 13px",
                      borderRadius: "8px",
                      outline: "none",
                      fontSize: smfontsize,
                      backgroundColor: Colors.borderPrimary,
                      borderWidth: "1px",
                      borderColor: Colors.borderPrimary,
                      color: Colors.fontDark,
                      width: "100%",
                    }}
                  />
                </div>
                {errors.external_order_ref && isClickedOnContinue && (
                  <div
                    style={{
                      color: Colors.redDark,
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      padding: "6px 10px 0px 12px",
                      marginLeft: "6px",
                    }}
                  >
                    {errors.external_order_ref}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CreateFieldsFormik;
