import React, { useEffect, useRef, useState } from "react";
import { Loading } from "../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useConnectedAccounts, userDataStore, useTheme } from "../../store";
import ConnectedAccountDetails from "./ConnectedAccountDetails";
import ConnectedAcountListItem from "./ConnectedAcountListItem";
import ButtonComp from "../../components/ButtonComp/ButtonComp";
import styles from "./styles/styles.module.css";
import CreateAccountModal from "./CreateAccountModal";
import CustomModal from "../CustomModal/CustomModal";
import DeleteAccountModal from "./DeleteAccountModal";
import FiltersMenu from "../shared/common/FiltersMenu";
import { FILTER_TYPES } from "../../constants";
import ActiveFiltersBar from "../shared/common/FiltersMenu/ActiveFiltersBar";
import { accountStatusListOptions } from "./utils";
import Pagination from "../shared/Pagination";
import MissingPaymentMethodAlert from "../shared/common/MissingPaymentMethodAlert";

const ConnectedAccounts = () => {
  const { Colors } = useTheme();
  const { authInfo } = userDataStore();

  const {
    getConnectedAccounts,
    connectedAccountsList,
    isConnectedAccountsLoading,
    setIsCreateAccountModalOpen,
    isDeleteAccountModalVisible,
    setIsDeleteAccountModalVisible,
    setAccountForEditing,
  } = useConnectedAccounts();

  const { isdesktop, lgfontsize, mdfontsize } = useDesktopOrLaptop();

  const [accountNameQuery, setAccountNameQuery] = useState("");
  const [merchantStoreIdQuery, setMerchantStoreIdQuery] = useState("");
  const [accountEmailQuery, setAccountEmailQuery] = useState("");
  const [accountStatusQuery, setAccountStatusQuery] = useState(null);

  const [firstStoreInsideAccount, setFirstStoreInsideAccount] = useState("");

  const [detailsHeight, setDetailsHeight] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);

  const scrollRef = useRef(null);

  const scrollHandler = (top) => {
    scrollRef.current.scrollTo({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  };

  const filtersDefinition = [
    {
      id: "search_by_store_name",
      title: "Account Name",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Account Name",
      filterState: accountNameQuery,
      setFilterState: setAccountNameQuery,
    },
    {
      id: "search_by_merchant_store_id",
      title: "Merchant Store ID",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Merchant Store Id",
      filterState: merchantStoreIdQuery,
      setFilterState: setMerchantStoreIdQuery,
    },
    {
      id: "search_by_account_email",
      title: "Account Email",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Account Email",
      filterState: accountEmailQuery,
      setFilterState: setAccountEmailQuery,
    },
    {
      id: "filter_by_account_status",
      title: "Account Status",
      type: FILTER_TYPES.RADIO,
      label: "label",
      placeholder: "Account Status",
      list: accountStatusListOptions,
      filterState: accountStatusQuery,
      setFilterState: setAccountStatusQuery,
    },
  ];

  const searchHandler = (params) => {
    setActiveIndex();
    const payload = {
      ending_before: params?.ending_before || "",
      starting_after: params?.starting_after || "",
      account_email: encodeURIComponent(accountEmailQuery),
      merchant_store_id: encodeURIComponent(merchantStoreIdQuery),
      name: encodeURIComponent(accountNameQuery),
      status: accountStatusQuery?.value,
    };
    getConnectedAccounts(authInfo.merchantAccountId, payload);
  };

  useEffect(() => {
    searchHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authInfo,
    accountNameQuery,
    merchantStoreIdQuery,
    accountEmailQuery,
    accountStatusQuery,
  ]);

  useEffect(() => {
    scrollHandler(0);

    return () => {};
  }, [connectedAccountsList]);

  const createAccountHandler = () => {
    setIsCreateAccountModalOpen(true);
  };

  return (
    <div
      style={{
        padding: "20px",
        paddingBottom: "170px",
        backgroundColor: Colors.white,
        height: isdesktop ? `calc(100vh - 70px)` : `calc(100vh - 234px)`,
      }}
    >
      <div
        style={{
          fontSize: lgfontsize,
          fontWeight: "500",
          color: Colors.fontGreyLight,
        }}
      >
        Connected Accounts
      </div>
      <MissingPaymentMethodAlert />
      <div className={styles.header}>
        <div style={{ minWidth: "130px" }}>
          <FiltersMenu menuItems={filtersDefinition} title="Filter By" />
        </div>
        <div style={{ width: "100%" }}>
          <ActiveFiltersBar activeFilters={[...filtersDefinition]} />
        </div>
        <div style={{ width: "250px" }}>
          <a
            style={{
              whiteSpace: "nowrap",
              color: Colors.fontGreyLight,
              fontWeight: "500",
              fontSize: mdfontsize,
            }}
            href={`${process.env.REACT_APP_STRIPE_RETURN_URL}/onboarding/platform_accounts/${authInfo?.merchantAccountId}`}
            target="_blank"
            rel="noreferrer"
          >
            Onboarding Link
          </a>
        </div>
        <div style={{ width: "250px" }}>
          <ButtonComp
            extraStyles={{ whiteSpace: "nowrap" }}
            title={"Create Account"}
            onClick={() => createAccountHandler()}
            primary
          />
        </div>
      </div>
      <div
        ref={scrollRef}
        style={{
          height: "100%",
          borderRadius: "8px",
          overflowY: "scroll",
        }}
      >
        {isConnectedAccountsLoading && (
          <div
            style={{ minHeight: "80px" }}
            className="w-full  flex items-center justify-center kata"
          >
            <Loading
              color={Colors.bluePrimary}
              className="h-14 w-14 animate-spin"
            />
          </div>
        )}
        {connectedAccountsList?.data?.length > 0 &&
          connectedAccountsList.data.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  width: "100%",
                  backgroundColor: Colors.white,
                  marginTop: activeIndex === index ? "20px" : "0px",
                  marginBottom: activeIndex === index ? "20px" : "0px",
                  padding: "0px 20px",
                  borderRadius:
                    activeIndex === index
                      ? "8px"
                      : activeIndex === index - 1
                      ? "8px 8px 0px 0px"
                      : activeIndex === index + 1
                      ? "0px 0px 8px 8px"
                      : "0px",
                  overflow: "hidden",
                  borderColor: "rgba(0, 0, 0, 0.07)",
                  borderBottomWidth: activeIndex === index + 1 ? "1px" : "0px",
                  boxShadow:
                    activeIndex === index + 1 &&
                    "0px 2px 2px rgba(0, 0, 0, 0.07)",
                  borderTopWidth: activeIndex === index && "1px",
                }}
              >
                <ConnectedAcountListItem
                  item={item}
                  scrollHandler={scrollHandler}
                  activeIndex={activeIndex}
                  index={index}
                  detailsHeight={detailsHeight}
                  setActiveIndex={setActiveIndex}
                  firstStoreInsideAccount={firstStoreInsideAccount}
                  setFirstStoreInsideAccount={setFirstStoreInsideAccount}
                />
                {index === activeIndex && (
                  <ConnectedAccountDetails
                    firstStoreInsideAccount={firstStoreInsideAccount}
                    setDetailsHeight={setDetailsHeight}
                    activeIndex={activeIndex}
                    index={index}
                    item={item}
                  />
                )}
              </div>
            );
          })}
      </div>
      <div
        className="flex items-center justify-end pr-20"
        style={{
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          display: !connectedAccountsList?.data?.length ? "none" : "flex",
        }}
      >
        <Pagination
          data={connectedAccountsList}
          onGetPaginationData={searchHandler}
          dependencies={[
            accountNameQuery,
            merchantStoreIdQuery,
            accountEmailQuery,
            accountStatusQuery,
          ]}
        />
      </div>
      <CreateAccountModal />

      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={isDeleteAccountModalVisible}
        onClose={() => {
          setIsDeleteAccountModalVisible(false);
          setAccountForEditing();
        }}
      >
        <DeleteAccountModal onDeleteCallback={() => searchHandler()} />
      </CustomModal>
    </div>
  );
};

export default ConnectedAccounts;
