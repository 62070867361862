import React, { Fragment, forwardRef } from "react";

import styles from "./styles.module.css";

import { Menu } from "@headlessui/react";
import classNames from "classnames";
import DropdownHeadless from "../../../shared/common/DropdownHeadless";
import { BsTelephone } from "react-icons/bs";
import { BiSolidDownArrow } from "react-icons/bi";
import { phoneNumberHandler } from "../../../../utils/helpers";
import CopyToClipboard from "../../../shared/atoms/CopyToClipboard";

let MyCustomButton = forwardRef(function (props, ref) {
  return (
    <button className={styles.bellOnHeader} ref={ref} {...props}>
      <div className={classNames(styles.phoneIcon, "ml-4")}>
        <BsTelephone size={20} color={"white"} />
      </div>
      <div className="ml-1">Contact Numbers</div>
      <span>
        <BiSolidDownArrow size={10} className={styles.light} />
      </span>
    </button>
  );
});
export function PhoneNumbersOnSideDrawer({ item }) {
  let storeNumber =
    item?.courier_phone_number_for_store && item?.courier_phone_number_for_store[0] !== "+"
      ? "+" + item?.courier_phone_number_for_store
      : item?.courier_phone_number_for_store;

  let customerNumber =
    item?.courier_phone_number_for_store && item?.courier_phone_number_for_store[0] !== "+"
      ? "+" + item?.courier_phone_number_for_customer
      : item?.courier_phone_number_for_customer;

  if (customerNumber || storeNumber) {
    return (
      <div className="-mr-5">
        <DropdownHeadless externalDropDownClasses={styles.dropdown} CustomButton={MyCustomButton}>
          <Menu.Item as={Fragment}>
            {({ active }) => (
              <div className="flex flex-col items-center mb-2">
                <div className={styles.labels}>For Merchant</div>
                <div
                  className={classNames(styles.orderIdTitle, { [styles.active]: active }, " text-left cursor-pointer px-0 flex")}
                >
                  {storeNumber ? phoneNumberHandler(storeNumber) : "N/A"}{" "}
                  {storeNumber && (
                    <span onClick={(e) => e.stopPropagation()}>
                      <CopyToClipboard copyText={storeNumber} extraContainerClasses={styles.copyIcon} />
                    </span>
                  )}
                </div>
                {storeNumber &&
                  item?.courier_phone_number_for_store_pin_code &&
                  `pin: ${item?.courier_phone_number_for_store_pin_code}`}
              </div>
            )}
          </Menu.Item>
          <Menu.Item as={Fragment}>
            {({ active }) => (
              <div className="flex flex-col items-center">
                <div className={classNames(styles.labels, styles.bgForCustomer)}>For customer</div>
                <div
                  className={classNames(
                    styles.orderIdTitle,
                    { [styles.active]: active },
                    "text-left px-5 cursor-pointer flex whitespace-nowrap"
                  )}
                >
                  {customerNumber ? phoneNumberHandler(customerNumber) : "N/A"}{" "}
                  {customerNumber && (
                    <span onClick={(e) => e.stopPropagation()} className="cursor-pointer">
                      <CopyToClipboard copyText={customerNumber} extraContainerClasses={styles.copyIcon} />
                    </span>
                  )}
                </div>
              </div>
            )}
          </Menu.Item>
        </DropdownHeadless>
      </div>
    );
  } else {
    return (
      <div className={styles.oneNumber}>
        <div className={classNames(styles.phoneIcon, "ml-12")}>
          <BsTelephone color={"white"} />
        </div>
        {item?.courier_phone_number ? phoneNumberHandler(item?.courier_phone_number) : "N/A"}
      </div>
    );
  }
}
