import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useMerchant, userDataStore } from "../store";
import { canSeeStatementsTab } from "../utils/helpers";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import TabButton from "./shared/common/TabButton";
import { ROLES } from "./AccountSettings/RolesSidebar/helpers";
import { useFlag } from "@unleash/proxy-client-react";

function SettingsPage() {
  // const { isdesktop } = useDesktopOrLaptop();
  // const isUserRolesEnabled = false;
  const isUserRolesEnabled = useFlag("userRoles");

  const location = useLocation();

  const { merchantData } = useMerchant();
  const { userRole, isAdmin } = userDataStore();

  const [settingsPages, setSettingsPages] = useState("payments");
  let navigate = useNavigate();
  // const canSeeStatements = canSeeStatementsTab(merchantData?.capabilities);
  useEffect(() => {
    window.gtag("event", "page_view", {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: window.location.pathname,
    });
    window.analytics.page();
    let splitedPath = location.pathname.split("/");

    if (!location?.pathname || !merchantData) {
      return;
    }

    if (splitedPath[3] === "payments") {
      if (userRole !== ROLES.ADMIN && !isAdmin && isUserRolesEnabled) {
        setSettingsPages("addressBook");
        navigate("/v1/settings/addressBook");
        return;
      }
    }
    if (splitedPath[3] === "details") {
      if (userRole !== ROLES.ADMIN && !isAdmin && isUserRolesEnabled) {
        setSettingsPages("addressBook");
        navigate("/v1/settings/addressBook");
        return;
      }
    }
    if (splitedPath[3] === "members") {
      if ((userRole === ROLES.EMPLOEE || userRole === ROLES.STORE_EMPLOEE) && !isAdmin && isUserRolesEnabled) {
        setSettingsPages("addressBook");
        navigate("/v1/settings/addressBook");
        return;
      }
    }

    if (splitedPath[3] === "billing" && !canSeeStatementsTab(merchantData?.capabilities).isEnabled) {
      if (userRole === ROLES.ADMIN || isAdmin || !isUserRolesEnabled) {
        setSettingsPages("payments");
        navigate("/v1/settings/payments");
      } else {
        setSettingsPages("addressBook");
        navigate("/v1/settings/addressBook");
      }
    } else {
      // navigate("/v1/settings/payments");
      setSettingsPages(splitedPath[3]);
    }
  }, [location.pathname, merchantData]);

  return (
    <div style={{ padding: "24px 24px 10px 24px", height: `calc(100% - 70px)` }} className="w-full flex flex-col">
      <div className="w-full h-full">
        <Outlet />
      </div>
    </div>
  );
}

export default SettingsPage;
