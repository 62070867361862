import * as React from "react";

const AvatarB = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 170 170">
    <rect width="170" height="170" fill="#0062FF" rx="12"></rect>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M74.57 42.253c0-4.558 3.716-8.253 8.3-8.253s8.301 3.695 8.301 8.252v28.371a32.2 32.2 0 0 1 15.614-4.006c17.792 0 32.215 14.34 32.215 32.027 0 17.689-14.423 32.028-32.215 32.028H63.106c-4.148 0-7.51-3.343-7.51-7.467s3.362-7.466 7.51-7.466h10.87c2.62 0 4.744-2.111 4.744-4.715 0-2.605-2.124-4.717-4.744-4.717H47.295c-4.148 0-7.51-3.342-7.51-7.466s3.362-7.466 7.51-7.466h26.484c2.728 0 4.94-2.2 4.94-4.913s-2.212-4.912-4.94-4.912H63.106c-4.148 0-7.51-3.343-7.51-7.466s3.362-7.467 7.51-7.467h11.463zM91.17 98.643c0 8.573 6.99 15.523 15.614 15.523 8.623 0 15.614-6.95 15.614-15.523 0-8.572-6.991-15.522-15.614-15.522s-15.614 6.95-15.614 15.522"
      clipRule="evenodd"
    ></path>
    <path fill="#fff" d="M48.264 73.856a7.632 7.632 0 1 1-15.264 0 7.632 7.632 0 0 1 15.264 0"></path>
  </svg>
);

export default AvatarB;
