import React from "react";

import styles from "./styles.module.css";

import burqLogo from "../../../assets/png/Blogo.png";
import { useOnboardingStore } from "../../../store";
import { FaCheckCircle } from "react-icons/fa";
import JustBLogo from "../../../assets/svgs/branding/JustBLogo";

const steps = [
  { number: 1, label: "Email address" },
  { number: 2, label: "Payment method" },
  { number: 3, label: "Password" },
];

const OnboardingHeader = () => {
  const { step, platformInfo, invitationDetails } = useOnboardingStore();

  return (
    <div className="w-full flex flex-col relative">
      <div className={styles.container}>
        <div className={styles.logosCont}>
          {platformInfo?.logo_url && (
            <div className={styles.platformLogo}>
              <img
                style={{
                  width: "70px",
                  objectFit: "cover",
                }}
                src={platformInfo?.logo_url}
                alt="Burq logo"
              />
            </div>
          )}
          <JustBLogo width={70} height={70} />
          {/* <img
            style={{
              width: "70px",
            }}
            src={burqLogo}
            alt="Burq logo"
          /> */}
        </div>
        <div className={styles.mainTitle}>Create your account</div>
        {invitationDetails?.connected_account?.name ? (
          <div className={styles.welcomeText}>Welcome {invitationDetails.connected_account.name}!</div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="flex items-center w-full px-10 justify-between pt-5 max-w-6xl self-center">
        {steps.map((st, index) => (
          <React.Fragment key={st.number}>
            <div className={styles.step}>
              {step > index ? (
                <FaCheckCircle className={styles.icon} />
              ) : step === st.number - 1 ? (
                <div className={styles.circle}>
                  <div className={styles.active}>{st.number}</div>
                </div>
              ) : (
                <div className={`${styles.circle}`}>{st.number}</div>
              )}
              <div className={styles.label}>{st.label}</div>
            </div>
            {index < steps.length - 1 && <div className={`${styles.line}`} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default OnboardingHeader;
