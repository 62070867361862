import { PiInfo } from "react-icons/pi";

import { CloseIcon } from "./Svgs";
import { useTheme } from "../../../../store";
import { OrderBannerImage } from "../../../../assets/svgs/Svgs";

const InfoBannerAlert = ({ onClose }) => {
  const { Colors } = useTheme();

  return (
    <div
      className="relative flex 2xl:flex-row flex-col p-[17px] gap-3 rounded-lg border items-center"
      style={{
        borderColor: "#0062FF",
        width: "100%",
      }}
    >
      <div className="flex flex-col flex-1 gap-2.5 items-start shrink-0" style={{ flex: "auto" }}>
        <div className="flex gap-3">
          <PiInfo size={16} color="#0062FF" />
          <span
            className="self-stretch font-poppins text-base font-medium leading-[16px] tracking-[-0.4px] text-gray-1200"
            style={{
              color: Colors.fontBlack,
            }}
          >
            We’re improving how you create, manage, and automate deliveries on Burq.
          </span>
        </div>
        <span
          className=" 2xl:pl-7 sm:pl-6 pl-4 text-gray-1000  font-poppins text-sm font-normal leading-6 self-stretch"
          style={{
            color: Colors.fontBlack,
          }}
        >
          You can now create <span className="font-bold">“orders”</span> on Burq instead of{" "}
          <span className="font-bold">”deliveries”</span>. This makes it easier to manage your deliveries—when a delivery is
          rerouted or canceled, the new and old
          <span className="font-bold"> deliveries</span> appear under a single <span className="font-bold">order</span>. We’ve
          also introduced <span className="font-bold">order workflows</span> to help automate order creation.{" "}
          <span
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/orders`;
            }}
            style={{
              color: Colors.fontBlack,
            }}
            className="font-poppins  text-sm font-medium leading-6 underline decoration-solid decoration-auto underline-offset-auto cursor-pointer"
          >
            Get started
          </span>
        </span>
      </div>
      <span
        className="relative cursor-pointer"
        style={{ height: "90px" }}
        onClick={() => {
          window.open("https://drive.google.com/file/d/1WMAHZM-Zm-kXQvboZ1wOud2uRxwp5pJm/view?usp=drive_link", "_blank");
        }}
      >
        <OrderBannerImage height={95} />
      </span>

      <span className="absolute right-4 top-4 rounded-sm cursor-pointer " onClick={onClose}>
        <CloseIcon
          style={{
            color: Colors.fontBlack,
          }}
        />
      </span>
    </div>
  );
};

export default InfoBannerAlert;
