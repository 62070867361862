import React, { useEffect, useState } from "react";
import { useTheme } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

const MaxReroutePriceDifferenceField = ({ label, value, setValue, isError, setIsError, touched, setTouched }) => {
  const { Colors } = useTheme();
  const { isdesktop, xsfontsize, smfontsize, mdfontsize } = useDesktopOrLaptop();

  const [localTouched, setLocalTouched] = useState(false);

  function validateDecimal(input) {
    const regex = /^\d+(\.\d{0,2})?$/; // Allows numbers with up to 2 decimal places
    return regex.test(input);
  }

  useEffect(() => {
    const isValid = validateDecimal(value);
    if ((localTouched || touched) && !isValid) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [value, touched, setIsError]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
      setValue(inputValue);
  };

  return (
    <div>
      <div
        style={{
          marginTop: "30px",
          color: Colors.fontDark,
          marginLeft: "4px",
          fontSize: mdfontsize,
        }}
      >
        {label}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            borderWidth: "1px",
            borderColor: isError ? Colors.redDark : Colors.borderSecondary,
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: isdesktop ? "36px" : "44px",
            position: "relative",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              backgroundColor: Colors.detailsBg,
              borderRadius: "10px 0px 0px 10px",
              padding: "0px 20px",
              color: Colors.fontGreyLight,
            }}
          >
            $
          </div>
          <input
            value={value}
            type="text"
            onBlur={() => {
              setLocalTouched(true);
              setTouched(true);
            }}
            onChange={handleInputChange}
            style={{
              flex: 1,
              backgroundColor: "transparent",
              paddingLeft: "10px",
              outline: "none",
              color: Colors.fontDark,
              height: isdesktop ? "32px" : "40px",
              fontSize: smfontsize,
            }}
          />
        </div>
      </div>
      {isError && (localTouched || touched) && (
        <span
          style={{
            position: "relative",
            fontSize: xsfontsize,
            color: Colors.redDark,
            // top: 36,
          }}
        >
          Value must be a non-negitive number
        </span>
      )}
      <div
        style={{
          marginTop: "10px",
          color: Colors.fontGreyLight,
          fontSize: xsfontsize,
        }}
      >
        * This field sets the maximum price difference allowed when rerouting a delivery to another provider. The value represents
        the highest amount, in dollars, that the delivery cost may increase due to rerouting.
      </div>
    </div>
  );
};

export default MaxReroutePriceDifferenceField;
