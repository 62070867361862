import React, { useEffect, useMemo } from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useFeatureFlags, useMyStore, useTheme } from "../../store";
import HeaderGreetingSection from "./HeaderGreetingSection";
import HeatChartSection from "./HeatChartSection";
import InsightsSection from "./InsightsSection";
import OverviewSection from "./OverviewSection";
import TableSection from "./TableSection";
import { useFlag, useVariant } from "@unleash/proxy-client-react";
import { Loading } from "../../assets/svgs/Svgs";
import { setCookie } from "../../utils";
import InfoBannerAlert from "../shared/common/InfoBanner";
import MissingPaymentMethodAlert from "../shared/common/MissingPaymentMethodAlert";

const Analytics = () => {
  const snowFlakeEnabled = useFlag("isSnowflakeDisabled");
  const isNewReactAnalyticPageEnabled = useFlag("isNewReactAnalyticPageEnabled");
  const { hideOrderBannerCookie, setHideOrderBannerCookie } = useMyStore();
  const { isdesktop } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  const { isSnowflakeDisabled, setIsSnowflakeDisabled } = useFeatureFlags();
  useEffect(() => {
    setIsSnowflakeDisabled(snowFlakeEnabled);
  }, [snowFlakeEnabled]);

  const styles = useMemo(() => {
    return {
      container: {
        fontFamily: "Poppins",
        padding: isdesktop ? "40px" : "10px",
        backgroundColor: Colors.bgDarkSecondary,
        boxSizing: "border-box",
        width: "100%",
        height: isdesktop ? "100%" : "calc(100% - 200px)",
      },
    };
  }, [Colors, isdesktop]);

  if (isNewReactAnalyticPageEnabled) {
    return (window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/home`);
  }

  if (isSnowflakeDisabled === "yetUndefined") {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loading color={Colors.bluePrimary} className="h-14 w-14 animate-spin" />
      </div>
    );
  } else {
    return (
      <div style={styles.container} className="kata">
        <HeaderGreetingSection />
        <div className="mb-3">
          <MissingPaymentMethodAlert />
        </div>
        {!hideOrderBannerCookie && (
          <div className="mb-8">
            <InfoBannerAlert
              onClose={() => {
                setHideOrderBannerCookie(true);
                setCookie("hideOrderBannerCookie", "true");
              }}
            />
          </div>
        )}
        <OverviewSection />
        <InsightsSection />
        <HeatChartSection />
        <TableSection />
      </div>
    );
  }
};

export default Analytics;
