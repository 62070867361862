import React, { useMemo, useState } from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useMerchant, useMyStore, userDataStore, useTheme } from "../../store";
import { constructName } from "../../utils/helpers";
import ButtonComp from "../ButtonComp/ButtonComp";
import CustomModal from "../CustomModal/CustomModal";
import AnalyticsUsersList from "./AnalyticsUsersList";

const HeaderGreetingSection = () => {
  const { isdesktop, smfontsize, xsfontsize, px24 } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  const { selectedUser, setSelectedUser, getMerchantData, setIdOfUser, merchantData } = useMerchant();
  const { loginAuth, authInfo } = userDataStore();
  const { setUserId } = useMyStore();
  const { isAdmin } = userDataStore();
  const [isUsersModalVisible, setIsUsersModalVisible] = useState(false);

  const styles = useMemo(() => {
    return {
      container: {
        display: "flex",
        justifyContent: "space-between",
      },
      greetingContainer: {
        fontFamily: "Poppins",
        paddingBottom: isdesktop ? "40px" : "10px",
      },
      greetingText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: px24,
        lineHeight: "36px",
        color: Colors.bluePrimary,
      },
      subHeadingText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: xsfontsize,
        lineHeight: "16px",
        color: Colors.fontGreyLight,
        marginTop: isdesktop ? "8px" : "20px",
      },
    };
  }, [Colors, isdesktop, xsfontsize]);

  const greetingText = () => {
    const date = new Date();
    const hour = date.getHours();
    if (hour < 12) {
      return "Good morning";
    } else if (hour < 17) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.greetingContainer}>
        <h1 style={styles.greetingText}>
          {greetingText()},{" "}
          {!!selectedUser
            ? constructName(selectedUser.first_name, selectedUser.last_name)
            : constructName(authInfo.first_name, authInfo.last_name)}
        </h1>
        <h2 style={styles.subHeadingText}>
          {merchantData?.name && `Here’s what’s going on with your deliveries at ${merchantData.name}`}
        </h2>
      </div>
      {isAdmin && (
        <div>
          {selectedUser && (
            <ButtonComp
              title={"Clear "}
              extraStyles={{
                padding: "8px",
                width: "150px",
                fontSize: smfontsize,
                color: Colors.fontDark,
                marginLeft: "20px",
              }}
              onClick={async () => {
                setSelectedUser();
                await loginAuth().then((res) => {
                  if (res.data?.user?.merchantAccountId) {
                    // getMerchantData(res.data.user.merchantAccountId);
                  }
                });
                setUserId();
                setIdOfUser();
              }}
            />
          )}
          <ButtonComp
            title={"Select User"}
            extraStyles={{
              padding: "8px",
              width: "150px",
              fontSize: smfontsize,
            }}
            primary
            onClick={() => {
              setIsUsersModalVisible(true);
            }}
          />
        </div>
      )}
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={isUsersModalVisible}
        onClose={() => setIsUsersModalVisible(false)}
      >
        <AnalyticsUsersList setIsUsersModalVisible={setIsUsersModalVisible} setSelectedUser={setSelectedUser} />
      </CustomModal>
    </div>
  );
};

export default HeaderGreetingSection;
