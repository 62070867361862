import React, { useCallback, useState } from "react";
import DatePicker, { utils } from "react-modern-calendar-datepicker";
import { BiCalendar, BiFilterAlt } from "react-icons/bi";
import { AiOutlineClear } from "react-icons/ai";
import { MdStorefront } from "react-icons/md";
import DownloadCsv from "../downloadCsv";
import Filter from "../Filter";
import StoresFilterOnListHeader from "./DeliveriesListSection/common/StoresFilterOnListHeader";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import {
  useClickStore,
  useFilter,
  useMyStore,
  useStoreAndChild,
  useTheme,
} from "../../store";
import { SearchSvg } from "../../assets/svgs/Svgs";
import { debounce } from "lodash";
import CreateDeliveryButton from "./DeliveriesListSection/CreateDeliveryButton";

const DeliveriesFilters = ({
  selectedDay,
  setSelectedDay,
  defaultValue,
  isInsideStore,
}) => {
  const { isdesktop, smfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  const { deliveries, pageCount, setPageCount, isSandbox } = useMyStore();

  const { selectedStore } = useStoreAndChild();
  const {
    filterStore,
    filterArray,
    startDate,
    setStartDate,
    search,
    setSearch,
    setFilterStore,
    setFilterArray,
  } = useFilter();

  const { isCalendarVisible, isCalendarHidden } = useClickStore();

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [isStoresListVisible, setIsStoresListVisible] = useState(false);

  const formatInputValue = () => {
    if (!selectedDay?.from?.day || !selectedDay?.to?.day || !startDate)
      return "From - To";

    return `${selectedDay?.from?.month}/${selectedDay?.from?.day}-${
      selectedDay?.to ? selectedDay.to.month + "/" + selectedDay.to.day : ""
    }`;
  };

  const renderCustomInput = ({ ref }) => (
    <input
      id="calendarPickerId"
      readOnly
      ref={ref} // necessary
      placeholder="Date"
      value={formatInputValue()}
      style={{
        fontFamily: "Poppins",
        fontSize: isdesktop ? "12px" : "22px",
        fontWeight: "300",
        backgroundColor: Colors.white,
        color: "#92929D",
        textAlign: "center",
        width: !isdesktop && "120px",
        backgroundColor: "transparent",
      }}
      className="focus:outline-none placeholder-gray-800" // a styling class
    />
  );

  const debounceSearhcFn = useCallback(
    debounce((searchText) => {
      if (pageCount > 0) {
        setPageCount(0);
      }
      deliveries(
        searchText,
        0,
        startDate,
        filterStore?.id ?? selectedStore?.id ?? "",
        filterArray
      );
    }, 800),
    [isSandbox, filterStore, selectedStore]
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: Colors.white,
        borderBottomWidth: isdesktop ? "1px" : "2px",
        borderColor: Colors.fontsBlack,
        paddingBottom: "20px",
        padding: "30px 24px",
      }}
    >
      {/* <div
        style={{
          fontFamily: "Poppins",
          fontSize: isdesktop ? "22px" : "36px",
          fontWeight: "600",
          color: Colors.fontDark,
          // marginTop: "30px",
        }}
      >
        All Activity
      </div> */}
      {/* <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      > */}

      {/* </div> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: !isdesktop && "100%",
        }}
      >
        {!isCalendarHidden && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: !isdesktop && "100%",
              justifyContent: "end",
            }}
            className="items-center w-full"
          >
            {!isInsideStore && (
              <div
                style={{
                  position: "relative",
                }}
                className="flex items-center"
              >
                <button
                  style={{
                    borderWidth: "1px",
                    borderColor: "#D8D8D8",
                    borderRadius: "16px",
                    padding: isdesktop ? "4px 0px" : "4px 4px",
                    marginLeft: "0px",
                    borderStyle: filterStore ? "solid" : "dashed",
                  }}
                  onClick={() => {
                    setIsStoresListVisible(true);
                  }}
                  type="button"
                  className="flex flex-row items-center focus:outline-none "
                >
                  {filterStore ? (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        deliveries(search, 0, startDate, "", filterArray);
                        setFilterStore();
                        setPageCount(0);
                      }}
                      style={{ padding: "2px 0px 2px 10px" }}
                    >
                      <AiOutlineClear
                        color={Colors.fontGreyLight}
                        size={isdesktop ? 22 : 26}
                      />
                    </button>
                  ) : (
                    <div style={{ padding: "2px 0px 2px 10px" }}>
                      <MdStorefront color={Colors.fontGreyLight} size={19} />
                    </div>
                  )}
                  <div
                    style={{
                      fontFamily: "Poppins",
                      // fontSize: mdfontsize,
                      fontSize: smfontsize,
                      color: Colors.fontGreyLight,
                      display: "flex",
                      alignItems: "center",
                      padding: filterStore
                        ? "2px 6px 2px 0px"
                        : "2px 10px 2px 0px",
                    }}
                  >
                    <span style={{ marginLeft: "8px" }}>Store</span>
                  </div>
                  {filterStore && (
                    <div
                      style={{
                        padding: "2px 10px 2px 6px",
                        color: Colors.fontGreyLight,
                        maxWidth: "170px",
                        fontSize: smfontsize,
                      }}
                      className="border-l border-[#D8D8D8] border-dashed truncate"
                    >
                      {filterStore.name}
                    </div>
                  )}
                </button>

                <StoresFilterOnListHeader
                  isStoresListVisible={isStoresListVisible}
                  setIsStoresListVisible={setIsStoresListVisible}
                />
              </div>
            )}

            <div
              style={{
                justifyContent: "flex-end",
                position: "relative",
              }}
              className="flex"
            >
              <button
                style={{
                  borderWidth: "1px",
                  borderColor: "#D8D8D8",
                  borderRadius: "16px",
                  padding: isdesktop ? "4px 0px" : "4px",
                  marginLeft: "18px",
                  borderStyle: filterArray?.length > 0 ? "solid" : "dashed",
                }}
                onClick={() => {
                  window.analytics.track("Clicked on sort by");

                  setIsFilterVisible(!isFilterVisible);
                }}
                type="button"
                className="flex flex-row items-center focus:outline-none "
              >
                {filterArray?.length > 0 ? (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      deliveries(
                        search,
                        0,
                        startDate,
                        selectedStore ? selectedStore.id : filterStore?.id,
                        []
                      );
                      setPageCount(0);
                      setFilterArray([]);
                    }}
                    style={{ padding: "2px 0px 2px 10px" }}
                  >
                    <AiOutlineClear
                      color={Colors.fontGreyLight}
                      size={isdesktop ? 22 : 26}
                    />
                  </button>
                ) : (
                  <div style={{ padding: "2px 0px 2px 10px" }}>
                    <BiFilterAlt color={Colors.fontGreyLight} size={18} />
                  </div>
                )}
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontSize: isdesktop ? "12px" : "20px",
                    color: Colors.fontGreyLight,
                    display: "flex",
                    alignItems: "center",
                    padding:
                      filterArray?.length > 0
                        ? "2px 6px 2px 2px"
                        : "2px 10px 2px 2px",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "6px",
                      whiteSpace: "nowrap",
                      // fontSize: mdfontsize,
                      fontSize: smfontsize,
                    }}
                  >
                    Filter by
                  </span>
                </div>
                {filterArray?.length > 0 && (
                  <div
                    style={{
                      padding: "2px 10px 2px 6px",
                      color: Colors.fontGreyLight,
                      maxWidth: "170px",
                      fontSize: smfontsize,
                    }}
                    className="border-l border-[#D8D8D8] border-dashed truncate"
                  >
                    {filterArray?.length}
                  </div>
                )}
              </button>

              <Filter
                setPageCount={setPageCount}
                startDate={startDate}
                setStartDate={setStartDate}
                isFilterVisible={isFilterVisible}
                setIsFilterVisible={setIsFilterVisible}
              />
            </div>
            <div
              style={{ marginLeft: "18px" }}
              className="flex flex-row items-center w-full"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: "1px",
                  borderColor: Colors.borderSecondary,
                  borderRadius: "16px",
                  marginRight: "20px",
                  paddingLeft: "10px",
                  height: isdesktop ? "36px" : "50px",
                  borderStyle: startDate ? "solid" : "dashed",
                }}
              >
                {startDate ? (
                  <button
                    type="button"
                    onClick={() => {
                      setStartDate();
                      deliveries(
                        search,
                        0,
                        "",
                        filterStore?.id ?? selectedStore?.id ?? "",
                        filterArray
                      );
                      setSelectedDay(defaultValue);
                      setPageCount(0);
                    }}
                    style={{}}
                  >
                    <AiOutlineClear
                      color={Colors.fontGreyLight}
                      size={isdesktop ? 22 : 26}
                    />
                  </button>
                ) : (
                  <BiCalendar
                    color={Colors.fontGreyLight}
                    size={isdesktop ? 22 : 26}
                  />
                )}
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontSize: isdesktop ? "12px" : "20px",
                    color: Colors.fontGreyLight,
                    marginRight: isdesktop ? "1px" : "0px",
                    marginLeft: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Date range:
                </div>
                {isCalendarVisible && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DatePicker
                      inputClassName="z-0"
                      calendarPopperPosition="bottom"
                      renderInput={renderCustomInput}
                      value={selectedDay ? selectedDay : utils.getToday()}
                      colorPrimary="#3DD598" // added this
                      colorPrimaryLight="#ECFBF5"
                      // and this
                      onChange={(day) => {
                        setSelectedDay(day);
                        if (day.from && day.to) {
                          let date = {
                            start: `${day.from.year}-${day.from.month}-${day.from.day}`,
                            end: `${day.to.year}-${day.to.month}-${day.to.day}`,
                          };
                          deliveries(
                            search,
                            0,
                            date,
                            filterStore?.id ?? selectedStore?.id ?? "",
                            filterArray
                          );
                          setStartDate(date);
                          setPageCount(0);
                        }
                      }}
                      // inputPlaceholder="From - To"
                      maximumDate={utils().getToday()}
                      formatInputText={formatInputValue}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {isdesktop && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          style={{ minWidth: "20vw" }}
          className="flex xl:justify-between justify-start"
        >
          <div
            style={{
              padding: "4px 10px",
              borderColor: Colors.borderPrimary,
              borderWidth: "1px",
              borderRadius: "10px",
            }}
            className="w-full flex items-center justify-evenly lg:px-0"
          >
            <SearchSvg />
            <input
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value === "") {
                  if (pageCount > 0) {
                    setPageCount(0);
                  }
                  deliveries(
                    e.target.value,
                    0,
                    startDate,
                    filterStore?.id ?? selectedStore?.id ?? "",
                    filterArray
                  );
                } else {
                  debounceSearhcFn(e.target.value);
                }
              }}
              style={{
                fontSize: smfontsize,
                fontWeight: "normal",
                backgroundColor: Colors.white,
                color: Colors.fontDark,
              }}
              type="search"
              className="focus:outline-none mr-1 bg w-full  pl-2  py-1 "
              placeholder="Search"
              value={search}
            />
          </div>
          <DownloadCsv admin={false} />
          <CreateDeliveryButton externalStyles={{ height: "42px" }} />
        </form>
      )}
    </div>
  );
};

export default DeliveriesFilters;
