import React, { useEffect, useState } from "react";
import {
  useProvidersManagementStore,
  useMyStore,
  userDataStore,
} from "../../store";
import { groupBy } from "lodash";

import ProviderSettingsDrawer from "./ProviderSettingsDrawer";
import ProviderList from "./ProviderList";
import styles from "./styles/styles.module.css";
import classNames from "classnames";
import Button from "../shared/atoms/Button";
import { TABS } from "./ProviderIntegrationDetails";
import MissingPaymentMethodAlert from "../shared/common/MissingPaymentMethodAlert";

function ProviderIntegrationSettings() {
  const [providers, setProviders] = useState([]);
  const [additionalProviders, setAdditionalProviders] = useState([]);
  const { merchantProviders, getMerchantProvidersHandler, setIsMissingProviderRequestFormOpen, setDefaultActiveTab } =
    useProvidersManagementStore();

  const { authInfo } = userDataStore();
  const { isSandbox } = useMyStore();

  useEffect(() => {
    if (merchantProviders?.length) {
      const { false: providers, true: additionalProviders } = groupBy(
        merchantProviders,
        "access_required"
      );
      setProviders([...providers]);
      setAdditionalProviders([...additionalProviders]);
    }
  }, [merchantProviders]);

  useEffect(() => {
    if (authInfo) {
      getMerchantProvidersHandler(authInfo.merchantAccountId, isSandbox);
    }
  }, [authInfo, isSandbox]);

  return (
    <div className={classNames("kata", styles.pageBody)}>
      {isSandbox && <div className={styles.testModeBadge}>Test Mode</div>}
      <div className={styles.title}>Providers</div>
      <div className={styles.description}>
        Manage all your delivery providers{" "}
      </div>
      <MissingPaymentMethodAlert />
      <ProviderList providers={providers} />
      <div className={styles.title}>Additional Providers</div>
      <div className={styles.description}>
        Manage all your delivery providers{" "}
      </div>
      <ProviderList providers={additionalProviders} />
      <div className={styles.title}>Missing a provider?</div>
      <div className={styles.description}>
        Is your ideal provider missing? Let us know, and we will make it happen!
      </div>
      <Button
        onClick={(e) => { 
          setIsMissingProviderRequestFormOpen(true)
          setDefaultActiveTab(TABS.REQUEST_PROVIDER);
          e.stopPropagation();
        }}
        text="Request Provider"
        externalClassName={styles.requestProviderButton}
      />
      <ProviderSettingsDrawer />
    </div>
  );
}

export default ProviderIntegrationSettings;
