import React, { useState, useEffect } from "react";
import expandArrow from "../../../assets/png/expandArrow.png";
import { useClickStore, useTheme, useSingleItemStore, useMyStore } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { statusesObject } from "../../../runTimeConst";
import DeliveryListIcon from "./DeliveryListIcon";
import { getStatus } from "../utils";
import Hyperlink from "../../shared/atoms/Hyperlink";
import { useNavigate } from "react-router-dom";
import styles from "../styles/deliveriesListResponsive.module.css";

const DeliveriesListResponsive = ({ status, item, requestId, setIsDetails, socketMessage }) => {

  const { setIsCalendarVisible } = useClickStore();
  const { setitem } = useSingleItemStore();
  const { smfontsize } = useDesktopOrLaptop();

  const [statusSocket, setStatusSocket] = useState(status);
  const [rerouteStatus, setRerouteStatus] = useState(item?.reroute_status);
  const {
    deliveriesList
  } = useMyStore();
  const [statusesHystory, setStatusesHystory] = useState(item.events);

  const returnDriverStatus = () => {
    if (statusSocket == "delivery_created" || statusSocket == "driver_not_assigned") {
      return "Assigning Driver";
    } else return "Not available";
  };

  useEffect(() => {
    if (socketMessage?.id === requestId) {
      setStatusSocket(socketMessage?.status);
      setRerouteStatus(socketMessage?.reroute_status);
    }
  }, [socketMessage]);

  const { Colors } = useTheme();
  return (
    <div
      style={{
        backgroundColor: Colors.white,
        marginTop: "0px",
        marginBottom: "0px",
        padding: "10px 20px",
        overflow: "hidden",
        // height: "250px",
        paddingBottom: "30px",
        borderBottomWidth: "1px",
        borderColor: Colors.fontGreyLight,
      }}
    >
      <button
        type="button"
        onClick={() => {
          window.analytics.track("On item is clicked on mobile");
          setIsDetails(true);
          setitem(item);
          setIsCalendarVisible(false);
        }}
        style={{
          // height: "94px",
          width: "100%",
          flexDirection: "row",
          display: "flex",
          outline: "none",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <DeliveryListIcon statusSocket={statusSocket} rerouteStatus={rerouteStatus} />
          <div
            style={{
              flexDirection: "column",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: "22.5px",
                paddingRight: "34px",
                justifyContent: "space-between",
                minWidth: "300px",
                marginLeft: "35px",
                textAlign: "start",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  color: Colors.fontGreyLight,
                }}
              >
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "30px",
                    textAlign: "start",
                  }}
                >
                  To
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Drop off
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "30px",
                    marginTop: "30px",
                  }}
                >
                  Driver
                </div>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  marginLeft: smfontsize,
                  color: Colors.fontGreyLight,
                  textAlign: "start",
                }}
              >
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontSize: "30px",
                    width: "500px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    color: Colors.fontBlack,
                    fontWeight: "300",
                  }}
                >
                  {item.dropoffs.map((dropoff, idx) => {
                    if (item.dropoffs.length === idx + 1) {
                      return dropoff.dropoff_name;
                    } else {
                      return dropoff.dropoff_name + ", ";
                    }
                  })}
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontSize: "30px",
                    marginTop: "30px",
                    color: Colors.fontBlack,
                    fontWeight: "300",
                  }}
                >
                  {item?.dropoffs[item.dropoffs.length - 1]?.dropoff_address_street || "Not Available"}
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontSize: "30px",
                    marginTop: "30px",
                    color: Colors.fontBlack,
                    fontWeight: "300",
                  }}
                >
                  {item.courier_name || returnDriverStatus()}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "36px",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  height: "15px",
                  width: "15px",
                  borderRadius: "10px",
                  backgroundColor:
                    rerouteStatus === "provider_search" ? "#656565"
                      : rerouteStatus === "rerouted" ? "#0077CC"
                        : statusSocket === "completed"
                          ? " bg-green-50"
                          : statusSocket === "pending" ||
                            statusSocket === "delivery_created" ||
                            statusSocket === "driver_assigned" ||
                            statusSocket === "driver_not_assigned"
                            ? "#FF974A"
                            : statusSocket === "canceled" ||
                              statusSocket === "provider_canceled" ||
                              statusSocket === "customer_canceled" ||
                              statusSocket === "burq_canceled" ||
                              statusSocket === "expired" ||
                              statusSocket === "failed" ||
                              statusSocket === "disputed"
                              ? "#FC5A5A"
                              : statusSocket === "delivered"
                                ? "#3DD598"
                                : statusSocket === "pickup" ||
                                  statusSocket === "enroute_pickup" ||
                                  statusSocket === "arrived_at_pickup" ||
                                  statusSocket === "pickup_complete"
                                  ? "#50B5FF"
                                  : statusSocket === "dropoff" ||
                                    statusSocket === "enroute_dropoff" ||
                                    statusSocket === "arrived_at_dropoff" ||
                                    statusSocket === "dropoff_complete"
                                    ? "#A461D8"
                                    : statusSocket === "Paid order" ||
                                      statusSocket === "contacting_provider" ||
                                      statusSocket === "scheduled" ||
                                      statusSocket === "returned"
                                      ? "#0062FF"
                                      : statusSocket === "request"
                                        ? "#92929D"
                                        : Colors.fontGreyLight,
                }}
              ></div>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "30px",
                  textAlign: "center",
                  marginLeft: "10px",
                  color:
                    rerouteStatus === "provider_search"
                      ? "#656565"
                      : rerouteStatus === "rerouted"
                        ? "#0077CC"
                        : statusSocket === "completed"
                          ? " bg-green-50"
                          : statusSocket === "pending" ||
                            statusSocket === "delivery_created" ||
                            statusSocket === "driver_assigned" ||
                            statusSocket === "driver_not_assigned"
                            ? "#FF974A"
                            : statusSocket === "canceled" ||
                              statusSocket === "provider_canceled" ||
                              statusSocket == "customer_canceled" ||
                              statusSocket === "burq_canceled" ||
                              statusSocket === "expired" ||
                              statusSocket === "failed" ||
                              statusSocket === "disputed"
                              ? "#FC5A5A"
                              : statusSocket === "delivered"
                                ? "#3DD598"
                                : statusSocket === "pickup" ||
                                  statusSocket === "enroute_pickup" ||
                                  statusSocket === "arrived_at_pickup" ||
                                  statusSocket === "pickup_complete"
                                  ? "#50B5FF"
                                  : statusSocket === "dropoff" ||
                                    statusSocket === "enroute_dropoff" ||
                                    statusSocket === "arrived_at_dropoff" ||
                                    statusSocket === "dropoff_complete"
                                    ? "#A461D8"
                                    : statusSocket === "Paid order" ||
                                      statusSocket === "contacting_provider" ||
                                      statusSocket === "scheduled" ||
                                      statusSocket === "returned"
                                      ? "#0062FF"
                                      : statusSocket === "request"
                                        ? "#92929D"
                                        : Colors.fontGreyLight,
                }}
              >
                {getStatus({ statusSocket, rerouteStatus })}
              </div>
              {item?.reroute_child_delivery_id && <Hyperlink text="View new delivery" externalClassName={styles.viewLink} onClick={(e) => {
                e.stopPropagation();
                setIsDetails(true);
                setitem(deliveriesList.find(d => d.id === item?.reroute_child_delivery_id));
                setIsCalendarVisible(false);
              }} />}
            </div>
          </div>
          {/* <img
            src={expandArrow}
            alt="expandArrow"
            style={{
              position: "absolute",
              top: "20px",
              right: "30px",
              width: "36px",
              // marginTop: "48px",
              objectFit: "contain",
            }}
          /> */}
        </div>
      </button>
    </div>
  );
};

export default DeliveriesListResponsive;
