import React, { useState, useRef, useCallback, useEffect } from "react";

import { BurgerSvgRotated, DropDown, SettingsIcon } from "../../assets/svgs/Svgs";
import logo from "../../assets/png/dark.png";
import CommonToggle from "../CommonToggle";

import Cookies from "js-cookie";
import Avatar from "react-avatar";

import { useMyStore, useClickStore, useTheme, useStoreAndChild, useMerchant, useFilter } from "../../store";
import { Link, useNavigate } from "react-router-dom";
import { ImSun } from "react-icons/im";
import { FaMoon } from "react-icons/fa";
import burqlogo from "../../assets/png/g10.png";
import darkburqlogo from "../../assets/png/g10Dark.png";
import { FiLogOut } from "react-icons/fi";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

import { baseUrl } from "../../runTimeConst";
import { ZendeskAPI } from "react-zendesk";

import styles from "./styles.module.css";
import classNames from "classnames";
import Notifications from "./Notifications";
import { removeCookie, setCookie } from "../../utils";
import AvatarB from "../../assets/svgs/branding/AvatarB";
import SettingsDropdownMenu from "./SettingsDropdownMenu";

function Header({ noNotifications, disableToggle, setQuoteRes, fixed, noMenu, toggle, noStores, setSidepanelactive }) {
  const { useOnClickOutside, isBurgerMenuVisible, setIsBurgerMenuVisible } = useClickStore();
  const [logoutVisible, setLogoutVisible] = useState(false);
  const { Colors, themeChanger, changeTheme } = useTheme();
  const { merchantData, avatar } = useMerchant();
  const { setSelectedStore, selectedStore } = useStoreAndChild();

  let history = useNavigate();

  const { isSandbox, setIsSandbox, setDeliveriesForAnalitycs, setHideOrderBannerCookie } = useMyStore();

  const { setFilterArray, filterArray } = useFilter();

  // const { avatar } = userDataStore();
  const { isdesktop, mdfontsize } = useDesktopOrLaptop();
  const sandboxHandler = () => {
    if (!disableToggle) {
      window.analytics.track(`Test envirement ${!isSandbox}`);

      setDeliveriesForAnalitycs([]);

      if (JSON.stringify(filterArray) !== JSON.stringify([])) {
        setFilterArray([]);
      }

      setIsSandbox(!isSandbox);
      setCookie("testModeCookie", !isSandbox ? "true" : "false");
      if (setQuoteRes) {
        setQuoteRes();
      }
    }
  };

  const clickRef = useRef();
  const handler = useCallback(() => {
    setLogoutVisible(false);
  });
  useOnClickOutside(clickRef, handler);
  useEffect(() => {
    if (Cookies.get("testModeCookie") === "true") {
      setIsSandbox(true);
    } else {
      setIsSandbox(false);
    }
  }, [Cookies.get("testModeCookie")]);

  useEffect(() => {
    if (Cookies.get("hideOrderBannerCookie") === "true") {
      setHideOrderBannerCookie(true);
    } else {
      setHideOrderBannerCookie(false);
    }
  }, [Cookies.get("hideOrderBannerCookie")]);

  return (
    <header
      style={{
        position: fixed ? "fixed" : "",
      }}
      className={classNames(styles.container)}
    >
      <div className="h-full flex items-center">
        {noMenu ? (
          <div className="object-fill ml-3">
            <Link to="/v1/home" className="" onClick={() => {}}>
              <img
                style={{
                  height: isdesktop ? "36px" : "60px",
                }}
                src={isdesktop ? logo : themeChanger ? burqlogo : darkburqlogo}
                alt="Burq logo"
              />
            </Link>
          </div>
        ) : (
          <div>
            {!isBurgerMenuVisible && (
              <button
                className="ml-6"
                type="button"
                onClick={() => {
                  setIsBurgerMenuVisible(true);
                }}
              >
                <BurgerSvgRotated />
              </button>
            )}
          </div>
        )}

        <div className="flex flex-row items-center">
          <div className=" ml-0 flex flex-row items-center">
            {!toggle && (
              <div className="flex flex-row items-center ml-5">
                <div
                  className="font-semibold mr-3"
                  style={{
                    color: isSandbox ? Colors.bluePrimary : Colors.fontGreyLight,
                    fontSize: mdfontsize,
                  }}
                >
                  Test
                </div>
                <CommonToggle
                  id="headerToggle"
                  smsHandler={sandboxHandler}
                  checked={isSandbox}
                  translateX={isdesktop ? 13.5 : 20}
                  circleStyle={{
                    width: isdesktop ? "11px" : "20px",
                    height: isdesktop ? "11px" : "20px",
                    backgroundColor: Colors.white,
                  }}
                  style={{
                    width: isdesktop ? "30px" : "46px",
                    height: isdesktop ? "16px" : "22px",
                    padding: "3px",
                    backgroundColor: isSandbox ? Colors.bluePrimary : Colors.fontGreyLight,
                  }}
                />
              </div>
            )}
          </div>
        </div>

        {selectedStore && !noStores && (
          <div className={classNames("flex items-center", styles.unselectCont)}>
            <button
              onClick={() => {
                setSelectedStore();
                history("/v1/stores");
              }}
              style={{ color: Colors.fontGreyLight }}
              type="button"
              className="w-full truncate"
            >
              Unselect
              <span className={styles.unselectText}>{selectedStore.name}</span>
            </button>
          </div>
        )}
      </div>

      <div className="flex items-center">
        {!noNotifications && <Notifications />}
        {themeChanger ? (
          <button
            onClick={() => {
              changeTheme();
              window.analytics.track("dark mode is enabled");
            }}
            type="button"
            className="outline-none mr-7 w-4"
          >
            <ImSun color="#d4af37" size={22} />
          </button>
        ) : (
          <button
            onClick={async () => {
              changeTheme();
              window.analytics.track("dark mode is disabled");
            }}
            type="button"
            className="outline-none mr-7 w-4"
          >
            <FaMoon color="lightgrey" size={18} />
          </button>
        )}

        <div
          className="flex items-center focus:outline-none"
          onClick={() => {
            setLogoutVisible(!logoutVisible);
          }}
        >
          <div className="rounded-full overflow-hidden" style={{height: "40px", width: "40px"}}>
            {avatar ? (
              <img
              style={{ objectFit: "cover", height: "100%", width: "100%" }} 
              src={avatar + "?" + Date.now() ?? ""} alt="" />
            ) : (
              <Avatar
                name={merchantData?.email ? merchantData.email.split("").join(" ") : merchantData?.name}
                size={40}
                maxInitials={2}
                className={styles.triggerAvatarFallback}
              />
            )}
          </div>
          <DropDown height={24} width={24}/>
        </div>
        {logoutVisible && (
          <SettingsDropdownMenu setSidepanelactive={setSidepanelactive} setLogoutVisible={setLogoutVisible} clickRef={clickRef} />
        )}
      </div>
    </header>
  );
}

export default Header;
