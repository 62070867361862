import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import axios from "axios";
import Cookies from "js-cookie";

import { toast } from "react-toastify";
import { baseUrl, baseUrlOfApi } from "../runTimeConst";
import { generateQueryParams } from "../utils/api/utils";
import { removeCookie } from "../utils";

const apiUrl = baseUrl;
const itemTemplate = { name: "", quantity: "", size: "small" };
const dropoffTemplate = {
  dropoff_address: "",
  dropoff_businessName: "",
  dropoff_phone_number: "",
  dropoff_notes: "",
  dropoffName: "",
  dropoffLastName: "",
  dropoff_name: "",
};

const useMyStore = createStore({
  name: "LoginStore",
  Store: () => {
    const [dotsMenuVisible, setDotsMenuVisible] = useState(false);
    const [isSandbox, setIsSandbox] = useState(false);
    const [hideOrderBannerCookie, setHideOrderBannerCookie] = useState(false);

    const [search, setSearch] = useState("");
    // ! LOGIN
    const [userId, setUserId] = useState();
    const [loading, setLoading] = useState(false);
    const [isScalingDisabled, setIsScalingDisabled] = useState(false);

    // ! USERS LIST

    const [usersList, setUsersList] = useState();
    const [isUsersListRequestLoading, setIsUsersListRequestLoading] = useState(false);
    const [allUsers, setAllUsers] = useState();

    const usersListHandler = async (params) => {
      const parameters = {
        searchKey: params?.searchKey ?? "",
        limit: params?.limit ?? "50",
        ending_before: params?.ending_before,
        starting_after: params?.starting_after,
      };
      const url = generateQueryParams(`${apiUrl}delivery/users`, parameters);
      setIsUsersListRequestLoading(true);

      try {
        // let queryParams = `?perPage=${perPage || "50"}&page=${page || "0"}`;
        // if (search) {
        //   queryParams += `&searchKey=${search || ""}`;
        // }
        // const response = await axios.get(
        //   `${apiUrl}delivery/users${queryParams}`
        // );
        const response = await axios.get(url);
        // console.log("USERSLIST GET RESPONSE", response);
        setUsersList(response.data);
        setIsUsersListRequestLoading(false);
        setAllUsers();
        return response;
      } catch (error) {
        setIsUsersListRequestLoading(false);
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    // ! DELIVERIES
    const [logout, setLogout] = useState(false);
    const [deliveriesError, setDeliveriesError] = useState();
    const [deliveriesForAnalitycs, setDeliveriesForAnalitycs] = useState();
    const [deliveriesList, setDeliveriesList] = useState();
    const [deliveriesLoading, setDeliveriesLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [totalDeliveryPages, setTotalDeliveryPages] = useState();
    const [totalDeliveries, setTotalDeliveries] = useState();

    const deliveries = async (search, page, startDate, storeId, filteredArr, source) => {
      try {
        setDeliveriesLoading(true);
        const response = await axios.get(
          `${apiUrl}delivery/list/${userId || ""}?${storeId ? "store_id=" + storeId + "&" : ""}test_mode=${isSandbox}&${
            search ? "searchKey=" + search + "&" : ""
          }page=${page}&limit=30&${startDate?.start ? "startDate=" + startDate.start + "&" : ""}${
            startDate?.end ? "endDate=" + startDate?.end + "&" : ""
          }${filteredArr?.length > 0 ? "statuses=" + filteredArr : ""}`,
          { headers: { "x-burq-client": "client-dashboard" }, cancelToken: source?.token }
        );

        setDeliveriesList(response.data.items);
        setDeliveriesForAnalitycs(response.data);
        setTotalDeliveryPages(Math.ceil(response.data.total / 30));
        setTotalDeliveries(response.data.filteredTotal);
        setDeliveriesLoading(false);
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          if (error?.response?.status) {
            if (error?.response?.status === 401 || error.response.data?.message === "The user does not exist.") {
              Object.keys(Cookies.get()).forEach((cookieName) => {
                removeCookie(cookieName);
              });
              setTimeout(() => {
                window.location.href = `${baseUrl}auth/login`;
              }, 1000);
            }
          }
          toast.error(error.response.data.message);
        } else {
          Sentry.captureException(error);
          if (!source) {
            toast.error("Something went wrong! Please try again.");
          }
        }
        // console.log("login error", error.response);
        setDeliveriesError(error);
        setDeliveriesLoading(false);
        return error;
      }
    };

    // ! PICKUP FIELDS

    const [pickupName, setPickupName] = useState("");
    const [pickupLastName, setPickupLastName] = useState("");
    const [pickupBusiness, setPickupBusiness] = useState("");
    const [pickupPhoneNumber, setPickupPhoneNumber] = useState("");
    const [pickupNote, setPickupNote] = useState("");
    const [pickupZip, setPickupZip] = useState();
    const [pickupStateShort, setPickupStateShort] = useState();

    // ! DROPOFF FIELDS

    const [dropoffName, setDropoffName] = useState("");
    const [dropoffLastName, setDropoffLastName] = useState("");
    const [dropoffBusiness, setDropoffBusiness] = useState("");
    const [dropoffPhoneNumber, setDropoffPhoneNumber] = useState("");
    const [dropoffNote, setDropoffNote] = useState("");
    const [dropoffZip, setDropoffZip] = useState();
    const [dropoffStateShort, setDropoffStateShort] = useState();

    // !
    const [loadingQuote, setLoadingQuote] = useState(false);

    // requests data

    const [quoteRes, setQuoteRes] = useState();
    const [quoteError, setQuoteError] = useState();
    const [quoteId, setQuoteId] = useState();

    const createQuote = async (
      values,
      isSandbox,
      merchantAccId,
      storeId
      // providers
    ) => {
      setLoadingQuote(true);

      let dropoffArr = [];
      for (let i = 0; i < values?.dropoff.length; i++) {
        dropoffArr.push(values?.dropoff[i].dropoff_address);
      }
      try {
        const response = await axios.post(`${apiUrl}delivery/merchant_accounts/${merchantAccId}/quote?test_mode=${isSandbox}`, {
          dropoff_address: dropoffArr,
          pickup_address: values.pickup[0].pickup_address,
          pickup_at: values.isPickupAt ? values.eta : "",
          dropoff_at: !values.isPickupAt ? values.eta : "",
          vehicle: values.vehicleType,
          // providers: providers,
          store_id: storeId || "",
          order_value: values.order_value ? parseFloat(values.order_value) * 100 : 0,
          pickup_unit: values?.pickup[0]?.pickup_unit ?? "",
          dropoff_unit: values?.dropoff[0]?.dropoff_unit ?? "",
          pickup_name: values?.pickup[0]?.pickup_name ?? "",
          contains_alcohol: values.contains_alcohol,
        });
        setQuoteRes(response.data.quotes);
        setQuoteId(response.data.quotes[0]?.id);
        setLoadingQuote(false);
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        setQuoteRes();
        setQuoteError(error);
        setLoadingQuote(false);
        return error;
      }

      // ! -------====-==-
      // if (userId) {
      //   try {
      //     const response = await axios.post(
      //       // `${apiUrl}delivery/merchant_accounts/${merchantAccId}/quote`,
      //       `${apiUrl}delivery/quote/${userId ? userId : ""}`,

      //       {
      //         dropoff_address: dropoff,
      //         pickup_address: pickup,
      //         testMode: isSandbox,
      //         pickup_at: isPickupAt ? dateAt : "",
      //         dropoff_at: !isPickupAt ? dateAt : "",
      //         vehicle: vehicle,
      //         providers: providers,
      //         store_id: storeId ? storeId : "",
      //         order_value: order_value ? order_value * 100 : 0,
      //       },
      //       {
      //         headers: { "x-burq-client": "client-dashboard" },
      //         withCredentials: true,
      //       }
      //     );

      //     setQuoteRes(response);
      //     setQuoteId(response.data.id);
      //     setLoadingQuote(false);
      //     // console.log("response  squote", response);
      //     return response;
      //   } catch (error) {
      //     // console.log("Quote error", error.response);
      //     if (error.response.data.message) {
      //       toast.error(
      //         error.response.data.message ===
      //           "pickup_at cannot be a timestamp in the past."
      //           ? "Scheduled time should be in the future"
      //           : error.response.data.message,
      //         {
      //           position: "top-center",
      //           autoClose: 7000,
      //           hideProgressBar: false,
      //           closeOnClick: true,
      //           pauseOnHover: true,
      //           draggable: true,
      //           closeButton: false,
      //           // progress: undefined,
      //         }
      //       );
      //     }
      //     setQuoteRes();
      //     setQuoteError(error);
      //     setLoadingQuote(false);
      //     return error;
      //   }
      // } else {
      //   try {
      //     const response = await axios.post(
      //       // `${apiUrl}delivery/merchant_accounts/${merchantAccId}/quote`,
      //       `${baseUrlOfApi}quote/`,
      //       {
      //         dropoff_address: dropoffArr,
      //         pickup_address: pickup,
      //         pickup_at: isPickupAt ? dateAt : "",
      //         dropoff_at: !isPickupAt ? dateAt : "",
      //         vehicle: vehicle,
      //         providers: providers,
      //         store_id: storeId ? storeId : "",
      //         order_value: order_value ? order_value * 100 : 0,
      //       },
      //       {
      //         headers: {
      //           "x-api-key": isSandbox
      //             ? Cookies.get("sandbox")
      //             : Cookies.get("prod"),
      //           "x-burq-client": "client-dashboard",
      //         },
      //         withCredentials: false,
      //       }
      //     );

      //     setQuoteRes(response);
      //     setQuoteId(response.data.id);
      //     setLoadingQuote(false);
      //     // console.log("response  squote", response);
      //     return response;
      //   } catch (error) {
      //     console.log("Quote  error", error);
      //     if (error?.response?.data?.message) {
      //       toast.error(error.response.data.message, {
      //         position: "top-center",
      //         autoClose: 7000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         closeButton: false,
      //         // progress: undefined,
      //       });
      //     }
      //     setQuoteRes();
      //     setQuoteError(error);
      //     setLoadingQuote(false);
      //     return error;
      //   }
      // }
    };

    // ! GET SAVED ADDRESSES
    const [getAddressesLoading, setGetAddressesLoading] = useState(false);
    const [getAddressesData, setGetAddressesData] = useState();

    const getSavedAddresses = async () => {
      setGetAddressesLoading(true);
      try {
        const response = await axios.get(`${apiUrl}auth/address/${userId ? userId : ""}`, {
          headers: { "x-burq-client": "client-dashboard" },
        });
        setGetAddressesLoading(false);
        // console.log("Addresses", response);
        setGetAddressesData(response.data);
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        setGetAddressesLoading(false);
        // console.log("Addresses error", error.response);
      }
    };

    // ! POST SAVE ADDRESSES
    const [postAddressesLoading, setPostAddressesLoading] = useState(false);

    const postSaveAddres = async (address, name, phoneNumber, businessName, notes, customer_email, lastName) => {
      setPostAddressesLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}auth/address/${userId ? userId : ""}`,
          {
            address: address,
            firstName: name,
            lastName: lastName || " ",
            phoneNumber: phoneNumber,
            businessName: businessName,
            notes: notes ? notes : "Notes for the Driver",
            customer_email,
          },
          { headers: { "x-burq-client": "client-dashboard" } }

          // {
          //   headers: {
          //     Authorization: `bearer ${token}`,
          //   },
          // }
        );
        setPostAddressesLoading(false);

        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        setPostAddressesLoading(false);
        // console.log("Post Addresses error", error.response);
      }
    };

    // ! DELETE SAVED ADDRESS
    const [deleteAddressData, setDeleteAddressData] = useState();

    const deleteSavedAddress = async (id) => {
      try {
        const response = await axios.delete(
          `${apiUrl}auth/address/${id}/${userId ? userId : ""}`,
          { headers: { "x-burq-client": "client-dashboard" } }

          // {
          //   headers: {
          //     Authorization: `bearer ${token}`,
          //   },
          // }
        );
        setDeleteAddressData(response);
        toast.success("Success!");
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    // ! EDIT ORDER INFO

    const [orderInfoId, setOrderInfoId] = useState();
    const editOrderInfoHandler = async (deliveryInfoId, manifestItems, dropoffInfoArr) => {
      setDeliveryLoading(true);

      try {
        const response = await axios.patch(
          `${baseUrlOfApi}delivery_information`,
          {
            deliveryInfoId: deliveryInfoId,
            dropoff_name: dropoffInfoArr[0].dropoffName + " " + dropoffInfoArr[0].dropoffLastName,
            dropoff_phone_number: dropoffInfoArr[0].dropoff_phone_number,
            dropoff_notes: dropoffInfoArr[0].dropoff_notes,
            pickup_name: pickupName + " " + pickupLastName,
            pickup_phone_number: pickupPhoneNumber,
            pickup_notes: pickupNote,
            items_description: "Things",
            quote_id: quoteId,
            items: manifestItems,
            dropoff_address: dropoffInfoArr[0].dropoff_address,
            pickup_address: pickupAddress,
            user_reference_number: "my order",
          },
          {
            headers: {
              "x-api-key": isSandbox ? Cookies.get("sandbox") : Cookies.get("prod"),
              "x-burq-client": "client-dashboard",
            },
            withCredentials: false,
          }
        );
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(
            error.response.data.message === "The price quote specified has expired."
              ? "The price quote specified has expired"
              : error.response.data.message === "We do not deliver for that location"
              ? error.response.data.message
              : "Please fill The Forms with valid Information"
          );
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        setDeliveryLoading(false);
      }
    };

    // ! create delivery state
    const [deliveryRes, setDeliveryRes] = useState();
    const [deliveryError, setDeliveryError] = useState();
    const [deliveryLoading, setDeliveryLoading] = useState(false);
    const [pickupAddress, setPickupAddress] = useState("");
    const [manifest, setManifest] = useState([{ ...itemTemplate }]);
    const [dropoffInfoArr, setDropoffInfoArr] = useState([{ ...dropoffTemplate }]);

    const createDeliveryInfo = async (info, items, isSandbox, storeId, id) => {
      let body = {};
      const providerSettings = {
        signature: !!info.provider_settings.signature,
        contactless: !!info.provider_settings.contactless,
      };
      if (info.provider_settings.undeliverable_action) {
        providerSettings.undeliverable_action = info.provider_settings.undeliverable_action;
      }

      if (info.provider_settings.age_verification) {
        providerSettings.recipient_minimum_age = info.provider_settings.recipient_minimum_age;
      }

      if (info.dropoff.length > 1) {
        body = {
          pickup_name: info.pickup[0].pickupName + " " + info.pickup[0].pickupLastName,
          pickup_address: info.pickup[0].pickup_address,
          pickup_unit: info.pickup[0].pickup_unit,

          pickup_phone_number: info.pickup[0].pickup_phone_number,
          pickup_notes: info.pickup[0].pickup_notes,
          pickup_at: info.isPickupAt ? info.eta : "",
          items_description: "Things",
          // vehicle: info.vehicleType,
          items: items,
          initiate: info.initiate !== "later",
          quote_id: info.quote_id,
          tip: Math.floor(info.tip * 100),
          dropoff: info.dropoff,
          order_value: Math.floor(info.order_value * 100),
          dropoff_at: !info.isPickupAt ? info.eta : "",
          store_id: storeId ?? null,
          reroute_delivery_id: id,
          external_order_ref: info.external_order_ref || undefined,
          required_provider_settings: providerSettings,
        };
      } else if (info.dropoff.length > 0 && info.dropoff.length < 2) {
        body = {
          pickup_name: !storeId ? info.pickup[0].pickup_name : "",
          pickup_address: !storeId ? info.pickup[0].pickup_address : "",
          pickup_unit: !storeId ? info.pickup[0].pickup_unit : "",
          pickup_phone_number: !storeId ? info.pickup[0].pickup_phone_number : "",
          pickup_notes: !storeId ? info.pickup[0].pickup_notes : "",
          pickup_at: info.isPickupAt ? info.eta : "",
          items_description: "Things",
          items: items,
          initiate: info.initiate !== "later",
          quote_id: info.quote_id,
          tip: Math.floor(info.tip * 100),
          dropoff_address: info.dropoff?.length > 0 ? info.dropoff[0].dropoff_address : "",
          dropoff_name: info.dropoff?.length > 0 ? info.dropoff[0].dropoff_name : "",
          dropoff_unit: info.dropoff?.length > 0 ? info.dropoff[0].dropoff_unit : "",
          dropoff_phone_number: info.dropoff?.length > 0 ? info.dropoff[0].dropoff_phone_number : "",
          dropoff_notes: info.dropoff?.length > 0 ? info.dropoff[0].dropoff_notes : "",
          order_value: Math.floor(info.order_value * 100),
          store_id: storeId ?? null,
          reroute_delivery_id: id,
          external_order_ref: info?.external_order_ref || undefined,
          required_provider_settings: providerSettings,
          contains_alcohol: info.contains_alcohol,
        };
      }

      setDeliveryLoading(true);

      try {
        const response = await axios.post(`${baseUrl}delivery/create?test_mode=${isSandbox}`, body, {
          // const response = await axios.post(`${baseUrlOfApi}delivery_information`, body, {
          headers: {
            // "x-api-key": isSandbox ? Cookies.get("sandbox") : Cookies.get("prod"),
            "x-burq-client": "client-dashboard",
          },
          // withCredentials: false,
        });
        // console.log("delivery API response", response);
        setDeliveryLoading(false);
        setDeliveryRes(response);
        return true;
      } catch (error) {
        if (error?.response?.data?.message) {
          if (error.response.data.message === "Store does not have a payment method associated to it.") {
            setTimeout(() => {
              window.location.href = `/v1/stores/${storeId}?active=paymentMethods`;
            }, 3000);
          }
          toast.error(
            error.response.data.message === "Store does not have a payment method associated to it."
              ? "Please select a default payment method for the store to create the delivery using this store."
              : error.response.data.message === "The price quote specified has expired."
              ? "The price quote specified has expired"
              : error.response.data.message === "We do not deliver for that location"
              ? error.response.data.message
              : error.response.data.message === "NO_DEFAULT_PAYMENT_METHOD_SET"
              ? "No default payment method set"
              : error.response.data.message
          );
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        setDeliveryLoading(false);
        setDeliveryError(error);
        return false;
      }
    };

    const [dropoffAddress, setDropoffAddress] = useState("");

    // ! REQUEST DRIVER

    const [driverRequsest, setDriverRequsest] = useState();
    const [isDriverRequestLoading, setIsDriverRequestLoading] = useState(false);

    const requestDriverHandler = async (id) => {
      setIsDriverRequestLoading(true);
      try {
        const response = await axios.post(
          `${baseUrlOfApi}initiate_delivery`,
          {
            deliveryInfoId: id,
          },
          {
            headers: {
              "x-api-key": isSandbox ? Cookies.get("sandbox") : Cookies.get("prod"),
              "x-burq-client": "client-dashboard",
            },
            withCredentials: false,
          }
        );

        setDriverRequsest(response);
        setIsDriverRequestLoading(false);

        // console.log("REQUEST DRIVER", response);
        return response;
      } catch (error) {
        setIsDriverRequestLoading(false);

        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        setCancelError(error);
        return error;
      }
    };

    // ! CANCEL DELIVERY

    const [cancelRequest, setCancelRequest] = useState();
    const [cancelError, setCancelError] = useState();

    const cancelDelivery = async (token, id) => {
      if (userId) {
        try {
          const response = await axios.post(`${apiUrl}delivery/${id}/cancel/${userId ? userId : ""}?test_mode=${isSandbox}`, {
            headers: { "x-burq-client": "client-dashboard" },
            withCredentials: true,
          });
          setCancelRequest(response);
          return response;
        } catch (error) {
          if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message);
          } else {
            Sentry.captureException(error);
            toast.error("Something went wrong! Please try again.");
          }
          setCancelError(error);
          // return error;
        }
      } else {
        try {
          const response = await axios.post(
            `${baseUrlOfApi}delivery/${id}/cancel`,
            {},
            {
              headers: {
                "x-api-key": isSandbox ? Cookies.get("sandbox") : Cookies.get("prod"),
                "x-burq-client": "client-dashboard",
              },
              withCredentials: false,
            }
          );
          setCancelRequest(response);
          return response;
        } catch (error) {
          if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message);
          } else {
            Sentry.captureException(error);
            toast.error("Something went wrong! Please try again.");
          }
          setCancelError(error);
          // return error;
        }
      }
    };

    // ! WEBHOOKS REQUESTS

    // GET WEBHOOKLIST

    const [webhookListLoading, setWebhookListLoading] = useState(false);
    const [webhooksList, setWebhooksList] = useState();
    const [webhookError, setWebhookError] = useState();

    const getWebhooksList = async (test_mode) => {
      setWebhookListLoading(true);
      try {
        const response = await axios.get(
          // `${apiUrl}webhooks/${userId ? userId : ""}`,
          `${apiUrl}webhooks?test_mode=${test_mode}`,
          { headers: { "x-burq-client": "client-dashboard" } }
        );

        setWebhooksList(response.data);
        setWebhookListLoading(false);
        // console.log("response webhookList", response.data);
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        setWebhookError(error);
        setWebhookListLoading(false);
        return error;
      }
    };

    // ADD NEW WEBHOOK
    const [addWebhookData, setAddWebhookData] = useState();
    const [addWebhookLoading, setAddWebhookLoading] = useState(false);
    const addWebhook = async (url, isSandbox) => {
      setAddWebhookLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}webhooks/${userId ? userId : ""}`,
          {
            url: url,
            testMode: isSandbox,
          },
          { headers: { "x-burq-client": "client-dashboard" } }
        );

        setAddWebhookLoading(false);
        setAddWebhookData(response.data);

        // console.log("response new webhook", response.data);
        toast.success("webhook has added successfully");
        return response;
      } catch (error) {
        setAddWebhookLoading(false);
        if (error?.response?.data?.message) {
          console.log("new webhook error", error?.response?.data?.message);
          if (error?.response?.data?.message === '"url" must be a valid uri') {
            toast.error("Given Uri is not valid");
          } else toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return error;
      }
    };
    // DELETE WEBHOOK

    const [deleteWebhookData, setDeleteWebhookData] = useState();
    const deleteWebhook = async (token, id) => {
      try {
        const response = await axios.delete(`${apiUrl}webhooks/${id}${userId ? "/" + userId : ""}`, {
          headers: { "x-burq-client": "client-dashboard" },
        });
        // console.log("delete webhook response", response);
        setDeleteWebhookData(response);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return;
      }
    };

    // ! STATE MANAGEMENT FOR LITTLE THINGS

    return {
      // ! Login
      loading,
      setLogout,
      logout,
      // ! Deliveries List
      deliveries,
      deliveriesError,
      deliveriesList,
      setDeliveriesList,
      deliveriesLoading,
      setDeliveriesForAnalitycs,
      deliveriesForAnalitycs,

      // ! Create delivery QUOTE
      createQuote,
      quoteRes,
      quoteError,
      loadingQuote,
      setLoadingQuote,
      setQuoteRes,

      // ! GET SAVED ADDRESSES
      getSavedAddresses,
      getAddressesLoading,
      setGetAddressesLoading,
      getAddressesData,

      // ! POST SAVE ADDRESSES,
      postSaveAddres,
      postAddressesLoading,
      // ! DELETE SAVED ADDRESS
      deleteSavedAddress,
      deleteAddressData,
      // ! Create delivery fields
      createDeliveryInfo,
      //!
      dropoffAddress,
      setDropoffAddress,
      pickupAddress,
      setPickupAddress,
      totalDeliveries,
      totalDeliveryPages,
      itemsPerPage,
      setItemsPerPage,
      pageCount,
      setPageCount,
      pickupName,
      setPickupName,
      pickupLastName,
      setPickupLastName,
      pickupBusiness,
      setPickupBusiness,
      pickupPhoneNumber,
      setPickupPhoneNumber,
      pickupNote,
      setPickupNote,
      dropoffName,
      setDropoffName,
      dropoffLastName,
      setDropoffLastName,
      dropoffBusiness,
      setDropoffBusiness,
      dropoffPhoneNumber,
      setDropoffPhoneNumber,
      dropoffNote,
      setDropoffNote,
      dropoffInfoArr,
      setDropoffInfoArr,
      // ! create Delivery and state
      deliveryRes,
      deliveryError,
      deliveryLoading,
      quoteId,
      setDeliveryRes,
      // ! REQUEST DRIVER
      requestDriverHandler,
      driverRequsest,
      isDriverRequestLoading,
      // ! STATE MANAGEMENT FOR LITTLE THINGS
      dotsMenuVisible,
      setDotsMenuVisible,
      isSandbox,
      setIsSandbox,
      hideOrderBannerCookie,
      setHideOrderBannerCookie,
      search,
      setSearch,
      // ! WEBHOOK LIST
      getWebhooksList,
      webhookListLoading,
      webhooksList,
      webhookError,
      setWebhookError,
      addWebhook,
      addWebhookData,
      addWebhookLoading,
      deleteWebhook,
      deleteWebhookData,
      cancelDelivery,
      cancelRequest,
      setCancelRequest,
      cancelError,
      setCancelError,
      // ! zip and state
      pickupZip,
      setPickupZip,
      dropoffZip,
      setDropoffZip,
      setPickupStateShort,
      pickupStateShort,
      dropoffStateShort,
      setDropoffStateShort,
      // loginAuth,
      usersListHandler,
      usersList,
      userId,
      setUserId,
      isUsersListRequestLoading,
      // createQuoteApi,
      manifest,
      setManifest,
      // ! ORDER INFO
      editOrderInfoHandler,
      orderInfoId,
      setOrderInfoId,
      setDeliveryLoading,

      isScalingDisabled,
      setIsScalingDisabled,
    };
  },
});

export default useMyStore;
