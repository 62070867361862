import { PiInfo } from "react-icons/pi";
import Button from "../../atoms/Button";
import styles from "./missingPaymentMethodAlert.module.css";
import { useNavigate } from "react-router-dom";
import { useMerchant, useMyStore } from "../../../../store";

const MissingPaymentMethodAlert = () => {
  let history = useNavigate();
  const { isMissingPaymentMethod } = useMerchant();
  const { isSandbox } = useMyStore();

  const showBanner = isMissingPaymentMethod && !isSandbox;

  if (!showBanner) {
    return null;
  }
  return (
    <div
      className="relative flex 2xl:flex-row flex-col p-[17px] gap-3 rounded-lg border items-center"
      style={{
        marginTop: "12px",
        borderColor: "#FFE099",
        width: "100%",
        backgroundColor: "#FFFDF8",
      }}
    >
      <div className="flex flex-col flex-1 gap-1  items-start shrink-0" style={{ flex: "auto" }}>
        <div className="flex gap-3">
          <PiInfo size={16} color="#EBAD1E" />
          <span
            className="self-stretch font-poppins text-base font-medium leading-[16px] tracking-[-0.4px]"
            style={{
              color: "#1C2029",
            }}
          >
            Set up a payment method{" "}
          </span>
        </div>
        <span
          className=" 2xl:pl-7 sm:pl-6 pl-4 font-poppins text-md font-normal leading-6 self-stretch"
          style={{
            color: "#343C4C",
          }}
        >
          You don’t currently have a payment method set up. Without a payment method, you can create orders, but can’t assign them
          to delivery providers.
        </span>
      </div>
      <Button text="Set up" externalClassName={styles.button} onClick={() => history("/v1/settings/payments")} />
    </div>
  );
};

export default MissingPaymentMethodAlert;
