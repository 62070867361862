import React, { useEffect } from "react";
import { useMyStore, useDeliveryIncidents, useStoreAndChild, userDataStore, useMerchant } from "../../store";
import classNames from "classnames";
import LitsItem from "./ListItem";
import useViewportMeta from "../../customHooks/useViewportMeta";
import ListHeader from "./ListHeader";
import ListLoading from "./ListLoading";
import Pagination from "../shared/Pagination";
import Incidentfilters from "./filters";
import styles from "./styles/incident.module.css";
import ListEmptyState from "./ListEmptyState";
import { useFlag } from "@unleash/proxy-client-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DeliveryIncident = () => {
  const navigate = useNavigate();

  useViewportMeta();
  const { authInfo, isAdmin } = userDataStore();

  const { isSandbox, userId } = useMyStore();

  const {
    searchDeliveryId,
    searchByStoreId,
    searchByStatus,
    searchByExternalOrderRef,
    deliveryIncidents,
    isDeliveryIncidentLoading,
    getDeliveryIncidentsWorker,
  } = useDeliveryIncidents();
  const { getAllStoresByUser } = useStoreAndChild();

  const getDeliveryIncidentHandler = async ({ starting_after, ending_before } = {}) => {
    const params = {
      test_mode: isSandbox,
      starting_after: starting_after || null,
      ending_before: ending_before || null,
    };
    const id = userId || authInfo?.merchantAccountId;
    await getDeliveryIncidentsWorker(id, params);
  };

  useEffect(() => {
    let subscribe = true;

    if (isAdmin === false) {
      // toast.error("You do not have access to this page. Please contact customer support for support.");
      navigate(`/v1/home`);
    }
    if (subscribe) {
      if (authInfo) {
        getDeliveryIncidentHandler();
      }
    }

    return () => {
      subscribe = false;
    };
  }, [authInfo, isSandbox, searchDeliveryId, searchByStoreId, searchByStatus, searchByExternalOrderRef, isAdmin]);

  const storeListHandler = async (accountId) => {
    await getAllStoresByUser(accountId);
    return;
  };

  useEffect(() => {
    if (authInfo?.merchantAccountId) {
      const id = userId || authInfo?.merchantAccountId;
      storeListHandler(id);
    }
  }, [authInfo]);

  const renderTableBody = () => {
    if (isDeliveryIncidentLoading) {
      return <ListLoading />;
    } else if (!deliveryIncidents?.data?.length) {
      return <ListEmptyState />;
    } else return deliveryIncidents.data.map((item) => <LitsItem data={item} />);
  };

  return (
    <div className={classNames("overflow-x-auto", styles.container)}>
      <div className={styles.header}>
        <h1 className={styles.heading}>Delivery Incidents</h1>
      </div>
      <Incidentfilters />
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead className={styles.tableHeader}>
            <ListHeader />
          </thead>

          <tbody className={classNames(styles.tableBody, "kata")}>{renderTableBody()}</tbody>
          <tfoot className={styles.tableFooter}>
            <tr className="flex items-center justify-end pr-28 mt-4">
              <td colSpan="4">
                <Pagination
                  data={deliveryIncidents}
                  onGetPaginationData={getDeliveryIncidentHandler}
                  dependencies={[isSandbox, searchDeliveryId, searchByStoreId, searchByStatus, searchByExternalOrderRef]}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default DeliveryIncident;
