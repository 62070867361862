import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "axios";
import classNames from "classnames";

import styles from "./DeliverySideDrawer.module.css";
import HeaderForDrawer from "./HeaderForDrawer";
import Timeline from "./TimeLine";
import DetailedInfo from "./DetailedInfo";
import AddressesDetails from "./AddressesDetails/AddressesDetails";
import ImagesContainerOnSidebar from "./ImagesContainerOnSidebar";
import DeliveryItemsOnSideDrawer from "./DeliveryItemsOnSideDrawer";
import PaymentInfoOnSideDrawer from "./PaymentInfoOnSideDrawer";
import RerouteDeliveryBanner from "./RerouteDeliveryBanner";
import IncidentInfoAndReason from "./IncidentInfoAndReason";
import { NotFoundErrorSvg } from "./Svgs";

import { baseUrl } from "../../runTimeConst";
import { Loading } from "../../assets/svgs/Svgs";
import CustomModal from "../CustomModal/CustomModal";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import CancelDeliveryRequest from "../CancelDeliveryRequest";
import DisclosureComp from "../shared/common/DisclosureComp";
import DeliveryFailureLogs from "../DeliveriesAnalytics/DeliveriesListSection/DeliveryDetailsCollapsable/DeliveryFailureLogs";
import RequestIncident from "../DeliveriesAnalytics/DeliveriesListSection/DeliveryDetailsCollapsable/RequestIncident";
import { useClickStore, useDelInfoOnSidePopup, useMerchant, useMyStore, usePusherUpdates, userDataStore } from "../../store";
import ReroutedDeliveryBanner from "./ReroutedDeliveryBanner";
import Cookies from "js-cookie";
import { setCookie } from "../../utils";

const DeliverySideDrawer = ({ setSearchParams, authInfo }) => {
  const { deliveryIdFromQueryParam, setDeliveryIdFromQueryParam } = useDelInfoOnSidePopup();
  const { isdesktop } = useDesktopOrLaptop();
  const [deliveryObject, setDeliveryObject] = useState();
  const [cancelVisible, setCancelVisible] = useState(false);
  const { setIsCalendarVisible, setAllTimeVisible } = useClickStore();
  const { isAdmin } = userDataStore();
  const [activeTab, setActiveTab] = useState("Summary");

  const [isDeliveryLoading, setIsDeliveryLoading] = useState(false);

  const [isCreateIncidentOpened, setIsCreateIncidentOpened] = useState(false);

  const { setIsSandbox, isSandbox } = useMyStore();
  const { deliveriesUpdates } = usePusherUpdates();

  const getDeliveryInfo = async (id) => {
    setIsDeliveryLoading(true);
    try {
      const { data } = await axios.get(`${baseUrl}delivery/${id}`, {
        withCredentials: false,
      });
      // if (authInfo.merchantAccountId === data?.merchant_account_id) return;
      if (data) {
        if (data.testMode !== isSandbox) {
          setIsSandbox(data.testMode);
          setCookie("testModeCookie", data?.testMode ? "true" : "false");
        }
        // deliveries("", 0);
        // console.log()
      }

      setDeliveryObject(data);
      setIsDeliveryLoading(false);
    } catch (err) {
      setIsDeliveryLoading(false);
      toast.error(err.response?.data?.message === "Not Found" ? `Requested Id ${err.response.data.message}` : "Not Found");
    }
  };

  // useEffect(() => {
  //   let meta = document.querySelector("meta[name~='viewport']");
  //   if (!meta) {
  //     meta = document.createElement("meta");
  //     meta.rel = "viewport";
  //     document.getElementsByTagName("head")[0].appendChild(meta);
  //   }
  //   meta.content = "width=device-width, initial-scale=1";

  //   return () => {
  //     meta.content = "width=1000px, user-scalable=1";
  //   };
  // }, []);

  useEffect(() => {
    if (deliveriesUpdates?.id === deliveryObject?.id) {
      setDeliveryObject({ ...deliveryObject, ...deliveriesUpdates });
    }
  }, [deliveriesUpdates]);

  useEffect(() => {
    if (deliveryIdFromQueryParam) {
      getDeliveryInfo(deliveryIdFromQueryParam);
    }

    return () => {
      setDeliveryObject();
    };
  }, [deliveryIdFromQueryParam]);

  if (isDeliveryLoading || !deliveryObject?.id) {
    return (
      <div className={classNames(styles.container, "w-full h-full flex items-center justify-center")}>
        <Loading color={"#0062ff"} className="h-14 w-14 animate-spin" />
      </div>
    );
  }

  if (
    authInfo?.merchantAccountId === deliveryObject?.merchant_account_id ||
    authInfo?.merchantAccountId === deliveryObject?.platform_account_id ||
    isAdmin
  ) {
    return (
      <div className={styles.container}>
        <CustomModal
          extraPanelStyles={{ maxWidth: isdesktop ? "40%" : "80%" }}
          isOpen={isCreateIncidentOpened}
          onClose={() => {
            setIsCreateIncidentOpened(false);
          }}
        >
          <RequestIncident
            delInfo={deliveryObject}
            setIsIncidentVisible={setIsCreateIncidentOpened}
            insideSidebarFunc={() => {
              getDeliveryInfo(deliveryIdFromQueryParam);
            }}
          />
        </CustomModal>
        <CustomModal
          extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
          isOpen={cancelVisible}
          onClose={() => {
            setCancelVisible(false);
          }}
        >
          <CancelDeliveryRequest
            insideSidebarFunc={() => {
              getDeliveryInfo(deliveryIdFromQueryParam);
            }}
            setIsCalendarVisible={setIsCalendarVisible}
            request_id={deliveryObject?.id}
            setCancelVisible={setCancelVisible}
          />
        </CustomModal>
        <HeaderForDrawer
          setIsCreateIncidentOpened={setIsCreateIncidentOpened}
          setSearchParams={setSearchParams}
          deliveryObject={deliveryObject}
          statusSocket={deliveryObject?.status}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setCancelVisible={setCancelVisible}
          getDeliveryInfo={getDeliveryInfo}
        />

        <div className={styles.paddingCont}>
          {deliveryObject?.delivery_incident && <IncidentInfoAndReason item={deliveryObject} />}
          {!deliveryObject?.delivery_incident && deliveryObject.cancellation_reason && (
            <IncidentInfoAndReason item={deliveryObject} isOnlyReason={true} />
          )}
          <ReroutedDeliveryBanner
            deliveryId={deliveryObject.reroute_child_delivery_id}
            rerouteStatus={deliveryObject?.reroute_status}
            rerouteReason={deliveryObject?.reroute_reason}
            deliveryStatus={deliveryObject?.status}
          />
          {deliveryObject?.reroute_parent_delivery_id && (
            <RerouteDeliveryBanner deliveryId={deliveryObject.reroute_parent_delivery_id} />
          )}
          {/* <DisclosureComp
            isExpandDisabled
            defaultOpened
            titleClasses={styles.summaryTitle}
            containerClasses={styles.disclosure}
            title="Summary"
          >
            <DetailedInfo item={deliveryObject} />
          </DisclosureComp> */}
          <div className={styles.disclosure}>
            <DetailedInfo
              item={deliveryObject}
              getDeliveryInfo={getDeliveryInfo}
              setCancelVisible={setCancelVisible}
              setIsCreateIncidentOpened={setIsCreateIncidentOpened}
            />
          </div>
          <DisclosureComp
            isExpandDisabled
            defaultOpened
            titleClasses={styles.summaryTitle}
            containerClasses={styles.disclosure}
            title="Delivery Details"
          >
            <AddressesDetails item={deliveryObject} />
          </DisclosureComp>
        </div>

        {deliveryObject?.events && (
          <DisclosureComp
            isExpandDisabled
            defaultOpened
            titleClasses={classNames(styles.summaryTitle, styles.eventDisclTitle)}
            containerClasses={classNames(styles.disclosure)}
            title="Delivery Timeline"
            extraArrowClasses={"mr-9"}
          >
            <Timeline events={deliveryObject?.status_history} />
          </DisclosureComp>
        )}

        <div className={styles.paddingCont}>
          {deliveryObject?.proof_of_delivery && deliveryObject?.proof_of_delivery?.length > 0 && (
            <div className="mt-6">
              <ImagesContainerOnSidebar
                // title={"dasdas"}
                id={deliveryObject.id}
                images={deliveryObject?.proof_of_delivery}
              />
            </div>
          )}
          {deliveryObject?.signature && (
            <div className="mt-6">
              <ImagesContainerOnSidebar isSignature id={deliveryObject.id} images={[deliveryObject.signature]} />
            </div>
          )}
          <DisclosureComp
            isExpandDisabled
            defaultOpened
            titleClasses={styles.summaryTitle}
            containerClasses={styles.disclosure}
            title="Order Details"
          >
            <DeliveryItemsOnSideDrawer items={deliveryObject?.items} />
          </DisclosureComp>

          <div className={classNames("flex w-full items-center justify-between pb-6", styles.disclosure)}>
            <div className={styles.summaryTitle}>Order Value</div>
            <div className={styles.orderValue}>
              {deliveryObject.order_value == 0
                ? "$ 0"
                : deliveryObject?.order_value
                ? "$" + (deliveryObject?.order_value / 100).toFixed(2)
                : ""}
            </div>
          </div>
          <DisclosureComp
            isExpandDisabled
            defaultOpened
            titleClasses={styles.summaryTitle}
            containerClasses={styles.disclosure}
            title="Payment Details"
          >
            <PaymentInfoOnSideDrawer item={deliveryObject} />
          </DisclosureComp>
          {deliveryObject?.status === "failed" && (
            <DisclosureComp
              isExpandDisabled
              defaultOpened
              titleClasses={styles.summaryTitle}
              containerClasses={styles.disclosure}
              title="Api logs"
            >
              <DeliveryFailureLogs deliveryId={deliveryObject?.id} />
            </DisclosureComp>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={classNames(styles.container, "w-full h-full flex flex-col items-center justify-start")}>
        <div className="flex w-full items-center justify-end pr-8 mb-10">
          <button
            onClick={() => {
              setDeliveryIdFromQueryParam();
              setSearchParams({ deliveryId: "" });
              setAllTimeVisible(true);
              setIsCalendarVisible(true);
            }}
            type="button"
          >
            <IoIosCloseCircleOutline className={styles.burqIdTitle} size={28} />
          </button>
        </div>
        <div className={styles.notFoundSvgCont}>
          <NotFoundErrorSvg />
        </div>
        <div className={styles.notFoundTItle}>Result Not Found</div>
        {/* <div className={styles.notFoundDesc}>
          Whoops.... this information is not <br /> available for a moment.
        </div> */}
        <div className={classNames(styles.notFoundDesc, styles.fontDarker)}>
          We are sorry, the page you requested could not be found <br /> please go back to the homepage.
        </div>
      </div>
    );
  }
};

export default DeliverySideDrawer;
