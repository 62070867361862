import React from "react";
import {
  DashboardSvg,
  Developer,
  Settings,
  Admin,
  IntegrationsSvg,
  TripsSvg,
  PaymentSvg,
  AnalyticsSvg,
  Workflows,
} from "../../assets/svgs/Svgs";
import { useNavigate } from "react-router-dom";
import { useFilter, useMyStore, useStatementsStore, useTheme } from "../../store";
import "../../index.css";
import { MdLogout, MdOutlineAccountTree } from "react-icons/md";
import Cookies from "js-cookie";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { HiOutlineClipboardList } from "react-icons/hi";
import { baseUrl } from "../../runTimeConst";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { TbFileInvoice } from "react-icons/tb";
import { ZendeskAPI } from "react-zendesk";
import { removeCookie } from "../../utils";
import { LuPackage } from "react-icons/lu";
// import Colors from "../assets/Colors";

function PanelButtonResponsive({ setSidepanelactive, setBurger, sidepanelactive, active, createPage, id, setIsModal }) {
  const { Colors } = useTheme();
  const { isdesktop, mdfontsize } = useDesktopOrLaptop();
  const { setSelectedStatement } = useStatementsStore();

  let history = useNavigate();
  // const { setGoToPayments } = useClickStore();
  const { setFilterArray } = useFilter();

  const isActive = sidepanelactive === active;

  return (
    <button
      id={id}
      onClick={() => {
        if (active === "logOut") {
          Object.keys(Cookies.get()).forEach((cookieName) => {
            removeCookie(cookieName);
          });
          ZendeskAPI("messenger", "logoutUser");
          window.location.href = `${baseUrl}auth/logout`;
          return;
        }
        if (active === "orders") {
          return (window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/orders`);
        }
        setFilterArray([]);
        setSidepanelactive(active);
        history(`/v1/${active}`);
        // setGoToPayments(false);
        setIsModal(false);
        if (!isdesktop) {
          setBurger(false);
        }
        if (active === "statements") {
          setSelectedStatement();
        }
      }}
      className={`hover:border-blue-600 mb-5 focus:outline-none kata outline-none`}
      style={{
        display: "flex",
        width: "90%",
        marginLeft: "52px",
        marginRight: "30px",
        alignItems: "center",
        borderBottomWidth: "3px",
        borderColor: Colors.black,
        marginTop: "40px",
      }}
    >
      <div style={{ paddingBottom: "10px", paddingTop: "10px" }} className="w-full flex items-center ml-7">
        {active === "home" ? (
          <DashboardSvg isdesktop={isdesktop} active={isActive} />
        ) : active === "admin" ? (
          <Admin active={isActive} isdesktop={isdesktop} />
        ) : active === "trips" ? (
          <TripsSvg isdesktop={isdesktop} active={isActive} />
        ) : active === "payments" ? (
          <PaymentSvg isdesktop={isdesktop} sidepanelactive={sidepanelactive} />
        ) : active === "developers" ? (
          <Developer isdesktop={isdesktop} active={isActive} />
        ) : active === "analytics" ? (
          <AnalyticsSvg isdesktop={isdesktop} sidepanelactive={sidepanelactive} />
        ) : active === "accounts" ? (
          <MdOutlineAccountTree size={29} color={sidepanelactive === "accounts" ? Colors.bluePrimary : Colors.fontDark} />
        ) : active === "workflows" ? (
          <Workflows sidepanelactive={sidepanelactive} isdesktop={isdesktop} />
        ) : active === "orders" ? (
          <HiOutlineClipboardList size={26} color={sidepanelactive === "orders" ? Colors.bluePrimary : Colors.fontDark} />
        ) : active === "settings" ? (
          <Settings isdesktop={isdesktop} active={isActive} />
        ) : active === "stores" ? (
          <AiOutlineAppstoreAdd color={sidepanelactive === "stores" ? Colors.bluePrimary : Colors.fontDark} size={26} />
        ) : active === "statements" ? (
          <TbFileInvoice color={isActive ? Colors.bluePrimary : Colors.fontDark} size={isdesktop ? 26 : 30} />
        ) : active === "logOut" ? (
          <MdLogout color={Colors.fontDark} size={34} />
        ) : active === "deliveries" ? (
          <LuPackage color={isActive ? Colors.bluePrimary : Colors.fontDark} size={isdesktop ? 26 : 30} />
        ) : (
          active === "integrations" && <IntegrationsSvg active={isActive} isdesktop={isdesktop} />
        )}
        <div
          style={{
            fontSize: mdfontsize,
            color: sidepanelactive === active ? Colors.bluePrimary : Colors.fontGreyLight,
            fontFamily: "Poppins",
            transition: `all 0.5s`,
          }}
          className="ml-6 font-semibold capitalize"
        >
          {active === "stores" ? "Stores" : active === "orders" ? "Orders" : active === "trips" ? "Trip Management" : active}
        </div>
      </div>
    </button>
  );
}

export default PanelButtonResponsive;
