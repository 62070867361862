import axios from "axios";
import { baseUrl } from "../../../runTimeConst";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "react-toastify";
import { ZendeskAPI } from "react-zendesk";
import favIcon from "../../../assets/png/burqFavIcon.png";
import AvatarB from "../../../assets/svgs/branding/AvatarB";
import fav from "../../../assets/svgs/branding/favicon.svg";

export const isCenterDriver = (deliveryInfo) =>
  deliveryInfo?.courier_location_lat &&
  deliveryInfo.status !== "delivered" &&
  deliveryInfo.status !== "completed" &&
  deliveryInfo.status !== "canceled" &&
  deliveryInfo.status !== "returned" &&
  deliveryInfo.status !== "Paid order" &&
  deliveryInfo.status !== "disputed" &&
  deliveryInfo.status !== "request" &&
  deliveryInfo.status !== "failed" &&
  deliveryInfo.status !== "expired" &&
  deliveryInfo.status !== "burq_canceled" &&
  deliveryInfo.status !== "customer_canceled" &&
  deliveryInfo.status !== "provider_canceled";

export const mapStyles = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        // saturation: "-100",
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 65,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: "50",
      },
      {
        visibility: "off",
      },
    ],
  },
];

// COORDINATES HANDELR

const coordinatesHandler = async (pickupAddress) => {
  const pickupResults = await geocodeByAddress(pickupAddress);
  const location = pickupResults[0].geometry.location;
  return { lat: location.lat(), lng: location.lng() };
};

// GET DELIVERY INFORMATION ON TRACKING PAGE LOAD
export const getDeliveryInfo = async (
  id,
  setDeliveryInfo,
  setPickupCoordinates,
  dropoffCoordsArr,
  setDropoffCoordsArr,
  isOrderTracking
) => {
  try {
    let response = null;
    if (isOrderTracking) {
      response = await axios.get(`${baseUrl}orders/track/${id}`, {
        withCredentials: false,
      });
    } else {
      response = await axios.get(`${baseUrl}delivery/${id}`, {
        withCredentials: false,
      });
    }
    setDeliveryInfo(response.data);
    const pickupLatLng = await coordinatesHandler(response.data.pickup_address);

    if (response?.data?.branding_setting?.burq_chat_enabled_on_tracking_page === false) {
      ZendeskAPI("messenger", "hide");
    }

    // if (
    //   response?.data?.branding_setting?.merchant_chat_code_snippet &&
    //   !response?.data?.branding_setting?.burq_chat_enabled_on_tracking_page
    // ) {
    //   const scriptElement = document.createElement("script");
    //   scriptElement.innerHTML = Buffer.from(
    //     response?.data?.branding_setting?.merchant_chat_code_snippet,
    //     "base64"
    //   ).toString("utf-8");
    //   document.head.appendChild(scriptElement);
    // }

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    // link.href = fav;
    link.href = response?.data?.branding_setting?.favIcon_image_url || fav;
    // link.href = response?.data?.branding_setting?.favIcon_image_url || favIcon;

    if (response.data?.business_name) {
      document.title = response.data?.business_name;
    }

    setPickupCoordinates(pickupLatLng);
    let geocodePromises = [];
    for (let i = 0; i < response.data.dropoffs.length; i++) {
      const drop = response.data.dropoffs[i];
      geocodePromises.push(geocodeByAddress(drop.dropoff_address));
    }

    Promise.all(geocodePromises).then((e) => {
      const _arrayOfCoords = [...dropoffCoordsArr];
      e.forEach((element, i) => {
        const location = element[0].geometry.location;

        _arrayOfCoords.push({
          lat: location.lat(),
          lng: location.lng(),
        });
      });

      setDropoffCoordsArr(_arrayOfCoords);
    });
  } catch (err) {
    toast.error(err.response?.data?.message === "Not Found" ? `Requested Id ${err.response.data.message}` : "Not Found");
  }
};

// GET ROUTES BETWEEN COORDINATES
export const calculateAndDisplayRoute = async (lat, lng, deliveryInfo, setRoutesForMap) => {
  const DirectionsService = new window.google.maps.DirectionsService();
  const _routesForMap = [];
  for (let i = 0; i < deliveryInfo.dropoffs.length; i++) {
    if (i === 0) {
      const data = await DirectionsService.route({
        origin: new window.google.maps.LatLng(lat, lng),
        destination: {
          query: deliveryInfo.dropoffs[i].dropoff_address,
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      });

      _routesForMap.push(data);
    } else if (i > 0) {
      const data = await DirectionsService.route({
        origin: {
          query: deliveryInfo.dropoffs[i - 1].dropoff_address,
        },
        destination: {
          query: deliveryInfo.dropoffs[i].dropoff_address,
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      _routesForMap.push(data);
    }
  }

  setRoutesForMap(_routesForMap);
};

export const isEtaVisible = (status) => {
  return !(
    status === "returned" ||
    status === "delivered" ||
    status === "scheduled" ||
    status === "provider_canceled" ||
    status === "customer_canceled" ||
    status === "burq_canceled" ||
    status === "expired" ||
    status === "failed" ||
    status === "disputed" ||
    status === "request"
  );
};
