import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import axios from "axios";
import { baseUrl } from "../runTimeConst";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { updateRerouteConfig } from "../utils/api/account";
import { setCookie } from "../utils";
import { canCreateDeliveriesService } from "../utils/helpers";

const useMerchant = createStore({
  name: "useMerchant",
  Store: () => {
    const [merchantData, setMerchantData] = useState();
    const [isIndustriesModalVisible, setIsIndustriesModalVisible] = useState(false);
    const [phoneErr, setPhoneErr] = useState("");
    const [phoneIsNeeded, setPhoneIsNeeded] = useState(false);
    const [IdOfUser, setIdOfUser] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [isMissingPaymentMethod, setIsMissingPaymentMethod] = useState(false);
    const [isAccountOverdrafted, setIsAccountOverdrafted] = useState(false);

    const [avatar, setAvatar] = useState();

    //! -----------------------------------------------------------------

    const getMerchantData = async (userId) => {
      if (!userId) return;
      try {
        const response = await axios.get(`${baseUrl}merchant_accounts/${userId ?? ""}`, {
          headers: { "x-burq-client": "client-dashboard" },
        });
        if (response.data?.account_type !== "connected") {
          if (!response.data?.standardized_industry) {
            setIsIndustriesModalVisible(true);
          }
        }
        if (response.data) {
          window.fcWidget?.user?.setFirstName(response?.data?.name ? response.data?.name : response.data.email);
          window.fcWidget?.user?.setEmail(response.data.email);
          window.fcWidget?.user?.setPhone(response.data.phone_number);

          window.fcWidget?.user?.setProperties({
            businessName: response.data.name,
            businessIndustry: response.data.standardized_industry,
            phoneNumber: response.data.phone_number,
            addressLineOne: response.data.address,
            businesWebsite: response.data.website,
            businessOtherIndustry: response.data.userInfo?.user_inputted_industry,
            country: response.data.country,
            env: "Dev", // meta property 1
          });
          if (Cookies.get("userId") === userId) {
            // setIsAdmin(response.data.is_burq_admin);
            // setIsAdmin(false);
          }
          setMerchantData(response.data);
          const canCreateDelivery = canCreateDeliveriesService(response?.data?.capabilities);
          const missingPaymentMethod =
            !canCreateDelivery?.isEnabled && canCreateDelivery?.pendingRequirments?.includes("payment_method");
          const accountOverdrafted =
            !canCreateDelivery?.isEnabled && canCreateDelivery?.pendingRequirments?.includes("account_overdrafted");
          setIsMissingPaymentMethod(missingPaymentMethod);
          setIsAccountOverdrafted(accountOverdrafted);
          setCookie("user", response.data.email);
          setCookie("sandbox", response.data.sandbox_key);
          setCookie("prod", response.data.production_key);
        }
        setAvatar(response.data?.branding_settings?.icon_image_url ?? null);
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    const updateMerchantData = async (userId, payload, settings, industry, phone, isAccount) => {
      if (phone) {
        payload.phone_number = phone;
        setPhoneIsNeeded(false);
      }
      if (industry) {
        payload.standardized_industry = industry;
      }
      if (settings) {
        payload.settings = settings;
      }
      try {
        const response = await axios.put(`${baseUrl}merchant_accounts/${userId ?? ""}`, payload);
        if (response.data && !isAccount) {
          setMerchantData(response.data);
        }
        if (industry) {
          setIsIndustriesModalVisible(false);
        }
        toast.success("Success!!");
        setAvatar(response.data.image_url ?? null);
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          if (error.response.data.message === "phone_number must be provided in E164 format.") {
            setPhoneErr("Please provide valid Phone number");
            setPhoneIsNeeded(true);
            setIsIndustriesModalVisible(true);
            toast.error("Please provide valid Phone number");
          } else toast.error(error.response.data.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return error;
      }
    };
    const createMerchantData = async (payload) => {
      delete payload.isOtherChosen;
      try {
        const response = await axios.post(`${baseUrl}merchant_accounts`, payload);
        // if (response.data) {
        //   setMerchantData(response.data);
        // }
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    const [isCredentialsLoading, setIsCredentialsLoading] = useState(false);
    const [merchantCredentials, setMerchantCredentials] = useState();
    const getMerchantCredentials = async (userId) => {
      if (!userId) return;
      setIsCredentialsLoading(true);
      try {
        const response = await axios.get(`${baseUrl}merchant_accounts/${userId ?? ""}/provider_credentials`, {
          headers: { "x-burq-client": "client-dashboard" },
        });
        setMerchantCredentials(response.data);
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsCredentialsLoading(false);
      }
    };

    const putMerchantCredentials = async (credId, payload) => {
      if (!credId) return;
      try {
        const response = await axios.put(`${baseUrl}provider_credentials/${credId ?? ""}`, payload, {
          headers: { "x-burq-client": "client-dashboard" },
        });
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    const [isUpdateRerouteConfigLoading, setIsUpdateRerouteConfigLoading] = useState(false);
    const updateRerouteConfigWorker = async (merchantAccountId, payload) => {
      try {
        setIsUpdateRerouteConfigLoading(true);
        const response = await updateRerouteConfig(merchantAccountId, payload);
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsUpdateRerouteConfigLoading(false);
      }
    };

    return {
      getMerchantData,
      merchantData,
      setMerchantData,
      updateMerchantData,
      createMerchantData,
      isIndustriesModalVisible,
      setIsIndustriesModalVisible,
      phoneErr,
      setPhoneErr,
      phoneIsNeeded,
      setPhoneIsNeeded,
      IdOfUser,
      setIdOfUser,
      selectedUser,
      setSelectedUser,
      // ? credentials
      getMerchantCredentials,
      isCredentialsLoading,
      setIsCredentialsLoading,
      merchantCredentials,
      putMerchantCredentials,

      avatar,
      setAvatar,
      isUpdateRerouteConfigLoading,
      updateRerouteConfigWorker,
      isMissingPaymentMethod,
      isAccountOverdrafted,
    };
  },
});

export default useMerchant;
