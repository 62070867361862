import React from "react";
import { Link } from "react-router-dom";
import { useStoreAndChild, useTheme } from "../../../../store";
import Button from "../../atoms/Button";
import IllustrationElement from "../../atoms/IllustrationElement";
import styles from "./styles.module.css";
import { useFlag } from "@unleash/proxy-client-react";

function DeliveriesZeroState({ isInsideStore }) {
  const { Colors } = useTheme();
  const { setSelectedStore, selectedStore } = useStoreAndChild();
  const isOrdersEnabled = useFlag("orders");

  return (
    <>
      <div className={styles.container}>
        <IllustrationElement />
        <div className={styles.body}>
          <h2
            style={{
              color: Colors.fontDark,
              fontSize: "18px",
              margin: "10px 0 0 0",
              fontWeight: "normal",
              marginBottom: "30px",
            }}
          >
            {isOrdersEnabled
              ? "Delivery has not been created yet."
              : isInsideStore
              ? "This store has not yet created a delivery"
              : "Let's get you started with your first delivery"}
          </h2>
          {!isInsideStore && !isOrdersEnabled && (
            <Link id="goToCreatePage" to="/delivery">
              <Button
                onClick={() => {
                  if (selectedStore) {
                    setSelectedStore();
                  }
                }}
              >
                Create Delivery
              </Button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

export default DeliveriesZeroState;
