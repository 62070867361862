const hexColorThemes = {
  light: {
    "--white": "#FFFFFF",
    "--white-blue": "#eff4F8",
    "--sky-blue": "#f7fafc",
    "--primary-gray": "#92929D",
    "--dark-gray": "#455468",
    "--secondary-gray": "#f2f2f7",
    "--bg-settings": "#F5F5F5",
    "--bg-dark-secondary": "#ffffff",
    "--font-dark": "#171725",
    "--bg-grey": "#F7FAFC",
    "--warning-grey": "#F2F2F2",
    "--lighter-gray": "#fafafb",
    "-black": "#000000",
    "--secondary-background": "#f5f5f5",
    "--shadowColor": "rgba(71, 63, 79, 0.16)",
    "--menu-text":" #768196",
    "--menu-icon": "#5D6576",
    "--menu-item-hover": "#eceef2",
    "--menu-divider-line": "#eceef2",
    "--menu-header-text": "#1C2029",

    alertRed: "#ff9966",
    // fontDark: "#171725",
    // bluePrimary: "#0062FF",
    chartSecondLine: "#9DA6B7",
    chartOrangeLine: "#EB4A4A",
    synchronizedAreaChartGreen: "#24A873",
    synchronizedAreaChartOrange: "#FF8528",
    synchronizedAreaChartPurple: "#9360FF",
    synchronizedAreaChartWarning: "#FFDABF",
    // fontGreyLight: "#92929D",
    bgGrey: "#F2F2F2",
    // white: "#ffffff",
    // borderPrimary: "#F2F2F7",
    blueLight: "#62B6FF",
    redDark: "#FF3F3F",
    yellowDark: "#FF974A",
    // greenDark: "#3DD598",
    carBg: "#F2F4FF",
    vanBg: "#F1FAFF",
    truckBg: "#EAF4FF",
    // buttonBg: "#FAFAFB",
    purple: "#7D8AD5",
    accentBlue: "#96B5E8",
    sidePanelBgColor: "#F7F9FB",
    bottomLines: "#ffffff",
    // bgDarkSecondary: "#ffffff",
    borderSecondary: "#E2E2EA",
    // bgListHeader: "#FAFAFB",
    asapContainer: "#EFFBF5",
    createFirstColors: "#D2E9FF",
    createFirstC: "#f0f8ff",
    // detailsBg: "#F7FAFC",
    fontBlack: "#000000",
    // whiteBlue: "#EFF4F8",
    // whitestBlue: "#F7FAFC",
    driverBoxesBg: "#ffffff",
    bgDots: "#ECEEF0",
    bgWorkflow: "#FBFBFB",
    // bgSettings: "#F5F5F5",
    percentUp: "#24A873",
    percentDown: "#EB4A4A",
    chartMonth: "#83879B",
    synchronizedAreaChartLable: "#9DA6B7",
    tableRow: "#F9FAFB",
    chartBlueLighter: "#CBE5FC",
    chartBlueLight: "#59B1F9",
    chartBlue: "#3F8EF1",
    chartBlueDark: "#2E6CC4",
    chartBlueDarker: "#1B448D",
  },
  dark: {
    "--secondary-gray": "#3C4257",
    "--white": "#191E34",
    "--white-blue": "#20253A",
    "--sky-blue": "#292E44",
    "--primary-gray": "#BFBFBF",
    "--dark-gray": "#FAFAFB",
    "--bg-settings": "#292E44",
    "--bg-dark-secondary": "#292E44",
    "--font-dark": "#F9FAFB",
    "--bg-grey": "#292E44",
    "--warning-grey": "#3C4257",
    "--lighter-gray": "#292D44",
    "-black": "#ffffff",
    "--secondary-background": "#191E34",
    "--shadowColor": "rgba(193, 187, 199, 0.16)",
    "--menu-text":" #d9dde5",
    "--menu-icon": "#d9dde5",
    "--menu-item-hover": "#1d283a",
    "--menu-divider-line": "#5d6576",
    "--menu-header-text": "#d9dde5",

    // fontDark: "#F9FAFB",
    // fontGreyLight: "#BFBFBF",
    bgGrey: "#3C4257",
    // white: "#191E34",
    borderPrimary: "#3C4257",
    sidePanelBgColor: "#030000",
    // bgDarkSecondary: "#292E44",
    bgListHeader: "#292D44",
    asapContainer: "#21252F",
    createFirstColors: "#155089",
    lightestBlue: "#F7FAFC",
    createFirstC: "#5db3ff",
    // detailsBg: "#292E44",
    fontBlack: "#FFFFFF",
    // whiteBlue: "#20253A",
    // whitestBlue: "#292E44",
    driverBoxesBg: "#292E45",
    bgDots: "#191E34",
    bgWorkflow: "#2B2F44",
    // bgSettings: "#292E44",
    synchronizedAreaChartLable: "#ffff",
    charGridColor: "#EDF2F7",
    tableRow: "#3C4257",
  },
};

export default hexColorThemes;
