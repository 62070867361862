import React, { useEffect, useState } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import TabButton from "../../shared/common/TabButton";
import { useTheme } from "../../../store";
import styles from "./styles.module.css";
import { RiQuestionLine } from "react-icons/ri";
import classNames from "classnames";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const StatementsAndTransactions = () => {
  const { Colors } = useTheme();
  const { id } = useParams();

  const { isdesktop } = useDesktopOrLaptop();
  let navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("activity");

  const location = useLocation();

  let splitedPath = location.pathname.split("/");
  useEffect(() => {
    if (splitedPath[4] === "statement") {
      navigate("/v1/settings/billing/invoice");
    }
    if (splitedPath[4] === "statement") {
      setActiveTab("statements");
    } else if (splitedPath[4] === "invoice") {
      setActiveTab("invoices");
    } else if (!splitedPath[4]) {
      setActiveTab("activity");
    }
    return () => {};
  }, [location.pathname]);

  return (
    <div
      style={{
        padding: "10px 10px 10px 0px",
        backgroundColor: Colors.white,
        height: isdesktop ? `100%` : `calc(100vh - 200px)`,
      }}
      className="md:w-full flex flex-col"
    >
      <div style={{
        fontFamily: "Poppins",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
      }}>
        Billing
      </div>
      <div style={{ height: "100%", flexGrow: 1 }}>
        <div
          style={{
            padding: "0px 20px 0px 30px",
            color: Colors.fontDark,
            height: "80px",
          }}
          className="w-full flex items-center justify-between"
        >
          <div className="flex items-center">
            <TabButton
              name={"Account Activity"}
              onClick={() => {
                setActiveTab("activity");
                navigate("/v1/settings/billing");
              }}
              active={activeTab === "activity"}
              extraButtonClasses={"-ml-7"}
            />
            <TabButton
              name={"Invoices"}
              onClick={() => {
                setActiveTab("invoices");
                navigate("/v1/settings/billing/invoice");
              }}
              active={activeTab === "invoices"}
              extraButtonClasses={"ml-10"}
            />
            {/* {isInvoiceEnabled ? (
              <TabButton
                name={"Invoices"}
                onClick={() => {
                  setActiveTab("invoices");
                  navigate("/v1/settings/billing/invoice");
                }}
                active={activeTab === "invoices"}
                extraButtonClasses={"ml-10"}
              />
            ) : (
              <TabButton
                name={"Statements"}
                onClick={() => {
                  setActiveTab("statements");
                  navigate("/v1/settings/billing/statement");
                }}
                active={activeTab === "statements"}
                extraButtonClasses={"ml-10"}
              />
            )} */}
          </div>
          {!splitedPath[5] && (
            <div
              className={classNames(styles.helperText, {
                // [styles.mb30]: activeTab !== "activity",
                // [styles.mb40]: !isdesktop,
              })}
            >
              {activeTab === "activity" && (
                <span className="w-5 ">
                  <RiQuestionLine color={Colors.fontGreyLight} size={18} />
                </span>
              )}
              <span className="ml-1 leading-4">
                {activeTab === "activity" &&
                  "Please see below for transactions from the current billing period. These transactions will be included in the next billing statement at the end of the current billing period."}
                {/* {activeTab === "statements" &&
                  !isInvoiceEnabled &&
                  "Statements are generated at the end of each billing period. Each store with completed deliveries has its own billing statement. Payments are automatically charged to store payment method (whenever applicable) or default account payment method. To view statements from a specific store or earlier billing periods, please use filters on the right side of the screen."} */}
              </span>
            </div>
          )}
        </div>
        {/* <div
        className={classNames(styles.helperText, {
          [styles.mb30]: activeTab !== "activity",
          [styles.mb40]: !isdesktop,
        })}
      >
        <span className="w-5 ">
          <RiQuestionLine color={Colors.fontGreyLight} size={18} />
        </span>
        <span className="ml-1 leading-4">
          {activeTab === "activity"
            ? "Please see below for transactions from the current billing period. These transactions will be included in the next billing statement at the end of the current billing period."
            : "Statements are generated at the end of each billing period. Each store with completed deliveries has its own billing statement. Payments are automatically charged to store payment method (whenever applicable) or default account payment method. To view statements from a specific store or earlier billing periods, please use filters on the right side of the screen."}
        </span>
      </div> */}

        {/* {activeTab === "activity" ? <RecentTrx /> : <PreviousStatements />} */}
        <Outlet />
      </div>
    </div>
  );
};

export default StatementsAndTransactions;
